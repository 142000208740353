export const faqData = [
    {
        "question": "Sounds interesting. Is this a scam? What's the catch?",
        "answer": `There's no catch. Coral is a social enterprise with a mission to support artists in leading fulfilling lives, 
        dedicated to creating music, and a vision of a vibrant, healthy, and diverse ecosystem of music, artists and communities. 
        It's started by <a href="https://www.linkedin.com/in/dan-gooden-40191316/" style="color: #FC5373;" target='_blank' rel='noopener noreferrer'>Dan</a>, an Australian music fan who works at the intersection of technology, data, 
        and how we organise ourselves. Feel free to reach out to him by <a href="mailto:dan@wearecoral.org" style="color: #FC5373;">email</a> or 
        via <a href="https://www.linkedin.com/in/dan-gooden-40191316/" style="color: #FC5373;" target='_blank' rel='noopener noreferrer'>LinkedIn</a>.`
    },
    {
        "question": "How is the money paid out?",
        "answer": "Contributions are paid out to the business entity or sole trader registered on the platform."
    },
    {
        "question": "Is this a type of royalty payment? What about publishers and labels?",
        "answer": "Contributions from Coral are not based on song playback. Instead, members select artists to contribute to, or sync their favourite artists via Spotify. The contributions from Coral are not payments for music listened, but contributions to support artists in continuing to make more music."
    },
    {
        "question": "Is any artist eligible?",
        "answer": "Coral is focused on creating a diverse and lively ecosystem of music, therefore artists that have not released in several years, have passed away, or reached high levels of commercial success are filtered to ensure the money goes to the parts of the ecosystem that most benefit."
    },
    {
        "question": "How do you ensure payments are meaningful?",
        "answer": "When a user creates a coral and their artist fund, the number of artists will be limited to ensure the distributions are meaningful. As the platform grows we'll continue to modify parameters to ensure equitable distribution. We're very keen to engage the artists in dialogue about what's fair, useful and healthy for them and the wider musical ecosystem."
    },
    {
        "question": "Does the platform take a fee? How does Coral make money?",
        "answer": "When a member contributes, there is a transaction fee charged by the payment processor, then the platform takes a 10% fee of the remainder and the rest (approximately 90% is distributed to artists). As a social enterprise, if there are profits from the platform fee after operating costs of the company are paid, 50% of those profits will go to a charitable cause related to the music industry."
    },
    {
        "question": "What happens to my funds if I don't sign up?",
        "answer": "We'll make every attempt to contact artists who have payments awaiting them on the platform. At the time of the first reach out, a 6 month timer will initiate. If after 6 months the artist has still not signed up to Coral to receive payments, the money returns as a credit to the contributor to reduce their next payment, and the artist is marked as Not Eligible for contributions. If they later sign up, they will become Eligible, but reversed contributions will not be retrievable."
    },
    {
        "question": "Can I contact my contributors to thank them or share tour dates and new music?",
        "answer": "Yes, if a contributor has opted in to receive communication from artists you’ll be able to reach all of them without cost. This functionality is still in development."
    },
];
