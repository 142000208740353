import { useState, useEffect, useRef } from 'react';
import { getArtistDetails, returnFavouriteArtists } from '../api/api';
import { ClientCoral, ClientArtistPool, CoralType, Source, SourceType, ClientArtistListItem, BlendedArtistListItem } from 'shared/types/platformTypes';
import { processSingleArtistSelection } from 'shared/utils/coralProcessingUtils';
import { addArtist } from './ArtistListHelperFunctions';

const useCoralEditorArtistManagement = (
    coralData: ClientCoral | null, 
    activeFilters: Array<{ filterType: 'genre' | 'location'; value: string }>, 
    setCoralData: (updateFunction: (prevData: ClientCoral) => ClientCoral) => void,
    apiClient = { getArtistDetails, returnFavouriteArtists }
  ) => {
    const [topArtists] = useState<ClientArtistPool | null>(null);
    const [isDynamicArtistPools, setIsDynamicArtistPools] = useState(false);
    const [uniqueGenres, setUniqueGenres] = useState<{ value: string; count: number; }[]>([]); 
    const [uniqueLocations, setUniqueLocations] = useState<{ value: string; count: number; }[]>([]);
    const [dynamicArtistPools, setDynamicArtistPools] = useState<{ sourceType: SourceType; sourceArtistPoolGuid: string; }[]>([]);

    useEffect(() => {
        // Set the variable dynamicArtistPools with the unique set of connected artist pools. Includes both the sourceType and artistPoolGuid
        if (coralData && coralData.artistPool) {
            const pairSet = new Set();
            const pairs = [];

            for (const { source, sourceType, sourceArtistPoolGuid } of coralData.artistPool.artistList) {
                if (source !== Source.USER) {
                    const pairString = JSON.stringify({ sourceType, sourceArtistPoolGuid });

                    if (!pairSet.has(pairString)) {
                        pairSet.add(pairString);
                        pairs.push({ sourceType, sourceArtistPoolGuid });
                    }
                }
            }

            setDynamicArtistPools(pairs);
        }
    }, [coralData]);


    useEffect(() => {
        // Update isDynamicArtistPools whenever coralData changes
        if (coralData && coralData.artistPool && coralData.artistPool.artistList) {
            setIsDynamicArtistPools(coralData.artistPool.artistList.some(artist => artist.source !== Source.USER));
        }
    }, [coralData]);

    useEffect(() => {
        if (coralData && coralData.artistPool && coralData.artistPool.artistList) {
            const genres = coralData.artistPool.artistList.flatMap(artist => artist.artistData?.genres || []);
            const counts = genres
                .filter(genre => genre !== '')
                .reduce((acc: Record<string, number>, genre) => ({ ...acc, [genre]: (acc[genre] || 0) + 1 }), {});
            const result = Object.entries(counts)
                .map(([value, count]) => ({ value, count }))
                .sort((a, b) => b.count - a.count);
            setUniqueGenres(result);
        }
    }, [coralData]);

    useEffect(() => {
        if (coralData && coralData.artistPool && coralData.artistPool.artistList) {
            const locations = coralData.artistPool.artistList.map(artist => artist.artistData?.country || '');
            const counts = locations
                .filter(location => location !== '')
                .reduce((acc: Record<string, number>, location) => ({ ...acc, [location]: (acc[location] || 0) + 1 }), {});
            const result = Object.entries(counts)
                .map(([value, count]) => ({ value, count }))
                .sort((a, b) => b.count - a.count);
            setUniqueLocations(result);
        }
    }, [coralData]);

    // Process artist selection criteria when dynamic filters change
    useEffect(() => {
        if (coralData && coralData.artistPool) {
            const updatedArtistPool = {
                ...coralData.artistPool,
                artistList: coralData.artistPool.artistList.map(artist => {
                    const isSelected = processSingleArtistSelection(artist, activeFilters);
                    return { ...artist, selected: isSelected };
                })
            };

            const newCoralData = {
                ...coralData,
                artistPool: updatedArtistPool
            };

            if (JSON.stringify(coralData) !== JSON.stringify(newCoralData)) {
                setCoralData(() => newCoralData);
                console.log(`CoralData has been updated due to changes in active filters.`); 
            }
        }
    }, [activeFilters, coralData]);

    const updateCoralData = (updatedArtistList: ClientArtistListItem[]) => {
        // const updatedCoralData = coralData as ClientCoral;

        setCoralData(prevCoralData => ({
            ...prevCoralData,
            artistPool: {
                ...prevCoralData.artistPool,
                artistList: updatedArtistList,
            },
        }));
        console.log(`CoralData has been updated in updateCoralData.`); 
      };    


    /**
     * When a user directly adds an artist by their name
     * 
     * @param newArtistName - The name of the new artist to be added.
     * If the coral data type is USER and the artist does not already exist in the artist pool,
     * a new artist with the given name, a status of 'pending', and a source of USER is added to the artist pool.
     */
    const addArtistDirectly = (newArtistName: string) => {
    if (coralData?.type === CoralType.USER) {
        const updatedCoralData = coralData as ClientCoral;
    
        const existingArtist = updatedCoralData.artistPool.artistList.find((artist: ClientArtistListItem) => artist.artistName === newArtistName);
    
        if (!existingArtist) {
        const newArtist: ClientArtistListItem = {
            artistId: '',
            artistName: newArtistName,
            artistListItemStatus: 'pending',
            source: Source.USER,
            sourceArtistPoolGuid: '',
            sourceType: SourceType.USER_ADDED,
            selected: true,
            artistListItemActive: true,
            payoutStatus: '',
        };
    
        const updatedArtistList = addArtist(updatedCoralData.artistPool.artistList, newArtist);
        console.log(`CoralData has been updated in addArtistDirectly.`); 
        updateCoralData(updatedArtistList);
        }
    }
    };

    /**
     * Used when a user adds an artist from a dynamic artist pool (i.e. suggested artists)
     * 
     * @param artistId - The id of the new artist to be added.
     * @param artistName - The name of the new artist to be added.
     * If the coral data type is USER and the artist does not already exist in the artist pool,
     * a new artist with the given id and name, a status of 'added', and a source of USER is added to the artist pool.
     */
    const addArtistFromDynamicArtistPool = (artist: ClientArtistListItem) => {
        if (coralData?.type === CoralType.USER) {
            const updatedCoralData = coralData as ClientCoral;
            const existingArtist = updatedCoralData.artistPool.artistList.find(a => a.artistId === artist.artistId && a.source === Source.USER);
    
            if (!existingArtist) {
                const updatedArtists = updatedCoralData.artistPool.artistList.map(a =>
                    a.artistName === artist.artistName && a.source === Source.SPOTIFY ? { ...a, selected: true } : a
                );
    
                const newArtist: ClientArtistListItem = {
                    ...artist,
                    source: Source.USER,
                    sourceArtistPoolGuid: '',
                    sourceType: SourceType.USER_ADDED,
                    selected: true,
                    artistListItemActive: artist.artistListItemActive || true,
                };
    
                const updatedArtistList = addArtist(updatedArtists, newArtist);
                console.log(`CoralData has been updated in addArtistFromDynamicArtistPool.`); 
                updateCoralData(updatedArtistList);
            }
        }
    };

    /**
     * Removes all artists from the artist pool that have a sourceArtistPoolGuid equal to the parameter.
     * 
     * @param sourceArtistPoolGuid - The guid of the source artist pool.
     * If the artist pool exists, all artists with the given sourceArtistPoolGuid are removed from the artist pool.
     */
    const removeArtistsBySourceArtistPoolGuid = (sourceArtistPoolGuid: string) => {
        if (coralData?.artistPool?.artistList) {
            const updatedArtists = coralData.artistPool.artistList.filter(artist => {
                return artist.sourceArtistPoolGuid !== sourceArtistPoolGuid;
            });

        setCoralData(prevCoralData => ({
            ...prevCoralData,
            artistPool: {
                ...prevCoralData.artistPool,
                artistList: updatedArtists,
            },
        }));
            console.log(`CoralData has been updated in removeArtistsBySourceArtistPoolGuid.`); 
        }
    };

    /**
     * Removes an artist from the artist pool.
     * The behaviour differs based on the artist context:
     * - If the arist source = User, the artist is deleted from the pool.
     * - If the artistStatus = 'added' the status is set to 'ok'
     * - If the artistStatus = 'filtered' then the status is set to 'removed'
     * 
     * @param artistName - The name of the artist to be removed.
     * If the artist pool exists, the artist with the given name is removed from the artist pool.
     */
    const removeArtist = (artistName: string) => {
        if (coralData?.artistPool?.artistList) {
            let updatedArtists = coralData.artistPool.artistList;

            // Only filter out the artist if the source is USER
            if (updatedArtists.some(artist => artist.artistName === artistName && artist.source === Source.USER)) {
                updatedArtists = coralData.artistPool.artistList.filter(artist => {
                    return !(artist.artistName === artistName && artist.source === Source.USER);
                });
            } else {

                updatedArtists = updatedArtists.map(artist => { // This is to handle artists that are in dynamic artist pools
                    if (artist.artistName === artistName && artist.source !== Source.USER) {
                        if (artist.source === Source.SPOTIFY) {
                            // If the artistStatus is 'filtered', set the status to 'removed' as the user doesn't want them funded
                            return { ...artist, active: false };
                        }
                    }
                    return artist;
                });
            }

            setCoralData(prevCoralData => ({
                ...prevCoralData,
                artistPool: {
                    ...prevCoralData.artistPool,
                    artistList: updatedArtists,
                },
            }));
            console.log(`CoralData has been updated in removeArtist.`); 
        }
    };

    /**
     * Enables an artist that has been disabled / excluded in the artist pool.
     * 
     * @param artistName - The name of the artist to be enabled.
     * If the artist pool exists and the artist with the given name has a source that is SPOTIFY and an active status of false,
     * the active status of the artist is changed to true.
     */
    const enableArtist = (artistName: string) => {
        if (coralData?.artistPool?.artistList) {
            let updatedArtists = coralData.artistPool.artistList;

            updatedArtists = updatedArtists.map((artist: ClientArtistListItem) => {
                if (artist.artistName === artistName && artist.source === Source.SPOTIFY && !artist.artistData?.artistActive) {
                    return { ...artist, active: true };
                }
                return artist;
            });

            setCoralData(prevCoralData => ({
                ...prevCoralData,
                artistPool: {
                    ...prevCoralData.artistPool,
                    artistList: updatedArtists,
                },
            }));
            console.log(`CoralData has been updated in enableArtist.`); 
        }
    }; 
    
    const retrieveTopArtists = async () => {
        const topArtists = await apiClient.returnFavouriteArtists();
        // console.log("Favourite Artists", topArtists);
        // if (!isClientArtistPool(topArtists)) {
        //     throw new Error('API response does not match ClientArtistPool');
        // }

        // // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // function isClientArtistPool(data: any): data is ClientArtistPool {
        //     // Check if data has all the properties of a ClientArtistPool
        //     // This is a basic example, you might need to add more checks depending on your data structure
        //     return data && Array.isArray(data.artists) && data.artists.every((artist: { artistId: string; artistName: string; artistStatus: string; }) => 
        //         typeof artist.artistName === 'string' &&
        //         typeof artist.artistStatus === 'string' //&&
        //         // typeof artist.source === 'string' &&
        //         // typeof artist.sourceArtistPoolGuid === 'string'
        //     );
        // }

        if (coralData && coralData.artistPool) {
            const uniqueArtists = topArtists.artistList.filter(favArtist => 
                !coralData.artistPool.artistList.find(artist => artist.artistId ? artist.artistId === favArtist.artistId : artist.artistName === favArtist.artistName)
            );

            setCoralData(prevCoralData => ({
                ...prevCoralData,
                artistPool: {
                    ...prevCoralData.artistPool,
                    artistList: [
                        ...(prevCoralData.artistPool.artistList || []), // Initialize as empty array if undefined
                        ...uniqueArtists.map(artist => ({
                            artistId: artist.artistId,
                            artistName: artist.artistName,
                            artistListItemStatus: artist.artistListItemStatus,
                            source: artist.source,
                            sourceArtistPoolGuid: artist.sourceArtistPoolGuid,
                            sourceType: artist.sourceType,
                            artistData: artist.artistData,
                            selected: false,
                            artistListItemActive: artist.artistListItemActive,
                            payoutStatus: artist.payoutStatus,
                        })),
                    ],
                },
            }));
            console.log(`CoralData has been updated in retrieveTopArtists.`); 
        }
    };

    const coralDataRef = useRef(coralData);
    useEffect(() => {
        coralDataRef.current = coralData;
    }, [coralData]);
    
    /**
     * Custom React hook for managing coral data and adding artists.
     *
     * This hook does the following:
     * 1. Sets up a reference to the current coral data to ensure that the interval callback always has the latest coral data.
     * 2. Sets up an interval that runs every 10 seconds.
     * 3. In each interval, it gets the current coral data and finds any artists that have an empty '' artistId. These are considered "pending" artists.
     * 4. For each pending artist, fetches the artist details from the API using the artistName.
     * 5. If the API returns exactly one artist detail, it updates the artist with the fetched artist detail.
     * 6. If the API returns more than one artist detail, it updates the artist status to 'disambiguation'.
     * 7. If the artist list was updated, it creates a new array of artists and updates the coralData state with the new array of artists.
     * 8. The interval is cleared when the component unmounts.
     * 9. Finally, it returns the current coral data and a function to add an artist.
     *
     * @returns An object containing the current coral data and a function to add an artist.
     */
    useEffect(() => {
    const retryMap = new Map<string, number>();
    const intervalId = setInterval(async () => {
        const currentCoralData = coralDataRef.current;
        if (!currentCoralData || !currentCoralData.artistPool) return;

        const pendingArtists = currentCoralData.artistPool.artistList.filter(artist => {
            if (!artist.artistId) {
                const retries = retryMap.get(artist.artistName!) || 0;
                if (retries < 3) {
                    retryMap.set(artist.artistName!, retries + 1);
                    return true;
                }
            }
            return false;
        });
        if (pendingArtists.length === 0) return;

        let isUpdated = false;
        const updatedArtistsPromises = pendingArtists.map(async artist => {
            await new Promise(resolve => setTimeout(resolve, retryMap.get(artist.artistName!)! * 15000)); // backoff

            const artistDetails = await apiClient.getArtistDetails(artist.artistName!) as BlendedArtistListItem[];
            if (artistDetails.length === 1) {
                isUpdated = true;
                console.log(`Updating artist ${artist.artistName} with fetched details.`);
                return { 
                    ...artist, 
                    artistData: { 
                        ...artist.artistData, 
                        ...artistDetails[0].artistData, 
                        name: artistDetails[0].artistData?.name || '', // provide a default value
                    },
                    artistId: artistDetails[0].artistId, 
                    artistName: artistDetails[0].artistName || '',
                    artistListItemStatus: artistDetails[0].artistListItemStatus,
                    artistListItemActive: artistDetails[0].artistListItemActive,
                    selected: processSingleArtistSelection(artistDetails[0])
                };
            } else if (artistDetails.length > 1) {
                isUpdated = true;
                console.log(`Multiple details found for artist ${artist.artistName}. Updating status to 'disambiguation'.`);
                return { ...artist, artistListItemStatus: 'disambiguation' as "pending" | "active" | "deceased" | "top-artist" | "retired" | "disambiguation" };
            }
            return artist;
        });

        const updatedArtists = await Promise.all(updatedArtistsPromises);

        if (isUpdated) {
            setCoralData((prevCoralData: ClientCoral) => {
                const updatedArtistList = prevCoralData.artistPool.artistList.map((artist: ClientArtistListItem) => {
                    const updatedArtist = updatedArtists.find(updatedArtist => updatedArtist.artistName!.toLowerCase() === artist.artistName!.toLowerCase());
                    return updatedArtist || artist;
                });
                return { ...prevCoralData, artistPool: { ...prevCoralData.artistPool, artistList: updatedArtistList } };
            });
        }
    }, 10000);

    return () => clearInterval(intervalId);
}, []); // No dependencies, run only on mount

    return { addArtistDirectly, removeArtist, removeArtistsBySourceArtistPoolGuid, enableArtist, addArtistFromDynamicArtistPool, retrieveTopArtists, isDynamicArtistPools, uniqueGenres, uniqueLocations, topArtists, dynamicArtistPools };
};

export default useCoralEditorArtistManagement;
