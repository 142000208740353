import React, { useState, useEffect } from 'react';
import { ArtistApplication } from 'shared/types/platformTypes';
import CoralButton from '../../components/CoralButtons/CoralButton';
import { getUserArtistApplications, createArtistApplication, getUserArtistConnections } from '../../api/api';
import { useHistory } from 'react-router-dom';

const ArtistVerifications: React.FC = () => {
    const [applications, setApplications] = useState<{applicationId: string; status: string; artistName: string;}[]>([]);
    const [currentApplication, setCurrentApplication] = useState<ArtistApplication>({
        userId: '',
        artistName: '',
        legalStructure: '',
        legalEntityName: '',
        streamingPurchaseLink: '',
        paymentPreferences: {
            paymentMethod: 'paypal',
            paymentDetails: {},
        },
        applicationStatus: 'PENDING',
    });
    const [artistConnections, setArtistConnections] = useState<{ artistName: string, artistGuid: string }[]>([]);
    const history = useHistory();

    useEffect(() => {
        const fetchApplications = async () => {
            try {
                const userApplications = await getUserArtistApplications();
                setApplications(userApplications);
            } catch (error) {
                console.error('Error fetching artist applications', error);
            }
        };

        const fetchUserAndArtistConnections = async () => {
            try {
                const artistConnections = await getUserArtistConnections();
                setArtistConnections(artistConnections || []);
            } catch (error) {
                console.error('Error fetching user and artist connections:', error);
            }
        };

        fetchApplications();
        fetchUserAndArtistConnections();
    }, []);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;
        setCurrentApplication(prevApplication => ({
            ...prevApplication,
            [name]: value,
        }));
    };

    const handleArtistConnectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedArtistName = event.target.value;
        const selectedArtist = artistConnections.find(artist => artist.artistName === selectedArtistName);
        setCurrentApplication(prevApplication => ({
            ...prevApplication,
            artistName: selectedArtist?.artistName || '',
        }));
    };

    const handleRelationshipConfirmationChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = event.target;

        setCurrentApplication(prevApplication => ({
            ...prevApplication,
            artistRelationshipConfirmation: {
                ...(prevApplication.artistRelationshipConfirmation || {}),
                [name]: value,
            },
        }));
    };

    const handlePaymentAccountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setCurrentApplication(prevApplication => ({
        ...prevApplication,
        paymentPreferences: {
            ...prevApplication.paymentPreferences,
            paymentDetails: {
                ...prevApplication.paymentPreferences.paymentDetails,
                [name]: value,
            },
        },
    }));
};

    const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setCurrentApplication(prevApplication => ({
            ...prevApplication,
            paymentPreferences: {
                ...(prevApplication.paymentPreferences || {}),
                [name]: value
            }
        }));
    };

    function isKeyOfArtistApplication(key: string, object: ArtistApplication): key is keyof ArtistApplication {
        return key in object;
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        const requiredFields = ['artistName', 'legalStructure', 'legalEntityName', 'streamingPurchaseLink'];
        const missingFields = requiredFields.filter(field => {
            if (isKeyOfArtistApplication(field, currentApplication)) {
                return !currentApplication[field];
            }
            return false;
        });

        if (missingFields.length > 0) {
            missingFields.forEach(field => {
                const inputElement = document.getElementById(field);
                if (inputElement && inputElement.parentNode) {
                    inputElement.classList.add('border-red-500');
                    // Check if the error message already exists
                    const existingErrorMessage = inputElement.parentNode.querySelector('.error-message');
                    if (!existingErrorMessage) {
                        const errorMessage = document.createElement('p');
                        errorMessage.className = 'text-red-500 text-sm mt-1 error-message'; // Add a class to identify the error message
                        errorMessage.textContent = 'This field is required';
                        inputElement.parentNode.insertBefore(errorMessage, inputElement.nextSibling);
                    }
                }
            });
            return;
        }

        try {
            const newApplication = await createArtistApplication(currentApplication);
            setApplications(prevApplications => [...prevApplications, newApplication]);
            setCurrentApplication({
                userId: '',
                artistName: '',
                legalStructure: '',
                legalEntityName: '',
                streamingPurchaseLink: '',
                paymentPreferences: {
                    paymentMethod: 'paypal',
                    paymentDetails: {},
                },
                applicationStatus: 'PENDING',
            });
        } catch (error) {
            console.error('Error creating artist application', error);
        }
    };

    return (
        <div className="flex flex-col justify-center items-center min-h-screen px-4">
            <div className="w-full max-w-xl  bg-white p-8">
                <h2 className="font-nunito text-xl md:text-2xl font-extrabold mb-4">
                    Artist Verification
                </h2>
                <p className="mb-6">
                    If you have any questions, please reach out to <a href="mailto:hello@wearecoral.org">hello@wearecoral.org</a>
                </p>
                <div className="w-full max-w-xl  pb-6">
                    <h3 className="font-nunito text-lg font-bold mb-4">Submitted Applications</h3>
                    {applications.length > 0 ? (
                        applications.map(application => (
                        <div key={application.applicationId} className="bg-white p-4 mb-4 rounded shadow-lg">
                            <h2 className="text-base mb-2"><span className="font-semibold">Application Number:</span> {application.applicationId.substring(application.applicationId.indexOf('-') + 1)}</h2>
                            <p className="text-base mb-1"><span className="font-semibold">Artist Name:</span> {application.artistName}</p>
                            <p className="text-base"><span className="font-semibold">Application Status:</span> {application.status}</p>
                        </div>
                        ))
                    ) : (
                        <p>No applications have been submitted.</p>
                    )}
                </div>

                <CoralButton
                    onClick={() => history.push('/dashboard')}
                    className="text-white hover:bg-[#3D4758] transition duration-200 p-2 sm:mb-3"
                >Go to Dashboard
                </CoralButton>

                <h2 className="font-nunito text-l md:text-xl font-extrabold pt-6 mb-4">
                    New Application to Link Artist
                </h2>
                <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                    <label htmlFor="artistConnection" className="block text-sm font-medium text-gray-700 mb-2">Artist Connection</label>
                    <select
                        id="artistConnection"
                        name="artistConnection"
                        required
                        className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm"
                        value={currentApplication.artistName || ''}
                        onChange={handleArtistConnectionChange}
                    >
                        <option value="">Select an artist</option>
                        {artistConnections.map(artist => (
                            <option key={artist.artistName} value={artist.artistName}>{artist.artistName}</option>
                        ))}
                    </select>
                </div>
                    <div>
                        <label htmlFor="legalStructure" className="block text-sm font-medium text-gray-700 mb-2">Legal Structure</label>
                        <select
                            id="legalStructure"
                            name="legalStructure"
                            required
                            className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm"
                            value={currentApplication.legalStructure || ''}
                            onChange={handleInputChange}
                        >
                            <option value="">Select legal structure</option>
                            <option value="Sole Proprietor">Sole Proprietor/Trader</option>
                            <option value="Partnership">Partnership</option>
                            <option value="Company">Company (e.g. Pty Ltd, LLC, Corporation)</option>
                        </select>
                    </div>
                    <div>
                        <label htmlFor="legalEntityName" className="block text-sm font-medium text-gray-700 mb-2">Full Legal Artist Entity Name (Person or Company)</label>
                        <input
                            id="legalEntityName"
                            name="legalEntityName"
                            type="text"
                            required
                            className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm"
                            placeholder="Enter legal entity name"
                            value={currentApplication.legalEntityName || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="streamingPurchaseLink" className="block text-sm font-medium text-gray-700 mb-2">Streaming/Purchase Link</label>
                        <p className="text-xs mb-5">
                            Include a link to your music on a major streaming platform or major online store to purchase your music. This assists in helping us verify your artist identity.
                        </p>
                        <input
                            id="streamingPurchaseLink"
                            name="streamingPurchaseLink"
                            type="text"
                            required
                            className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm"
                            placeholder="Enter streaming/purchase link"
                            value={currentApplication.streamingPurchaseLink || ''}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div>
                        <h3 className="text-lg font-bold mb-2">Artist Relationship Confirmation</h3>
                        <p className="mb-4">
                            We want to ensure that you are the intended recipient and have an official link to the artist. Please demonstrate your relationship to the artist by one of the following methods:
                            <ul className="list-disc list-inside">
                                <li>Send a message to @wearecoral on Instagram from your official artist account with the code: 123scag</li>
                                <li>Provide an official statement from APRA AMCOS that includes both your name and the artist&apos;s name</li>
                                <li>Update your Spotify &ldquo;Support this artist&ldquo; link to the wearecoral artist referral link (instructions provided later)</li>
                            </ul>
                        </p>
                        <label htmlFor="confirmationMethod" className="block text-sm font-medium text-gray-700 mb-2">Confirmation Method</label>
                        <select
                            id="confirmationMethod"
                            name="confirmationMethod"
                            className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm"
                            value={currentApplication.artistRelationshipConfirmation?.confirmationMethod || ''}
                            onChange={handleRelationshipConfirmationChange}
                        >
                            <option value="">Select confirmation method</option>
                            <option value="instagram">Instagram</option>
                            <option value="apraAmcos">APRA AMCOS</option>
                            <option value="spotify">Spotify</option>
                        </select>
                    </div>
                    <div>
                        <h3 className="text-lg font-bold mb-2">Payment Preferences</h3>
                        <p className="mb-4">
                            Please select your preferred payment method and provide the necessary details:
                        </p>
                        <label className="block text-sm font-medium text-gray-700 mb-2">Payment Method</label>
                        <div className="mb-4">
                            <label className="block mb-2">
                                <input
                                    type="radio"
                                    name="paymentMethod"
                                    value="paypal"
                                    checked={currentApplication.paymentPreferences.paymentMethod === 'paypal'}
                                    onChange={handlePaymentMethodChange}
                                />
                                PayPal
                            </label>
                            <label className="block">
                                <input
                                    type="radio"
                                    name="paymentMethod"
                                    value="wise"
                                    checked={currentApplication.paymentPreferences.paymentMethod === 'wise'}
                                    onChange={handlePaymentMethodChange}
                                />
                                Wise
                            </label>
                        </div>
                        <label htmlFor="paymentAccount" className="block text-sm font-medium text-gray-700 mb-2 mt-4">Payment Provider Account (e.g. PayPal account email)</label>
                        <input
                            id="paymentAccount"
                            name="paymentAccount"
                            type="text"
                            required
                            className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm"
                            placeholder="Enter payment account"
                            value={currentApplication.paymentPreferences.paymentDetails.paymentAccount || ''}
                            onChange={handlePaymentAccountChange}
                        />
                    </div>
                    <div className="w-full mt-8">
                        <h3 className="text-lg font-bold mb-2">Next Steps</h3>
                        <p>
                            Once you&apos;ve completed the sign-up process, our team will review your application. You can expect a response within 2 weeks. If approved, you&apos;ll receive a welcome email with further instructions on how to make the most of your wearecoral account and receive member contributions.
                        </p>
                    </div>
                    <div>
                        <CoralButton className="font-semibold text-xs sm:text-sm" onClick={handleSubmit}>Submit Application</CoralButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ArtistVerifications;