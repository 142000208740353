import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from '../App';
import Home from '../pages/Home/Home';
import About from '../pages/About/About';
import Contact from '../pages/Contact/Contact';
import Privacy from '../pages/Privacy/Privacy';
import Terms from '../pages/Terms/Terms';
import Principles from '../pages/Principles/Principles';
import Trust from '../pages/Trust/Trust';
import Dashboard from '../pages/Dashboard/Dashboard';
import OurImpact from '../pages/OurImpact/OurImpact';
import Settings from '../pages/Settings/Settings';
import Welcome from '../pages/Welcome/Welcome';
import ProfileSetup from '../pages/ProfileSetup/ProfileSetup';
import ArtistVerifications from '../pages/ArtistSetup/ArtistVerification';
import UserIdentityVerificationForm from '../pages/ArtistSetup/UserIdentityVerificationForm';
import Coral from '../pages/Coral/Coral';
import EditCoral from '../pages/Coral/EditCoral';
import PublicSharedCoral from '../pages/PublicSharedCoral/PublicSharedCoral';
import CreateCoralFunnel from '../pages/CreateCoralFunnel/CreateCoralFunnel';
import ForArtists from '../pages/ForArtists/ForArtists';
import Payment from '../pages/Payment/Payment';
import SignOut from '../pages/SignOut/SignOut';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-2RBVGM4D7Y');
//import NotFound from '../pages/NotFound/NotFound';
import RouteChangeTracker from '../components/RouteChangeTracker/RouteChangeTracker';
import ArtistConnections from '../pages/ArtistSetup/ArtistConnections';


const Routes = () => {

    return (
        <Router>
            <RouteChangeTracker />
            <App>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/contact-us" component={Contact} />
                    <Route path="/for-artists" component={ForArtists} />
                    <Route path="/privacy" component={Privacy} />
                    <Route path="/terms" component={Terms} />
                    <Route path="/principles" component={Principles} />
                    <Route path="/trust" component={Trust} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/impact" component={OurImpact} />
                    <Route path="/setup" component={ProfileSetup} />
                    <Route path="/artist-applications" component={ArtistVerifications} />
                    <Route path="/artist-connections" component={ArtistConnections} />
                    <Route path="/verify-identity" component={UserIdentityVerificationForm} />
                    <Route path="/welcome" component={Welcome} />
                    <Route path="/settings" component={Settings} />
                    <Route exact path="/coral" component={Coral} /> {/* For creating a new coral */}
                    <Route path="/create-coral" component={CreateCoralFunnel} />
                    <Route path="/coral/:id/edit" component={EditCoral} /> {/* For editing an existing coral */}
                    <Route path="/coral/:id" component={Coral} /> {/* For viewing an existing coral */}
                    <Route path="/c/:id" component={PublicSharedCoral} />
                    <Route path="/payment" component={Payment} />
                    <Route path="/sign-out" component={SignOut} />
                    {/* <Route component={NotFound} /> */}
                </Switch>
            </App>
        </Router>
    );
}

export default Routes;
