import React, { ComponentPropsWithoutRef, useContext } from 'react';
import { Link } from 'react-router-dom';
import coralIcon from '../../assets/images/coral-icon.png';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';
import { AuthContext } from '../../App';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface HeaderProps extends ComponentPropsWithoutRef<any> {
    navigationTextHandler: string;
}

const Header: React.FC<HeaderProps> = ({ navigationTextHandler, ...props }) => {
    const { setShowAuth } = useContext(AuthContext);
    const environment = process.env.REACT_APP_ENVIRONMENT;

    return (
        <header className={ `bg-white` } {...props}>
            <div className="px-4 py-4 max-w-screen-xl mx-auto flex justify-between items-center">
                <div className="flex items-center space-x-6 sm:space-x-8">
                    <Link to="/" className="flex items-center space-x-2">
                        <img src={coralIcon} alt="Coral" className="mr-2 h-7 w-7" />
                        <span className="font-nunito text-l md:text-xl font-extrabold">
                            <span className="sm:hidden">coral</span>
                            <span className="hidden sm:inline">wearecoral</span>
                        </span>
                    </Link>
                    <Link to="/about" className={ navigationTextHandler }>
                        About
                    </Link>
                    <Link to="/for-artists" className={ `${navigationTextHandler} text-center` }>
                        For <span className="sm:inline block">Artists</span>
                    </Link>
                </div>
                {environment !== 'prod' && (
                    <div className="flex items-center space-x-8">
                        <button onClick={() => setShowAuth({ show: true, signIn: true })} className={ `hidden md:inline ${ navigationTextHandler }` }>Log In</button>
                        <JoinCoralButton className="bg-gradient-to-r from-coral-pink to-coral-orange text-white px-2 py-1 md:px-4 md:py-2 rounded-full text-xs md:text-sm">
                            <span className="md:hidden">Start</span>
                            <span className="hidden md:inline">Sign Up</span>
                        </JoinCoralButton>
                    </div>
                )}
            </div>
        </header>
    );
};

export default Header;