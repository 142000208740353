import React, { useEffect, useState } from 'react';
import Image0 from 'images/thumbnails/image_0.png';
import { BiArrowBack, BiSolidDonateHeart } from 'react-icons/bi';
import { ClientCoral } from 'shared/types/platformTypes';
import SecondaryCoralButton from '../../../CoralButtons/SecondaryCoralButton';
import { getCoralFromAPI } from '../../../../api/api';

interface CreateCoralFromCuratedArtistsIndividualProps {
  pageTurner: (page: string) => void;
  coralId: string; // add this prop to receive the coral id
}

interface CoralTableRowProps {
  artistName: string;
  isChecked?: boolean;
  isLoading?: boolean;
}

const CoralTableRow: React.FC<CoralTableRowProps> = ({ artistName, isLoading = false }) => {
  const LOADING_STATE = (
    <tr className="border-b animate-pulse">
      <td className="px-0 py-4 whitespace-nowrap">
        <div className="h-6 bg-gray-200 rounded-md w-6"></div>
      </td>
      <td className="px-2 lg:px-3 py-4 whitespace-nowrap">
        <div className="h-4 bg-gray-200 rounded-md w-32"></div>
      </td>
    </tr>
  );

  if(isLoading) return LOADING_STATE;

  return (
    <tr className={ `border-b` }>
      <td className="px-0 py-3 lg:py-5 whitespace-nowrap">
        <input type="checkbox" className="w-4 h-4 lg:w-5 lg:h-5"/>
      </td>
      <td
        className="px-2 py-3 lg:px-3 lg:py-4 whitespace-nowrap overflow-hidden overflow-ellipsis max-w-28 sm:whitespace-normal sm:overflow-visible sm:max-w-full text-md lg:text-lg font-bold font-nunito text-primary-800"
      >
        { artistName }
      </td>
    </tr>
  );
}

const CreateCoralFromCuratedArtistsIndividual: React.FC<CreateCoralFromCuratedArtistsIndividualProps> = ({ pageTurner, coralId }) => {
  const [coral, setCoral] = useState<ClientCoral | null>(null);

  useEffect(() => {
    const fetchCoral = async () => {
      const data = await getCoralFromAPI(coralId);
      setCoral(data);
    };

    fetchCoral();
  }, [coralId]);

  if (!coral) {
    return (
      <section className="grid grid-cols-1 lg:grid-cols-[170px_1fr]">
        <section>
          <button onClick={ () => pageTurner('curated') }>
            <BiArrowBack className="w-6 h-6"/>
          </button>
        </section>
        <section className="flex flex-col gap-6">
          <section className="flex flex-col lg:flex-row justify-between gap-4">
            <div className="flex flex-col lg:flex-row flex-nowrap gap-6 justify-center lg:justify-start items-center">
              <div className="w-[100px] lg:w-[170px] aspect-[1] object-cover rounded-lg bg-gray-200 animate-pulse"></div>
              <div className="flex flex-col items-center lg:items-start">
                <h2 className="text-center h-6 bg-gray-200 rounded-md w-32 leading-[1] truncate animate-pulse mb-4"></h2>
                <p className="text-center h-4 bg-gray-200 rounded-md w-32 truncate animate-pulse"></p>
                <button
                  className="w-fit bg-primary-100 border-primary-200 border-1 border-solid rounded-[5000px] text-primary-800 font-nunito text-sm px-4 py-2 flex flex-row content-center items-center gap-2 mt-4 lg:mt-6 animate-pulse">
                </button>
              </div>
            </div>
            <div className="grid place-items-center">
              <SecondaryCoralButton className="h-fit" onClick={ () => {
              } }>
                Deselect All
              </SecondaryCoralButton>
            </div>
          </section>
          <section className="lg:mt-4 max-h-[300px] lg:max-h-[400px] relative overflow-y-scroll overscroll-y-contain">
            <table className="w-full mb-3">
              <thead className="text-xs">
              <tr>
                <th className="px-0 py-3 text-right align-bottom"></th>
                <th className="px-0 py-3 text-right align-bottom"></th>
              </tr>
              </thead>
              <tbody className="text-sm">
                <CoralTableRow artistName="No artists found" isLoading/>
                <CoralTableRow artistName="No artists found" isLoading/>
                <CoralTableRow artistName="No artists found" isLoading/>
                <CoralTableRow artistName="No artists found" isLoading/>
                <CoralTableRow artistName="No artists found" isLoading/>
                <CoralTableRow artistName="No artists found" isLoading/>
                <CoralTableRow artistName="No artists found" isLoading/>
                <CoralTableRow artistName="No artists found" isLoading/>
              </tbody>
            </table>
          </section>
        </section>
      </section>
    ); // or some loading spinner
  }

  return (
    <section className="grid grid-cols-1 lg:grid-cols-[170px_1fr]">
      <section>
        <button onClick={ () => pageTurner('curated') }>
          <BiArrowBack className="w-6 h-6"/>
        </button>
      </section>
      <section className="flex flex-col gap-6">
        <section className="flex flex-col lg:flex-row justify-between gap-4">
          <div className="flex flex-col lg:flex-row flex-nowrap gap-6 justify-center lg:justify-start items-center">
            <img src={ Image0 } alt={ `Coral thumbnail` }
                 className="w-[100px] lg:w-[170px] aspect-[1] object-cover rounded-lg"/>
            <div className="flex flex-col items-center lg:items-start">
              <h2
                className="text-center lg:text-left font-nunito text-lg lg:text-2xl font-black leading-[1] truncate">Curated
                Coral</h2>
              <p className="text-center lg:text-left text-gray-500 text-md lg:text-lg truncate">Artist name, Artist
                name...</p>
              <button
                className="w-fit bg-primary-100 border-primary-200 border-1 border-solid rounded-[5000px] text-primary-800 font-nunito text-sm px-4 py-2 flex flex-row content-center items-center gap-2 mt-4 lg:mt-6">
                <span><BiSolidDonateHeart width={ 24 } height={ 24 }/></span>
                <span>290 supporters</span>
              </button>
            </div>
          </div>
          <div className="grid place-items-center">
            <SecondaryCoralButton className="h-fit" onClick={ () => {
            } }>
              Deselect All
            </SecondaryCoralButton>
          </div>
        </section>
        <section className="lg:mt-4 max-h-[300px] lg:max-h-[400px] relative overflow-y-scroll overscroll-y-contain">
          <table className="w-full mb-3">
            <thead className="text-xs">
            <tr>
              <th className="px-0 py-3 text-right align-bottom"></th>
              <th className="px-0 py-3 text-right align-bottom"></th>
            </tr>
            </thead>
            <tbody className="text-sm">
            { coral.artistPool.artistList.length > 0 ? (
              coral.artistPool.artistList.map((artist, index) => (
                <CoralTableRow key={ index } artistName={ artist.artistName! }/>
              ))
            ) : (
              <CoralTableRow artistName="No artists found" isLoading/>
            ) }
            </tbody>
          </table>
        </section>
      </section>
    </section>
  );
};

export default CreateCoralFromCuratedArtistsIndividual;