import React from 'react';

// Mock transaction data
const mockTransactions = [
  { id: 'txn_1', date: '2024-02-20', amount: '$19.99', description: 'Monthly Subscription Fee', status: 'Completed' },
  { id: 'txn_2', date: '2024-01-20', amount: '$19.99', description: 'Monthly Subscription Fee', status: 'Completed' },
  { id: 'txn_3', date: '2023-12-20', amount: '$19.99', description: 'Monthly Subscription Fee', status: 'Completed' },
  // Add more mock transactions as needed
];

const Transactions: React.FC = () => {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg p-4">
      <div className="py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-nunito font-extrabold text-coral-black">Payment History</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Your recent transactions are listed below</p>
      </div>
      <div className="border-t border-gray-200">
        <div className="mt-3 flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Date
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Amount
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Description
                      </th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {mockTransactions.map((transaction) => (
                      <tr key={transaction.id}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{transaction.date}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.amount}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.description}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{transaction.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
