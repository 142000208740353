import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { signOut } from '@aws-amplify/auth';

const SignOut: React.FC = () => {
    const history = useHistory();

    useEffect(() => {
        async function handleSignOut() {
            try {
                await signOut();
                history.push('/');
            } catch (error) {
                console.error('Error signing out: ', error);
            }
        }

        handleSignOut();
    }, [history]);

    return (
        <div>
        </div>
    );
}

export default SignOut;