"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SourceType = exports.Source = exports.CoralType = exports.ArtistPoolType = void 0;
;
// Artist Pool Types
/**
* ArtistPoolType represents the type of an artist pool.
* It can be either 'fixed', 'dynamic', 'blended', or 'shared'.
*/
var ArtistPoolType;
(function (ArtistPoolType) {
    ArtistPoolType["FIXED"] = "fixed";
    ArtistPoolType["DYNAMIC"] = "dynamic";
    ArtistPoolType["BLENDED"] = "blended";
    ArtistPoolType["SHARED"] = "shared";
})(ArtistPoolType || (exports.ArtistPoolType = ArtistPoolType = {}));
// Coral Types
/**
* CoralType represents the type of a coral.

* Corals can be of different types:
* - Shared: An existing base Coral that can added to a user's library
* - User: A Coral that is created manually by a user adding artists
*/
var CoralType;
(function (CoralType) {
    CoralType["SHARED"] = "shared";
    CoralType["USER"] = "userManual";
})(CoralType || (exports.CoralType = CoralType = {}));
// Enums
/**
* Source represents the source of the artist pool.
* It can be 'Spotify' or any other source you want to add in the future.
*/
var Source;
(function (Source) {
    Source["UNDEFINED"] = "Undefined";
    Source["SPOTIFY"] = "Spotify";
    Source["USER"] = "User";
    // Add other sources here
})(Source || (exports.Source = Source = {}));
/**
* SourceType represents the type of the source of the artist pool.
* It can be 'FavouriteArtists', 'ArtistsOnRotation', or 'ArtistsFollowed'.
*/
var SourceType;
(function (SourceType) {
    SourceType["UNDEFINED"] = "Undefined";
    SourceType["USER_ADDED"] = "UserAdded";
    SourceType["FAVOURITE_ARTISTS"] = "FavouriteArtists";
    SourceType["ARTISTS_ON_ROTATION"] = "ArtistsOnRotation";
    SourceType["ARTISTS_FOLLOWED"] = "ArtistsFollowed";
    // Add other source types here
})(SourceType || (exports.SourceType = SourceType = {}));
