// src/components/CoralCard.tsx

import React from 'react';
import { useHistory } from 'react-router-dom';
import { ClientCoral } from 'shared/types/platformTypes';

interface CoralCardProps {
    coral: ClientCoral;
}

const CoralCard: React.FC<CoralCardProps> = ({ coral }) => {
    const history = useHistory();
    const artists = coral.artistPool.artistList.map(artist => artist.artistName).join(', ');
    const handleCardClick = () => {
        history.push(`/coral/${coral.guid}`);
    };

    return (
        <div 
            className="shadow-lg p-6 rounded-lg bg-white cursor-pointer hover:shadow-xl transition-shadow duration-200"
            onClick={handleCardClick}
        >
            <div className="flex justify-between items-center mb-4">
                <div className="text-coral-red font-nunito font-bold text-base truncate" style={{ maxWidth: '70%' }}>
                    {coral.coralName || artists}
                </div>
                <div className="bg-green-200 text-green-800 py-1 px-3 rounded-full text-xs font-semibold">
                    Active
                </div>
            </div>
            <div className="flex items-baseline">
                <span className="text-base font-semibold text-gray-800">{coral.pledgeAmount.toLocaleString()}</span>
                <span className="text-gray-500 text-sm ml-1 -mt-1">monthly</span>
            </div>
        </div>
    );
};

export default CoralCard;
