// src/components/CoralCardWithThumbnail.tsx

import React from 'react';
import Image0 from 'images/thumbnails/image_0.png';
import Image1 from 'images/thumbnails/image_1.png';
import Image2 from 'images/thumbnails/image_2.png';
import Image3 from 'images/thumbnails/image_3.png';
import Image4 from 'images/thumbnails/image_4.png';
import Image5 from 'images/thumbnails/image_5.png';
import Image6 from 'images/thumbnails/image_6.png';
import Image7 from 'images/thumbnails/image_7.png';
import Image8 from 'images/thumbnails/image_8.png';
import Image9 from 'images/thumbnails/image_9.png';
import Image10 from 'images/thumbnails/image_10.png';
import Image11 from 'images/thumbnails/image_11.png';

interface CoralThumbnailProps {
    media?: string;
    mediaAlt?: string;
    title: string;
    subtitle?: string;
    pageTurner: (page: string, guid?: string) => void;
    guid: string;
    link?: string;
    id?: string;
}

const CoralCardWithThumbnail: React.FC<CoralThumbnailProps> = ({ id, title, subtitle, pageTurner, guid, link }) => {
    const imageArray = [
        Image0,
        Image1,
        Image2,
        Image3,
        Image4,
        Image5,
        Image6,
        Image7,
        Image8,
        Image9,
        Image10,
        Image11
    ];

    const cardContent = (
<div className="max-w-[250px] flex flex-col gap-2 p-4 bg-white bg-opacity-80 rounded-lg shadow-lg">
    <img src={imageArray[Math.floor(Math.random() * imageArray.length)]} alt={`${title} thumbnail`} className="w-full h-full aspect-[1/1] object-cover rounded-lg mb-4" />

            <h2 className="font-nunito text-base font-bold leading-tight text-blue-600 truncate">{title}</h2>
            <p className="text-gray-500 text-sm truncate">{subtitle}</p>
        </div>
    );

    if (!link) {
        return (
            <div id={id} className="w-full min-w-0 h-full hover:cursor-pointer flex flex-col gap-4" onClick={() => pageTurner('curated-individual', guid)}>
                {cardContent}
            </div>
        );
    }

    return (
        <a className="w-full min-w-0" href={link}>
            <div id={id} className="h-full flex flex-col gap-4">
                {cardContent}
            </div>
        </a>
    );
};

export default CoralCardWithThumbnail;
