import React, { useEffect, useRef } from 'react';
import { createElement, loadAirwallex } from 'airwallex-payment-elements';
import { v4 as uuid } from 'uuid';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

// Documentation: https://github.com/airwallex/airwallex-payment-demo/tree/master/docs/airwallex-payment-elements

// Detailed payment intent request and response type can be found here:
// https://www.airwallex.com/docs/api#/Payment_Acceptance/Payment_Intents/_api_v1_pa_payment_intents_create/post
type PaymentIntentRequest = {
  request_id: string;
  merchant_order_id: string;
  amount: number;
  currency: string;
  order: {
    products: {
      url: string;
      name: string;
      desc: string;
      unit_price: number;
      currency: string;
      quantity: number;
    }[];
  };
};

type PaymentIntentBody = {
  amount: number;
  client_secret: string;
  id: string;
  currency: string;
};

// warning: This domain is for creating mock payment intents.
// The intents can only be paid in the "demo" environment.
// Please use the API on production to create real payment intent
// that can be paid in "prod". You can refer to the docs on how
// to create payment intents in "prod" here:
// https://www.airwallex.com/docs/api#/Payment_Acceptance/Payment_Intents/_api_v1_pa_payment_intents_create/post
const API_DOMAIN = 'https://demo-pacheckoutdemo.airwallex.com';

export const createPaymentIntent = async (paymentIntent: PaymentIntentRequest): Promise<PaymentIntentBody> => {
  const intentData = await axios.post(`${API_DOMAIN}/api/v1/pa/payment_intents/create`, paymentIntent);
  return intentData.data;
}; 

const AirwallexPayment: React.FC = () => {
  const history = useHistory();
  const dropInElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loadDropInElement = async () => {
      await loadAirwallex({
        env: 'demo',
        origin: window.location.origin,
      });

      const intent = await createPaymentIntent({
        request_id: uuid(),
        merchant_order_id: uuid(),
        amount: 19.99,
        currency: 'USD',
        order: {
          products: [
            {
              url: 'https://your-product-url.com',
              name: 'Your Product Name',
              desc: 'Your Product Description',
              unit_price: 19.99,
              currency: 'AUD',
              quantity: 1,
            },
          ],
        },
      });

      const { id, client_secret, currency } = intent;

      const element = createElement('dropIn', {
        intent_id: id,
        client_secret,
        currency,
        customer_id: 'replace-with-your-customer-id', // Provide the customer ID for the subscription
        mode: 'recurring', // Set the mode to 'recurring' for subscription payments
        recurringOptions: {
          card: {
            next_triggered_by: 'merchant', // Set to 'merchant' for merchant-initiated payments
            // requires_cvc: false, // Set to false if you don't require CVC for subsequent payments
            currency,
          },
        },
        style: {
          popupWidth: 400,
          popupHeight: 549,
        },
      });

      if (element) {
        if (dropInElementRef.current) {
          element.mount(dropInElementRef.current);
        }
      }

      
      const onReady = (event: CustomEvent) => {
        console.log(`Element is mounted: ${JSON.stringify(event.detail)}`);
      };

      const onSuccess = (event: CustomEvent): void => {
        console.log(`Confirm success with ${JSON.stringify(event.detail)}`);
      
        // The event.detail object contains the following properties:
        // {
        //   id: string;
        //   client_secret: string;
        //   amount: number;
        //   currency: string;
        //   customer_id: string;
        //   payment_method_type: string;
        //   payment_method_id: string;
        //   payment_consent_id: string;
        //   status: string;
        //   created_at: string;
        //   updated_at: string;
        //   metadata: {
        //     [key: string]: string;
        //   };
        // }
      
        try {
          console.log(JSON.stringify(event.detail));
      
          // Redirect to the success page
          history.push('/success');
        } catch (error) {
          console.error('Error storing payment details:', error);
        }
      };

      const onError = (event: CustomEvent) => {
        const { error } = event.detail;
        console.error('There is an error', error);
      };

      const domElement = dropInElementRef.current;
      domElement?.addEventListener('onReady', onReady as EventListener);
      domElement?.addEventListener('onSuccess', onSuccess as EventListener);
      domElement?.addEventListener('onError', onError as EventListener);

      return () => {
        domElement?.removeEventListener('onReady', onReady as EventListener);
        domElement?.removeEventListener('onSuccess', onSuccess as EventListener);
        domElement?.removeEventListener('onError', onError as EventListener);
      };
    };

    loadDropInElement();
  }, [history]);

  return <div id="drop-in" ref={dropInElementRef} className="p-4" />;
};

export default AirwallexPayment;