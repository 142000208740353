// src/components/CoralButtons/JoinCoralButton.tsx

import React, { useContext, useState } from 'react';
import CoralButton from './CoralButton';
import { AuthContext } from '../../App'; // Update the import path as needed

interface JoinCoralButtonProps {
    size?: 'default'|'small'|'large';
    isAnimated?: boolean;
    className?: string;
    children?: React.ReactNode;
}

const JoinCoralButton: React.FC<JoinCoralButtonProps> = ({ size = 'default', isAnimated = true, className, children }) => {
    const { setShowAuth } = useContext(AuthContext);
    const environment = process.env.REACT_APP_ENVIRONMENT; // Access the environment variable

    const sizes: Record<'default'|'small'|'large', string> = {
        'default': 'px-5 py-4',
        'large': 'px-8 py-6',
        'small': 'px-3 py-2',
    }

    const [clickCount, setClickCount] = useState(0); // Initialize click counter
    const handleClick = () => {
        console.log('Primary CTA clicked', environment);
        setClickCount(clickCount + 1); // Increment click counter

        if (environment === 'prod') {
            if (clickCount >= 2) { // If clicked 3 times
                setShowAuth({ show: true, signIn: false }); // Show authentication modal
            } else {
                window.open('https://wearecoral.substack.com', '_blank'); // Redirect for production or staging
            }
        } else {
            setShowAuth({ show: true, signIn: false }); // Show authentication modal for non-production environments
        }
    };

    return (
        <CoralButton
            className={`${isAnimated ? 'animate-breathe' : ''} ${className} ${ sizes[`${size}`] }`}
            popOnHover={true}
            onClick={handleClick}
        >
            {children ? children : (environment === 'prod' ? 'Stay In Touch' : 'Join wearecoral')}
        </CoralButton>
    );
};

export default JoinCoralButton;