import React from 'react';
import AirwallexPayment from '../../components/AirwallexPayment/AirwallexPayment';

const Payment: React.FC = () => {
  return (
    <div className="flex justify-center">
      <div className="w-full max-w-4xl p-4">
        <div className="flex">
          <div className="w-1/2 pr-4">
            <h3 className="text-lg leading-6 font-nunito font-extrabold text-coral-black">
              Complete Your Payment
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Payment Details</p>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white py-5">
                  <label className="block text-sm font-medium text-gray-500">Amount Due</label>
                  <p className="text-base font-semibold">$19.99</p>
                </div>
                <div className="bg-white py-5">
                  <label className="block text-sm font-medium text-gray-500">Billing Information</label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="border border-gray-300 rounded w-full p-2 mb-2"
                      placeholder="Full Name"
                    />
                    <input
                      type="text"
                      className="border border-gray-300 rounded w-full p-2 mb-2"
                      placeholder="Address"
                    />
                    <input
                      type="text"
                      className="border border-gray-300 rounded w-full p-2 mb-2"
                      placeholder="City"
                    />
                    <div className="flex space-x-2">
                      <input
                        type="text"
                        className="border border-gray-300 rounded w-1/2 p-2"
                        placeholder="State/Province"
                      />
                      <input
                        type="text"
                        className="border border-gray-300 rounded w-1/2 p-2"
                        placeholder="ZIP/Postal Code"
                      />
                    </div>
                  </div>
                </div>
              </dl>
            </div>
          </div>
          <div className="w-1/2 pl-4">
            <label className="block text-sm font-medium text-gray-500">Payment Method</label>
            <AirwallexPayment />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;