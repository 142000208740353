import React from 'react';
import createCoralUseSpotifySelected from '../../../../assets/images/spotify/Spotify_Logo_RGB_Green.png';
import createCoralUseSpotify from '../../../../assets/images/spotify/Spotify_Logo_RGB_Black.png';
import createCoralUseCuratedCoralsWhite
  from '../../../../assets/images/create-coral/create-coral-use-curated-corals-white.png';
import createCoralUseCuratedCorals from '../../../../assets/images/create-coral/create-coral-use-curated-corals.png';
import { useHistory } from 'react-router-dom';
import useSpotifyConnect from '../../../../hooks/useSpotifyConnect';

interface CreateCoralInitialProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  coralBox: [any, React.Dispatch<React.SetStateAction<any>>];
  pageTurner: (page: string) => void;
}

const CreateCoralInitial: React.FC<CreateCoralInitialProps> = ({ coralBox, pageTurner }) => {
  const [selectedBox, setSelectedBox] = coralBox;
  const history = useHistory();
  const { spotifyLinked, redirectToSpotify } = useSpotifyConnect();

  const handleBoxClick = (box: string) => {
    setSelectedBox(box);

    setTimeout(() => {
      if (box === 'spotify') {
        // Check if the user has already authorized the application via Spotify
        if (!spotifyLinked) {
          console.log('Spotify not linked');
          redirectToSpotify();
        } else {
          // Redirect to the /dashboard page and show a different modal
          history.push(`/create-coral?method=${ box }`);
          // Show a different modal
        }
      } else if (box === 'curated') {
        pageTurner('curated');
      } else if (box === 'manual') {
        history.push(`/create-coral?method=${ box }`);
      }
    }, 250); // Delay for 0.5 seconds
  };

  return (
    <>
      {/* <h1 className="font-nunito text-lg font-extrabold text-gray-900 mb-1">Let&apos;s Create a Coral</h1>
          <p className="text-sm text-gray-500 mb-4">
          Your coral will directly support chosen artists, nurturing the health of the music ecosystem.
          </p> */ }
      <h1 className="font-nunito text-lg font-extrabold text-gray-900 leading-[1.2] mb-2">Corals Thrive with Connection</h1>
      <p className="text-sm text-gray-500 mb-2">
        Connections simplify building your coral. They generate artist suggestions and allow your coral to sense
        and respond
        to your evolving musical journey.<br/><br/>
      </p>
      <div
        className="flex flex-col sm:flex-row justify-center items-stretch gap-4 mb-4">
        <button
          className={ `flex flex-col items-center border border-gray-200 rounded-lg shadow w-full sm:w-1/2 max-w-xs mx-auto` }
          onClick={ () => handleBoxClick('spotify') }>
          <img
            src={ selectedBox === 'spotify' ? createCoralUseSpotifySelected : createCoralUseSpotify }
            alt="Connect Spotify"
            className="w-36 h-36 object-contain p-4"
          />
        </button>
        <button
          className={ `flex flex-row items-center justify-center py-11 border border-gray-200 rounded-lg shadow w-full sm:w-1/2 max-w-xs mx-auto ${ selectedBox === 'curated' ? 'bg-curated-coral' : '' }` }
          onClick={ () => handleBoxClick('curated') }>
          <img
            src={ selectedBox === 'curated' ? createCoralUseCuratedCoralsWhite : createCoralUseCuratedCorals }
            alt="Connect to Curated Corals"
            className="w-16 h-16 object-contain mr-2"
          />
          <span className={ `text-lg font-bold font-nunito leading-[1.2] ${ selectedBox === 'curated' ? 'text-white' : '' }` }>Curated Corals</span>
        </button>
      </div>
      <div className="text-center mb-4">
        <button
          onClick={ () => handleBoxClick('manual') }
          className={ `text-sm ${ selectedBox === 'manual' ? 'font-bold' : '' } text-coral-pink no-underline hover:underline` }
        >
          or skip and add artists manually
        </button>
      </div>
    </>
  );
};

export default CreateCoralInitial;