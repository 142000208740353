import React, { useState } from 'react';
import CoralModal from '../../CoralModal/CoralModal';
import CreateCoralInitial from './Pages/CreateCoralInitial';
import CreateCoralFromCuratedArtists from './Pages/CreateCoralFromCuratedArtists';
import CreateCoralFromCuratedArtistsIndividual from './Pages/CreateCoralFromCuratedArtistsIndividual';

type CreateCoralModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const CreateCoralModal: React.FC<CreateCoralModalProps> = ({ isOpen, onClose }) => {
  const [page, setPage] = useState('initial');
  const [selectedBox, setSelectedBox] = useState<string|null>(null);
  const [coralGuid, setCoralGuid] = useState<string|null>(null);

  const pageTurner = (page: string, guid?: string) => {
    setPage(page);
    if (guid) {
      setCoralGuid(guid);
    }
  };

  return (
    <CoralModal isOpen={isOpen} onClose={() => {
      onClose();
      setSelectedBox(null);
      setPage('initial');
      setCoralGuid(null);
    }}>
      { page === 'initial' && <CreateCoralInitial coralBox={[selectedBox, setSelectedBox]} pageTurner={pageTurner} /> }
      { page === 'curated' && <CreateCoralFromCuratedArtists pageTurner={pageTurner} /> }
      { page === 'curated-individual' && coralGuid && <CreateCoralFromCuratedArtistsIndividual pageTurner={pageTurner} coralId={coralGuid} /> }
    </CoralModal>
  );
};

export default CreateCoralModal;

