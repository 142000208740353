import React, { useState, useEffect } from 'react';
import CoralButton from '../../components/CoralButtons/CoralButton';
import coralLogo from '../../assets/images/coral-icon.png'; 
import rewindIcon from '../../assets/images/rewind-icon.png'; 
import welcomeCoral from '../../assets/images/coral-wide-2.png';
import { getCurrentUser } from '@aws-amplify/auth';
import { User } from 'shared/types/platformTypes';
import { getUserFromAPI } from '../../api/api';
import Spinner from '../../components/Spinner/Spinner';
import { useHistory } from 'react-router-dom';

const Introduction: React.FC = () => {
    const [animationStage, setAnimationStage] = useState(0);
    const [user, setUser] = useState<User | null>(null);
    const history = useHistory();

    useEffect(() => {
        const fetchUser = async () => {
          try {
            const currentUser = await getCurrentUser();
            if (currentUser) {
              const fetchedUserProfile = await getUserFromAPI(currentUser.userId) as User;
              setUser(fetchedUserProfile);
            } else {
              setUser({ firstName: '' } as User); // Set user to a default value if not logged in
            }
          } catch (error) {
            setUser({ firstName: '' } as User); // Set user to a default value if not logged in
          }
        };
      
        fetchUser();
      }, []);

  useEffect(() => {
    const timeouts = [
      setTimeout(() => setAnimationStage(1), 2000), // First paragraph fade in
      setTimeout(() => setAnimationStage(2), 5000), // Second paragraph fade in
      setTimeout(() => setAnimationStage(3), 9000), // First paragraph fade out
      setTimeout(() => setAnimationStage(4), 11000), // Second section fade in
      setTimeout(() => setAnimationStage(5), 16000), // 2nd paragraph fade in
      setTimeout(() => setAnimationStage(6), 20000), // 3rd paragraph fade in
      setTimeout(() => setAnimationStage(7), 26000), // Fade out second section
      setTimeout(() => setAnimationStage(8), 28000), // Third message
      setTimeout(() => setAnimationStage(9), 32000), // Fourth message
      setTimeout(() => setAnimationStage(10), 35000), // Coral image fading in
      setTimeout(() => setAnimationStage(11), 35100), // wearecoral fading in
      setTimeout(() => setAnimationStage(12), 38000), // weare fading away
      setTimeout(() => setAnimationStage(13), 40000), // weare block being removed (allow 1 second transition)
      setTimeout(() => setAnimationStage(14), 40000), // icon and button fading in
    ];

    return () => {
      // Clear timeouts if the component is unmounted during the animation
      timeouts.forEach(clearTimeout);
    };
  }, []);

  if (!user) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner />
      </div>
    );
  }  

    return (
        <div className="flex flex-col items-center min-h-screen py-10 sm:py-16 sm:px-6 lg:px-8 font-nunito">
            <div className="max-w-xl w-full">
                <h1 className="text-center text-3xl sm:text-4xl lg:text-5xl font-extrabold text-gray-900 mb-4">
                    {user.firstName ? `Welcome, ${user.firstName}.` : 'Welcome.'} {/* Conditionally render welcome message */}
                </h1>

                {animationStage >= 1 && (
                    <p className={`text-center text-lg sm:text-xl lg:text-2xl mt-4 mb-4 px-4 sm:px-8 lg:px-16 font-nunito font-extrabold ${animationStage >= 3 ? 'animate-fadeOut' : 'animate-fadeIn'}`} style={animationStage >= 4 ? { display: 'none' } : {}}>
                        Coral is built on the simple yet powerful idea of collective, coordinated action.
                    </p>
                )}

                {animationStage >= 2 && (
                    <p className={`text-center text-lg sm:text-xl lg:text-2xl mt-4 mb-4 px-4 sm:px-8 lg:px-16 font-nunito font-extrabold ${animationStage >= 3 ? 'animate-fadeOut' : 'animate-fadeIn'}`} style={animationStage >= 4 ? { display: 'none' } : {}}>
                        Can we cooperate at scale to support the artists we love, nurturing music beyond the mainstream?
                    </p>
                )}                

                {animationStage >= 4 && (
                    <p className={`text-center text-lg sm:text-xl lg:text-2xl mt-4 mb-4 px-4 sm:px-8 lg:px-16 font-nunito font-extrabold ${animationStage >= 7 ? 'animate-fadeOut' : 'animate-fadeIn'}`} style={animationStage >= 8 ? { display: 'none' } : {}}>
                        Coral works when <em>many of us</em> each create a ‘coral’—select artists to support and make a small monthly contribution.
                    </p>
                )}

                {animationStage >= 5 && (
                    <p className={`text-center text-lg sm:text-xl lg:text-2xl mt-4 mb-4 px-4 sm:px-8 lg:px-16 font-nunito font-extrabold ${animationStage >= 7 ? 'animate-fadeOut' : 'animate-fadeIn'}`} style={animationStage >= 8 ? { display: 'none' } : {}}>
                        Every month, our coral shares our contribution amongst our artists.
                    </p>
                )}                

                {animationStage >= 6 && (
                    <p className={`text-center text-lg sm:text-xl lg:text-2xl mt-4 mb-4 px-4 sm:px-8 lg:px-16 font-nunito font-extrabold ${animationStage >= 7 ? 'animate-fadeOut' : 'animate-fadeIn'}`} style={animationStage >= 8 ? { display: 'none' } : {}}>
                        Collectively, this provides meaningful additional funding that supports artists in continuing to create music for us <em>all to enjoy</em>.
                    </p>
                )}

                {animationStage >= 8 && (
                    <p className="text-center text-lg sm:text-xl lg:text-2xl mt-4 mb-4 px-4 sm:px-8 lg:px-16 animate-fadeIn font-nunito font-extrabold" style={animationStage >= 8 ? {} : { visibility: 'hidden' }}>
                        Like a thriving coral reef, our collective small contributions can create something beautiful.
                    </p>
                )}

                {animationStage >= 9 && (
                    <p className="text-center text-lg sm:text-xl lg:text-2xl mt-4 mb-4 px-4 sm:px-8 lg:px-16 animate-fadeIn font-nunito font-extrabold" style={animationStage >= 9 ? {} : { visibility: 'hidden' }}>
                        Let&apos;s act together to cultivate a vibrant and healthy ecosystem of music.
                    </p>
                )}

                {animationStage >= 10 && (
                    <div className="flex justify-center mt-8 animate-fadeIn" style={animationStage >= 10 ? {} : { visibility: 'hidden' }}>
                        <img src={welcomeCoral} alt="Welcome Coral" />
                    </div>
                )}

                <div className="mt-8 mb-4 flex justify-center transition-all duration-500">
                    {animationStage >= 11 && (
                        <div className="animate-fadeIn" style={animationStage >= 11 ? { display: 'flex', alignItems: 'center' } : { visibility: 'hidden' }}>
                            {animationStage >= 14 && (
                                <img src={coralLogo} alt="Coral Logo" className="w-4 h-auto mr-2 animate-fadeIn" style={animationStage >= 12 ? {} : { visibility: 'hidden' }} />
                            )}
                            <div 
                                className={animationStage >= 12 ? "animate-fadeOutWidthToZero font-nunito font-extrabold text-2xl sm:text-3xl lg:text-4xl transition-opacity transition-transform duration-1000" : "font-nunito font-extrabold text-2xl sm:text-3xl lg:text-4xl"} 
                                style={animationStage >= 14 ? { display: 'none' } : { transformOrigin: 'center' }}
                            >
                                weare
                            </div>
                            <div className="font-nunito font-extrabold text-2xl sm:text-3xl lg:text-4xl">coral</div>
                        </div>
                    )}
                </div>              

                {animationStage === 14 && (
                    <div className="flex justify-center space-x-4 items-center animate-fadeIn">
                        <button onClick={() => {
                            window.location.reload();
                        }}>
                            <img src={rewindIcon} alt="Rewind" className="cursor-pointer h-7 w-7" />
                        </button>
                        <CoralButton onClick={() => history.push('/dashboard')} className="opacity-0 animate-breathe font-nunito font-extrabold text-lg sm:text-xl lg:text-2xl">Let&apos;s create</CoralButton>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Introduction;