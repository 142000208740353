import React, { useState, useEffect } from 'react';
import closeIcon from '../../assets/images/close.png';
import CoralButton from '../CoralButtons/CoralButton';
import Spinner from '../Spinner/Spinner';
import { ClientCoral } from 'shared/types/platformTypes';
import { addTagToCoral, removeTagFromCoral, retrieveTagsForCoral } from '../../api/api';

type ShareCoralModalProps = {
  isOpen: boolean;
  onClose: () => void;
  coralData: ClientCoral;
  toggleShare: () => Promise<void>;
  toggleCurated: () => Promise<void>;
};

const ShareCoralModal: React.FC<ShareCoralModalProps> = ({ isOpen, onClose, coralData, toggleShare, toggleCurated }) => {
  const [copied, setCopied] = useState(false);
  const [shared, setShared] = useState(coralData.coralShared);
  const [curated, setCurated] = useState(coralData.coralCurated);
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [newTag, setNewTag] = useState('');
  const [suggestedTags] = useState<string[]>([
    'rock',
    'pop',
    'hip-hop',
    'country',
    'jazz',
    'blues',
    'indie',
    'techno',
    'electronic',
    'classical',
    'australia',
    'us',
    'uk'
  ]);


  useEffect(() => {
    const fetchTags = async () => {
      if (coralData.sharedCoralGuid) {
        const retrievedTags = await retrieveTagsForCoral(coralData.sharedCoralGuid);
        setTags(retrievedTags);
        console.log('Retrieved tags:', retrievedTags);
      }
    };

    if (isOpen) {
      fetchTags();
    }
  }, [isOpen, coralData.sharedCoralGuid]);

  const handleAddTag = async () => {
    if (newTag.trim() !== '') {
      setTags(prevTags => [...prevTags, newTag.trim()]);
      try {
        await addTagToCoral(coralData.sharedCoralGuid!, newTag.trim());
        setNewTag('');
      } catch (error) {
        console.error('Failed to add tag to coral:', error);
        setTags(prevTags => prevTags.filter(tag => tag !== newTag.trim()));
      }
    }
  };

  const handleRemoveTag = async (tagToRemove: string) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
    await removeTagFromCoral(coralData.sharedCoralGuid!, tagToRemove);
  };
  
    if (!isOpen) return null;
  
    const handleCopyLink = () => {
      navigator.clipboard.writeText(`${process.env.REACT_APP_HOSTNAME}/c/${coralData.uniqueUrl}`);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    };
  
    const handleToggleShare = async () => { // Make this function async
      setIsLoading(true); // Set loading to true before the server call
      await toggleShare(); // Wait for the server call to complete
      setShared(!shared);
      setIsLoading(false); // Set loading to false after the server call
    };
  
    const handleToggleCurated = () => {
      setCurated(!curated);
      toggleCurated();
    };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      {/* Overlay to close modal on click outside */}
      <div className="absolute inset-0 bg-transparent" onClick={onClose}></div>

      <div className="relative bg-white rounded-lg shadow-2xl w-[400px] mx-auto">
        {/* Close button */}
        <button
          onClick={onClose}
          className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2"
        >
          <img src={closeIcon} alt="Close" className="w-10 h-10 object-contain" />
        </button>

        {/* Modal content */}
        <div className="relative mx-auto p-8">
          <h1 className="font-nunito text-lg font-extrabold text-gray-900 mb-4">Sharing Settings</h1>

          {/* Share toggle */}
          <div className="flex items-center mb-4">
            <label htmlFor="shareToggle" className="flex-grow text-sm font-medium text-gray-900">
              Generate Public Link
            </label>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="shareToggle"
                id="shareToggle"
                className="sr-only peer"
                checked={shared}
                onChange={handleToggleShare}
              />
              <div className="relative w-11 h-6 bg-rose-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-gradient-to-r peer-checked:from-coral-pink peer-checked:to-coral-orange"></div>
            </label>
          </div>

          {/* Curated toggle */}
          <div className="flex items-center mb-6">
            <label htmlFor="curatedToggle" className="flex-grow text-sm font-medium text-gray-900">
              Add to Curated Corals
            </label>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                name="curatedToggle"
                id="curatedToggle"
                className="sr-only peer"
                checked={curated}
                onChange={handleToggleCurated}
                disabled={!shared}
              />
              <div className={`relative w-11 h-6 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 ${shared ? 'bg-rose-200 peer-checked:bg-gradient-to-r peer-checked:from-coral-pink peer-checked:to-coral-orange' : 'bg-gray-200 cursor-not-allowed'}`}></div>
            </label>
          </div>

          {/* Share link */}
          <div className="mb-6" style={{ height: '4rem' }}>
            {isLoading ? (
              <div className="flex justify-center">
                <Spinner />
              </div>
            ) : shared && (
              <div>
                <p className="text-sm text-gray-500 mb-2">Public Link</p>
                <div className="flex">
                  <input
                    type="text"
                    readOnly
                    value={`${process.env.REACT_APP_HOSTNAME}/c/${coralData.uniqueUrl}`}
                    className="flex-grow px-4 py-2 text-sm text-gray-700 bg-gray-100 rounded-l-md focus:outline-none"
                  />
                  <button
                    onClick={handleCopyLink}
                    className="px-4 text-sm text-white bg-coral-pink rounded-r-md hover:bg-coral-pink-dark focus:outline-none"
                  >
                    {copied ? 'Copied!' : 'Copy'}
                  </button>
                </div>
              </div>
            )}
          </div>

          {coralData.sharedCoralGuid ? (
            /* Tags section */
            <div className="mb-6">
              <p className="text-sm text-gray-500 mb-2">Tags</p>
              <div className="flex flex-wrap gap-2 mb-2">
                {tags.map((tag) => (
                  <div
                    key={tag}
                    className="px-2 py-1 text-sm text-gray-700 bg-gray-100 rounded-md cursor-pointer"
                    onClick={() => handleRemoveTag(tag)}
                  >
                    {tag}
                  </div>
                ))}
              </div>
              <div className="flex">
                <input
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  className="flex-grow px-4 py-2 text-sm text-gray-700 bg-gray-100 rounded-l-md focus:outline-none"
                  placeholder="Add a tag"
                />
                <button
                  onClick={handleAddTag}
                  className="px-4 text-sm text-white bg-coral-pink rounded-r-md hover:bg-coral-pink-dark focus:outline-none"
                >
                  Add
                </button>
              </div>

              {/* Suggested tags section */}
              <div className="mt-6">
                <p className="text-sm text-gray-500 mb-2">Suggested Tags</p>
                <div className="flex flex-wrap gap-2 mb-2">
                  {suggestedTags.filter(tag => !tags.includes(tag)).map((tag) => (
                    <button
                      key={tag}
                      className="px-2 py-1 text-sm text-gray-700 bg-gray-100 rounded-md cursor-pointer"
                      onClick={() => {
                        (async () => {
                          setTags([...tags, tag.trim()]);
                          await addTagToCoral(coralData.sharedCoralGuid!, tag.trim());
                        })();
                      }}
                    >
                      {tag}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          ) : null}       

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <CoralButton onClick={onClose}>Done</CoralButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareCoralModal;