import React from 'react';
import privacyHeroImage from '../../assets/images/coral-wide-1.png';
import lightBlueWaveImage from '../../assets/images/light-blue-wave.png';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="fade-in-top">
            <div className="max-w-screen-xl mx-auto">
                {/* Page title */}
                <div className="p-16 bg-white w-auto md:w-2/3 text-left">
                    <h1 className="font-nunito text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-coral-black font-extrabold">
                        Privacy and Transparency
                    </h1>
                </div>
            </div>
            
            {/* Hero image section */}
            <div className="2xl:hidden">
                <img src={privacyHeroImage} alt="Privacy Policy Hero" className="w-full h-auto object-cover" />
            </div>

            <div className="2xl:h-[500px] relative">
                <img src={privacyHeroImage} alt="Privacy Policy Hero" className="absolute inset-0 w-full h-full object-cover"/>
            </div>

            {/* Privacy Policy section */}
            <div className="p-16 pb-6">
                <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row">
                    {/* Left column for the header */}
                    <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                        <h2 className="text-sm md:text-base font-extrabold">
                            <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                TRANSPARENCY
                            </div>
                        </h2>
                        <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
                            Our View
                        </h2>
                    </div>
                    
                    {/* Right column for placeholder text */}
                    <div className="md:w-1/2">
                        <p className="text-sm sm:text-base md:text-lg text-coral-black">
                            At wearecoral, we are committed to protecting your privacy and being a trusted partner of the music ecosystem.<br /><br />
                            We aim to provide clear visibility into our operations, including both internal finances and membership contributions and payments.<br /><br />
                            By safeguarding your privacy, demonstrating our capability as a trusted steward, and providing fair and transparent infrastructure, we support you to make informed decisions and contribute to a thriving future for music.
                        </p>
                    </div>
                </div>
            </div>

{/* Privacy Policy section */}
<div className="p-16 pt-6">
  <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row">
    {/* Left column for the header */}
    <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
      <h2 className="text-sm md:text-base font-extrabold">
        <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
          PRIVACY POLICY
        </div>
      </h2>
      <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
        The Legalese
      </h2>
    </div>

    {/* Right column for privacy policy summary */}
    <div className="md:w-1/2">
    <p className="text-sm sm:text-base md:text-lg text-coral-black">
    <b>Effective Date: 13th March 2024</b>
    </p>
    <p className="text-sm sm:text-base md:text-lg text-coral-black mt-4">
      At wearecoral, we are committed to protecting your privacy and being transparent about how we collect, use, and share your personal information. This Privacy Policy outlines our practices regarding the information we collect from you, how we use it, and your rights and choices concerning your information. By using the wearecoral platform and services, you agree to the collection, use, and sharing of your information as described in this Privacy Policy.
    </p>

    <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6 mb-2">
      1. Information We Collect
    </h3>
    <p className="text-sm sm:text-base md:text-lg text-coral-black">
      1.1 Information You Provide: When you create an account, create a Coral, or interact with the platform, we collect information you provide, such as your name, email address, country of residence, payment information, and any other information you choose to provide.
    </p>
    <p className="text-sm sm:text-base md:text-lg text-coral-black mt-2">
      1.2 Information We Collect Automatically: We automatically collect certain information about your interactions with the platform, including your IP address, browser type, device information, and usage data. We may also collect information through cookies and similar technologies.
    </p>
    <p className="text-sm sm:text-base md:text-lg text-coral-black mt-2">
      1.3 Information from Third Parties: We may receive information about you from third parties, such as payment processors, social media platforms, and music data services. This may include your name, email address, and payment information.
    </p>

    <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6 mb-2">
      2. How We Use Your Information
    </h3>
    <p className="text-sm sm:text-base md:text-lg text-coral-black">
      We use the information we collect to:
    </p>
    <ul className="list-disc list-inside text-sm sm:text-base md:text-lg text-coral-black mt-2">
      <li>Provide and improve the wearecoral platform and services</li>
      <li>Process payments and distribute money to artists</li>
      <li>Communicate with you about your account and the platform</li>
      <li>Personalize your experience on the platform</li>
      <li>Analyze usage trends and preferences</li>
      <li>Comply with legal obligations and enforce our rights</li>
    </ul>

    <p className="text-sm sm:text-base md:text-lg text-coral-black mt-4">
      Legal Basis for Processing (EU Users):
    </p>
    <p className="text-sm sm:text-base md:text-lg text-coral-black mt-2">
      For users in the European Union, we process your personal data based on the following legal grounds:
    </p>
    <ul className="list-disc list-inside text-sm sm:text-base md:text-lg text-coral-black mt-2">
      <li>Performance of a Contract: We process your data to provide the services you have requested and to fulfill our contractual obligations to you.</li>
      <li>Legitimate Interests: We may process your data for our legitimate interests, such as improving our platform, preventing fraud, and ensuring security.</li>
      <li>Consent: In some cases, we may ask for your consent to process your data for specific purposes, such as sending marketing communications.</li>
      <li>Legal Obligations: We may process your data to comply with legal obligations, such as tax and accounting requirements.</li>
    </ul>

    <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6 mb-2">
      3. Sharing Your Information
    </h3>
    <p className="text-sm sm:text-base md:text-lg text-coral-black">
      3.1 With Artists: We may provide functionality that allows artists to communicate with you through the platform. However, we will not share your personal details, such as your name and email address, directly with artists unless you explicitly enable this setting.
    </p>
    <p className="text-sm sm:text-base md:text-lg text-coral-black mt-2">
      3.2 With Other Users: Your username and other profile information may be visible to other users of the platform. Your contributions will be linked to your unique Coral, but the personal details related to your Coral will not be made public unless you explicitly enable this setting.
    </p>
    <p className="text-sm sm:text-base md:text-lg text-coral-black mt-2">
      3.3 With Service Providers: We may share your information with trusted third-party service providers who assist us in operating the platform and providing services to you. These providers may include payment processors, data analytics companies, and customer support services.
    </p>
    <p className="text-sm sm:text-base md:text-lg text-coral-black mt-2">
      3.4 Financial Transparency: In line with our commitment to transparency, the total amount of contributions, and payments will be publicly available. However, the personal details of individuals making contributions will not be publicly disclosed unless explicitly enabled by the user.
    </p>
    <p className="text-sm sm:text-base md:text-lg text-coral-black mt-2">
      3.5 Legal Requirements: We may disclose your information if required to do so by law or in response to valid legal requests, such as court orders or government inquiries.
    </p>

    <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6 mb-2">
      4. Your Rights and Choices
    </h3>
    <p className="text-sm sm:text-base md:text-lg text-coral-black">
      4.1 Access and Update Your Information: You can access and update your account information through your account settings.
    </p>
    <p className="text-sm sm:text-base md:text-lg text-coral-black mt-2">
      4.2 Opt-out of Communications: You may opt-out of receiving promotional communications from wearecoral by following the instructions in those communications.
    </p>
    <p className="text-sm sm:text-base md:text-lg text-coral-black mt-2">
      4.3 Cookies: You can control the use of cookies through your browser settings, but please note that some features of the platform may not function properly without cookies.
    </p>
    <p className="text-sm sm:text-base md:text-lg text-coral-black mt-2">
      4.4 EU User Rights: If you are a user in the European Union, you have the right to request access to, rectification, erasure, or restriction of your personal data. You also have the right to data portability and the right to object to certain processing activities. To exercise these rights, please contact us at privacy@wearecoral.com.
    </p>

    <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6 mb-2">
      5. Data Retention and Security
    </h3>
    <p className="text-sm sm:text-base md:text-lg text-coral-black">
      We retain your information for as long as necessary to provide the services and fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. We implement reasonable security measures, such as encryption and access controls, to protect your information from unauthorized access, alteration, or destruction.
    </p>

    <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6 mb-2">
      6. Children&apos;s Privacy
    </h3>
    <p className="text-sm sm:text-base md:text-lg text-coral-black">
      The wearecoral platform is not intended for use by children under the age of 16. We do not knowingly collect personal information from children under 16. If we become aware that we have collected personal information from a child under 16, we will take steps to delete such information.
    </p>

    <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6 mb-2">
      7. Changes to this Privacy Policy
    </h3>
    <p className="text-sm sm:text-base md:text-lg text-coral-black">
      We may update this Privacy Policy from time to time. If we make material changes, we will notify you by email or by posting a prominent notice on the platform prior to the effective date of the changes.
    </p>

    <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6 mb-2">
      8. Contact Us
    </h3>
    <p className="text-sm sm:text-base md:text-lg text-coral-black">
      If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at privacy@wearecoral.com.
    </p>

    <p className="text-sm sm:text-base md:text-lg text-coral-black mt-4">
      By using the wearecoral platform and services, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and sharing of your information as described herein.
    </p>
    </div>
  </div>
</div>
            {/* Keep the blue wave image */}
            <div className="relative w-full h-full">
                <img src={lightBlueWaveImage} alt="Light Blue Wave" className="w-full h-full object-cover" />
                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-light-blue to-transparent"></div>
            </div>

            {/* Placeholder section for future content */}
            <div className="p-16 pt-0 bg-coral-light-blue">
                <div className="max-w-screen-xl mx-auto flex flex-col items-center">
                            <div className="w-full mb-4">
                                <h2 className="font-nunito text-2xl md:text-3xl font-extrabold pb-6 text-center">
                                    A vibrant, healthy, and diverse ecosystem of music, artists and communities.
                                </h2>
                            </div>
                            <JoinCoralButton />
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
