import React, { useState } from 'react';
// import MiniArtistTable from '../../CoralEditor/ArtistTable/MiniArtistTable';
import { ClientArtistListItem, SourceType } from 'shared/types/platformTypes';
import SecondaryCoralButton from '../../CoralButtons/SecondaryCoralButton';
import ConnectionPill from '../../ConnectionPill/ConnectionPill';
import spotifyIcon from '../../../assets/images/spotify/Spotify_Icon_RGB_Green.png';
import Spinner from '../../../components/Spinner/Spinner';

export interface ConfigureConnectionsProps {
  artists: Array<ClientArtistListItem>;
  updateFilter: (filterType: 'genre' | 'location', value: string, action: 'add' | 'remove') => void; // A function to update the filters
  addArtistFromDynamicArtistPool: (artist: ClientArtistListItem) => void; 
  removeArtist: (artistId: string) => void;
  removeArtistsBySourceArtistPoolGuid: (sourceArtistPoolGuid: string) => void;
  handleSyncWithSpotify: () => void;
  retrieveTopArtists: () => void;
  dynamicArtistPools: { sourceType: SourceType; sourceArtistPoolGuid: string; }[];
  spotifyLinked: boolean;
  pageTitle?: string;
}


const ConfigureConnections: React.FC<ConfigureConnectionsProps> = ({ dynamicArtistPools, spotifyLinked, retrieveTopArtists, removeArtistsBySourceArtistPoolGuid, handleSyncWithSpotify, pageTitle = 'Select From Available Connections' }) => {  
  const [isProcessing, setIsProcessing] = useState(false);
  const hasTopArtists = dynamicArtistPools.some(pool => pool.sourceType === SourceType.FAVOURITE_ARTISTS);

  const handleRetrieveTopArtists = async () => {
    setIsProcessing(true);
    await retrieveTopArtists();
    setIsProcessing(false);
  };

  return (
    <div className="flex flex-col items-start px-4 py-4">
      <div className="w-full max-w-4xl">
      <h2 className="font-nunito text-2xl md:text-3xl font-extrabold mb-4">{pageTitle}</h2>
      <p className="text-sm md:text-base text-gray-500 mb-4">
        Connections enable <em>Living Filters</em>.<br /><br />
        Living Filters automatically select artists from your suggestions using genre or location. They update continuously, 
        ensuring your coral remains vibrant and responsive to your musical tastes.
      </p>

      <div className="mb-4 font-nunito text-xl font-bold">Available Connections</div>
        <div className="flex-grow flex justify-start mb-4">
          {!spotifyLinked ? (
            <button
              className="flex items-center justify-center px-4 py-2 border rounded-full shadow text-sm font-medium text-green-600 border-green-600 bg-white hover:bg-green-50 mr-2"
              onClick={handleSyncWithSpotify}
            >
              <img src={spotifyIcon} alt="Spotify" className="w-5 h-5 mr-2" />
              SPOTIFY
            </button>
          ) : !hasTopArtists ? (

            <ConnectionPill onClick={handleRetrieveTopArtists} variant='spotify' isActive={false}>
              {isProcessing ? (
                <>
                  Connecting...&nbsp;&nbsp;   
                  <Spinner color="text-green-600" />
                </>
              ) : 'ADD YOUR TOP ARTISTS'}
            </ConnectionPill>
          ) : null}

          <SecondaryCoralButton onClick={() => {}}>
            CURATED CORALS
          </SecondaryCoralButton>
        </div>       
      </div>
      <div className="mb-4 font-nunito  text-xl font-bold">Active Connections</div>
      <div className="flex-grow flex justify-start mb-10">
        {/* Render ConnectionPill for each dynamic artist pool */}
        {dynamicArtistPools.map((pool, index) => (
          
          <ConnectionPill 
            key={index} 
            onClick={() => {removeArtistsBySourceArtistPoolGuid(pool.sourceArtistPoolGuid)}}
            onRemove={() => {removeArtistsBySourceArtistPoolGuid(pool.sourceArtistPoolGuid)}}
            variant={pool.sourceType === SourceType.FAVOURITE_ARTISTS ? 'spotify' : 'standard'}
            isActive={true}
          >
            {pool.sourceType === SourceType.FAVOURITE_ARTISTS ? 'TOP ARTISTS' : pool.sourceType}
          </ConnectionPill>
        ))}
    </div>
    </div>
  );
};

export default ConfigureConnections;

