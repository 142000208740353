import React from 'react';

type Step = {
  name: string;
};

type NavigationTrailProps = {
  currentStep: number;
  steps: Step[];
  isManualAdd: boolean;
  onStepClick: (step: number) => void; // Add this prop to handle step click
};

const NavigationTrail: React.FC<NavigationTrailProps> = ({ currentStep, steps, isManualAdd, onStepClick }) => {
  // If manual adding is used, filter out the 'Refine Selection' step
  const filteredSteps = isManualAdd
    ? steps.filter((step) => step.name !== 'Refine Selection')
    : steps;

  return (
    <div className="flex items-center space-x-1 sm:space-x-4">
      {filteredSteps.map((step, index) => {
        const isActive = index === currentStep;
        const isNext = index > currentStep;
        return (
          <div key={step.name} className="flex items-center">
            <button
              onClick={() => onStepClick(index)} // Add onClick handler here
              className={`flex items-center text-sm sm:text-base ${isActive ? 'font-bold text-gray-900' : isNext ? 'text-gray-900' : 'font-bold text-gray-500'}`}
              style={{ borderBottom: isActive ? '3px solid coral' : 'none' }}
            >
              {step.name}
            </button>
            {index < filteredSteps.length - 1 && (
              <div className="px-6 py-1 text-gray-500">{'>'}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default NavigationTrail;