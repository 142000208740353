import { Amplify } from 'aws-amplify';

Amplify.configure(
  {
    Auth: {
      Cognito: {
        userPoolId: process.env.REACT_APP_USER_POOL_ID, // Environment variable
        userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        signUpVerificationMethod: 'code', // or 'link', depending on your setup
        // loginWith: {
        //   oauth: {
        //     domain: 'YOUR_COGNITO_DOMAIN',
        //     scopes: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        //     redirectSignIn: ['YOUR_SIGN_IN_URL'],
        //     redirectSignOut: ['YOUR_SIGN_OUT_URL'],
        //     responseType: 'code' // or 'token'
        //   }
        // }
      }
    }
  }
);
