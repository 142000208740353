import React, { useEffect } from 'react';
import { CoralSimulationResult } from 'shared/types/platformTypes';
import selectedStar from '../../../assets/images/selected-star.png';
import unSelectedStar from '../../../assets/images/unselected-star.png';
import infoIcon from '../../../assets/images/info-icon.png';
import { Tooltip } from 'react-tooltip';

interface MiniCoralSimulationTableProps {
  artistAllocations: CoralSimulationResult[];
  addPinnedArtist: (artistId: string) => void;
  removePinnedArtist: (artistId: string) => void;
  pinnedArtists: string[];
  simulateCoral: () => void; 
}

const MiniCoralSimulationTable: React.FC<MiniCoralSimulationTableProps> = ({ artistAllocations, addPinnedArtist, removePinnedArtist, pinnedArtists, simulateCoral }) => {
  useEffect(() => {
    simulateCoral();
  }, [pinnedArtists]);

  return (
    <div className="overflow-x-auto">
      <table className="min-w-max w-full">
        <thead className="text-gray-400 text-xs">
            <tr>
                <th className="px-0 py-3 text-right align-bottom"></th>
                <th className="px-3 py-3 text-right align-bottom"></th>
                <th className="px-0 sm:px-6 py-3 text-right align-bottom">Monthly<br />Impact</th>
                <th className="px-1 py-3 text-right align-bottom">
                  <span data-tooltip-id='coralsTooltip' data-tooltip-content="Our collective impact if there were just 5000 corals like yours.">
                    5k<br />Corals
                  </span>
                  <Tooltip id='coralsTooltip' />
                </th>
                <th className="px-1 py-3 text-right align-bottom"></th>
            </tr>
        </thead>
        <tbody className="bg-white text-sm">
          {artistAllocations.map((allocation, index) => (
            <tr key={index} className={`border-b ${allocation.included ? 'text-gray-900 font-semibold' : 'text-gray-400'}`}>
              <td className="px-0 py-4 whitespace-nowrap">
                {allocation.included && (
                  <img 
                    className="transition-transform duration-500 ease-in-out transform hover:scale-110"
                    src={pinnedArtists.includes(allocation.artist_id) ? selectedStar : unSelectedStar} 
                    alt="Star" 
                    width="24" 
                    height="24"
                    onClick={() => {
                      if (pinnedArtists.includes(allocation.artist_id)) {
                        removePinnedArtist(allocation.artist_id);
                      } else if (pinnedArtists.length < 2) {
                        addPinnedArtist(allocation.artist_id);
                      }
                    }}
                  />
                )}
              </td>
              <td className="px-3 py-4 whitespace-nowrap overflow-hidden overflow-ellipsis max-w-28 sm:whitespace-normal sm:overflow-visible sm:max-w-full">
                {allocation.artist_name}
              </td>
              <td className="px-0 sm:px-6 py-4 whitespace-nowrap text-right">${allocation.allocated_amount.toFixed(2)}</td>
              <td className="px-1 py-4 whitespace-nowrap text-right text-gray-500">${allocation.impact_5000_users.toFixed(0)}</td>
              <td className="px-1 py-4 whitespace-nowrap text-right">
                <button data-tooltip-id='reasonsTooltip' data-tooltip-content={allocation.reasons.join(', ')} className="text-blue-500 hover:text-blue-700">
                  <img src={infoIcon} alt="Inclusion details" className="h-4 w-4 mt-1.5" />
                </button>
                <Tooltip id='reasonsTooltip' />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MiniCoralSimulationTable;