import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { SingleValue } from 'react-select';
import flags from 'emoji-flags'; // Used to retrieve flag emojis
import { getCurrentUser } from '@aws-amplify/auth';
import { User } from 'shared/types/platformTypes';
import { updateUser, getUserFromAPI } from '../../api/api';
import CoralButton from '../../components/CoralButtons/CoralButton';
import Spinner from '../../components/Spinner/Spinner';

const ProfileSetup: React.FC = () => {
    const [firstName, setFirstName] = useState('');
    const [country, setCountry] = useState<{ value: string; label: string; flag: string; } | null>(null);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [isArtist, setIsArtist] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const user = await getCurrentUser();
                const fetchedUserProfile = await getUserFromAPI(user.userId) as User;
                setCurrentUser(fetchedUserProfile);
                if (fetchedUserProfile.settings?.profileSetupComplete) {
                    // history.push('/dashboard');
                }
            } catch (error) {
                console.error('Error fetching user:', error);
            }
        };

        fetchUser();
    }, [history]);

    if (currentUser === null) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Spinner />
            </div>
        );
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (!country) {
            console.error('Country not set'); // Debugging line
            return;
        }

        try {
            const updatedUser: Partial<User> = {
                userId: currentUser.userId,
                firstName: firstName,
                country: country.label,
                countryAtSignUp: country.label,
                artistConnections: isArtist,
                settings: {
                    ...currentUser.settings,
                    profileSetupComplete: true,
                },
            };

            await updateUser(updatedUser);
            window.scrollTo(0, 0);
            history.push('/welcome');
        } catch (error) {
            console.error('Error updating user', error);
        }
    }; 

    const priorityCountries = ['AU', 'US', 'CA', 'GB'];

    const countryOptions = flags.data
        .sort((a, b) => {
            if (priorityCountries.includes(a.code) && priorityCountries.includes(b.code)) {
                return priorityCountries.indexOf(a.code) - priorityCountries.indexOf(b.code);
            }
            if (priorityCountries.includes(a.code)) {
                return -1;
            }
            if (priorityCountries.includes(b.code)) {
                return 1;
            }
            return a.name.localeCompare(b.name);
        })
        .map(flag => ({
            value: flag.code,
            label: flag.name,
            flag: flag.emoji,
        }));

    const formatOptionLabel = ({ label, flag }: { label: string; flag: string; }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '10px' }}>{flag}</div>
            <div>{label}</div>
        </div>
    );

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value); // New handler for first name change
    };

    const handleCountryChange = (
        selectedOption: SingleValue<{ value: string; label: string; flag: string; }>,
    ) => {
        if (selectedOption === null) {
            setCountry(null);
        } else {
            setCountry(selectedOption);
        }
    };

    return (
        <div className="flex flex-col justify-center items-center h-screen px-4">
            <div className="w-full max-w-md bg-white p-8">
                <h2 className="font-nunito text-xl md:text-2xl font-extrabold mb-4">
                    Hello.
                </h2>
                <p className="text-base mb-6 font-nunito font-semibold">
                    Before we get started, we&apos;d like to get to know you a little better.
                </p>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div>
                        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700 mb-2">First Name</label>
                        <input 
                            id="first-name" 
                            name="first-name" 
                            type="text" 
                            required 
                            className="w-full p-2 text-base border border-gray-300 rounded-md shadow-sm"
                            placeholder=""
                            value={firstName}
                            onChange={handleFirstNameChange}
                        />
                    </div>
                    <div>
                        <label htmlFor="country" className="block text-sm font-medium text-gray-700 mb-2">Where do you live?</label>
                        <Select
                            id="country"
                            name="country"
                            options={countryOptions}
                            formatOptionLabel={formatOptionLabel}
                            className="basic-single"
                            classNamePrefix="select"
                            isSearchable
                            required
                            placeholder="Select a country..."
                            value={country}
                            onChange={handleCountryChange}
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.value}
                            theme={theme => ({
                                ...theme,
                                borderRadius: 1,
                                colors: {
                                    ...theme.colors,
                                    primary25: 'lightgray',
                                    primary: 'gray',
                                },
                            })}
                        />
                    </div>
                    <div>
                        <div className="flex items-center justify-between">
                            <span className="text-base font-nunito font-semibold">Do you want to register as an artist?</span>
                            <input
                                id="artist-checkbox"
                                type="checkbox"
                                className="form-checkbox h-5 w-5 text-coral-600 ml-2"
                                checked={isArtist}
                                onChange={() => setIsArtist(!isArtist)}
                            />
                        </div>
                    </div>
                    {isArtist && (
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                To be eligible as an artist on wearecoral, you must be a professional published musical artist, either an individual or group.
                            </p>
                            <p className="text-sm text-gray-500 mt-2">
                                If you are an amateur musician, content creator, generative AI music company, or create non-music audio content such as sleep sounds or sound packs wearecoral is not for you. We encourage you to explore more general creator platforms like Patreon.
                            </p>
                        </div>
                    )}
                    <div>
                        <CoralButton className="font-semibold text-xs sm:text-sm w-full" onClick={handleSubmit}>Complete Setup</CoralButton>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ProfileSetup;