import React from 'react';
import coralIcon from '../../assets/images/coral-icon.png';

const Footer: React.FC = () => {
    return (
        <footer className="bg-primary-800 py-6 text-white">
            <div className="max-w-screen-xl mx-auto px-16 2xl:px-4 3xl:px-0">
                <div className="flex flex-col md:flex-row-reverse justify-between items-center md:items-start">
                    <div className="mb-6 md:mb-0">
                        <img src={coralIcon} alt="wearecoral logo" className="w-8 h-8 mb-3" />
                    </div>
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 text-sm">
                        <div>
                            <h6 className="font-bold mb-2">wearecoral</h6>
                            <ul className="list-none mb-0 space-y-2">
                                <li><a href="/" className="hover:text-gray-300 transition duration-200">Home</a></li>
                                <li><a href="/for-artists" className="hover:text-gray-300 transition duration-200">For Artists</a></li>
                            </ul>
                        </div>
                        <div>
                            <h6 className="font-bold mb-2">Company</h6>
                            <ul className="list-none mb-0 space-y-2">
                                <li><a href="/about" className="hover:text-gray-300 transition duration-200">About</a></li>
                                <li><a href="/principles" className="hover:text-gray-300 transition duration-200">Principles</a></li>
                                <li><a href="/trust" className="hover:text-gray-300 transition duration-200">Trust</a></li>
                            </ul>
                        </div>
                        <div>
                            <h6 className="font-bold mb-2">Support</h6>
                            <ul className="list-none mb-0 space-y-2">
                                <li><a href="/contact-us" className="hover:text-gray-300 transition duration-200">Contact Us</a></li>
                                <li><a href="/terms" className="hover:text-gray-300 transition duration-200">Terms</a></li>
                                <li><a href="/privacy" className="hover:text-gray-300 transition duration-200">Privacy</a></li>
                            </ul>
                        </div>
                        <div>
                            <h6 className="font-bold mb-2">Community</h6>
                            <ul className="list-none mb-0 space-y-2">
                                <li><a href="https://www.instagram.com/wearecoral/" target="_blank" rel="noopener noreferrer" className="hover:text-gray-300 transition duration-200">Instagram</a></li>
                                <li><a href="https://wearecoral.substack.com" target="_blank" rel="noopener noreferrer"  className="hover:text-gray-300 transition duration-200">Newsletter</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="text-center text-sm text-custom-gray mt-6">
                    <p>&copy; 2024 wearecoral Pty Ltd. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;