import React, { useEffect, useRef, useState } from 'react';
import { CoralSimulationResult } from 'shared/types/platformTypes';
import selectedStar from '../../assets/images/selected-star.png';
import unSelectedStar from '../../assets/images/unselected-star.png';
import { Tooltip } from 'react-tooltip';
import { BiSolidDonateHeart } from "react-icons/bi";
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';

interface PublicCoralArtistTableProps {
  coralName: string;
  coralSimulationResults: CoralSimulationResult[];
  simulateCoral: () => void;
  updatePledgeAmount: (amount: number) => void;
  pledgeAmount: number;
  addPinnedArtist: (artistId: string) => void;
  removePinnedArtist: (artistId: string) => void;
  pinnedArtists: string[];
  setIsOpen: (state: boolean) => void;
}

const PublicCoralArtistTableOverlayed: React.FC<PublicCoralArtistTableProps> = ({
  coralName,
  coralSimulationResults,
  simulateCoral,
  updatePledgeAmount,
  pledgeAmount,
  addPinnedArtist,
  removePinnedArtist,
  pinnedArtists,
  setIsOpen,
}) => {
  const [inputValue, setInputValue] = useState('');
  const ref = useRef(null);
  useOutsideAlerter(ref, () => setIsOpen(false));

  useEffect(() => {
      setInputValue(pledgeAmount === 0 ? '' : pledgeAmount.toString());
  }, [pledgeAmount]);

  useEffect(() => {
    if (pledgeAmount > 0) {
      simulateCoral();
    }
  }, [pledgeAmount, simulateCoral]);

  useEffect(() => {
    simulateCoral();
  }, [pinnedArtists, simulateCoral]);

  const handleUpdatePledgeAmount = () => {
    const decimalRegex = /^([1-9]\d*(\.\d{1,2})?)?$/;

    if (inputValue === '') {
      console.log('Textbox is empty');
    } else if (decimalRegex.test(inputValue)) {
      updatePledgeAmount(Number(inputValue));
      console.log('Pledge amount updated');
    } else {
      console.log('Invalid pledge amount');
    }
  };

  return (
    <section className="fixed inset-0 bg-[rgba(0,0,0,0.5)] grid place-items-center z-[1000] px-1">
      <section
        id="modal"
        className="h-fit max-h-[80vh] max-w-[740px] w-full border-[1px] border-solid border-[rgba(167,180,222,0.5)] rounded-md bg-gradient-to-b from-[rgba(167,180,222,0.5)] to-[rgba(167,180,222,0.2)] [backdrop-filter:blur(100px)] p-4 sm:p-6 overflow-y-auto"
        ref={ref}
      >
        <section className="flex flex-col">
          <div className="mb-2">
            <h2 className="font-nunito font-black text-2xl">{ coralName }</h2>
            <p className="text-primary-200 font-nunito text-base">CHAMPIONING MUSIC SINCE 2024</p>
          </div>
          <button
            className="w-fit bg-primary-100 border-primary-200 border-1 border-solid rounded-[5000px] text-primary-800 font-nunito text-sm px-4 py-2 flex flex-row content-center items-center gap-2">
            <span><BiSolidDonateHeart width={ 24 } height={ 24 }/></span>
            <span>7 Connected</span>
          </button>
        </section>
        <section className="overflow-y-auto max-h-[530px] my-6 pr-[0.7rem]">
          <table className="w-full mb-3">
            <thead className="text-xs">
            <tr>
              <th className="px-0 py-3 text-right align-bottom"></th>
              <th className="px-0 py-3 text-right align-bottom"></th>
              <th className="px-0 sm:px-6 py-3 text-right align-bottom">Your Impact</th>
              <th
                className="px-1 py-3 text-right align-bottom bg-gradient-to-r from-accent-400 via-accent-200 to-accent-800 text-transparent bg-clip-text">
                <span data-tooltip-id="ourImpactTooltip"
                      data-tooltip-content="Our collective impact if there were just 5000 corals like yours.">
                  Our Impact
                </span>
                <Tooltip id="ourImpactTooltip"/>
              </th>
            </tr>
            </thead>
            <tbody className="text-sm">
            { coralSimulationResults.length > 0 ? (
              coralSimulationResults.map((allocation, index) => (
                <tr key={ index }
                    className={ `border-b` }>
                  <td className="px-0 py-4 whitespace-nowrap">
                    { allocation.included && (
                      <img
                        className="transition-transform duration-500 ease-in-out transform hover:scale-110"
                        src={ pinnedArtists.includes(allocation.artist_id) ? selectedStar : unSelectedStar }
                        alt="Star"
                        width="24"
                        height="24"
                        onClick={ () => {
                          if (pinnedArtists.includes(allocation.artist_id)) {
                            removePinnedArtist(allocation.artist_id);
                          } else if (pinnedArtists.length < 2) {
                            addPinnedArtist(allocation.artist_id);
                          }
                        } }
                      />
                    ) }
                  </td>
                  <td
                    className="px-3 py-4 whitespace-nowrap overflow-hidden overflow-ellipsis max-w-28 sm:whitespace-normal sm:overflow-visible sm:max-w-full text-lg font-bold font-nunito text-primary-100">
                    { allocation.artist_name }
                  </td>
                  <td
                    className="px-0 sm:px-6 py-4 whitespace-nowrap text-right text-primary-200">${ allocation.allocated_amount.toFixed(2) }</td>
                  <td
                    className="px-1 py-4 whitespace-nowrap text-right bg-gradient-to-r from-accent-400 via-accent-200 to-accent-800 text-transparent bg-clip-text">${ allocation.impact_5000_users.toFixed(0) }</td>
                </tr>
              ))
            ) : (
              [...Array(7)].map((_, index) => (
                <tr key={ index } className="border-b animate-pulse">
                  <td className="px-0 py-4 whitespace-nowrap">
                    <div className="h-6 bg-gray-200 rounded-md w-6"></div>
                  </td>
                  <td className="px-3 py-4 whitespace-nowrap">
                    <div className="h-4 bg-gray-200 rounded-md w-32"></div>
                  </td>
                  <td className="px-0 sm:px-6 py-4 whitespace-nowrap text-right">
                    <div className="h-4 bg-gray-200 rounded-md w-16"></div>
                  </td>
                  <td className="px-1 py-4 whitespace-nowrap text-right">
                    <div className="h-4 bg-gray-200 rounded-md w-20"></div>
                  </td>
                </tr>
              ))
            ) }
            </tbody>
          </table>
        </section>
        <form
          onSubmit={ (event) => {
            event.preventDefault();
            handleUpdatePledgeAmount();
          } }
          className="flex flex-col items-end content-end gap-4"
        >
          <section className="flex items-center content-between gap-3">
            <label>
              <input
                type="text"
                inputMode="numeric"
                name="pledge-amount"
                id="pledge-amount"
                value={ inputValue }
                onChange={ (event) => setInputValue(event.target.value) }
                className="max-w-[10rem] border-2 border-primary-100 bg-white text-primary-800 rounded px-5 py-3 text-base focus:border-accent-300 focus:[outline:none!important] transition-all ease-in-out duration-200"
                placeholder="AUD"
              />
            </label>
            <button type="submit"
                    className="w-fit rounded-[5000px] px-5 py-3 bg-gradient-to-r from-accent-400 to-accent-800 hover:bg-gradient-to-l hover:from-accent-300 hover:to-accent-600 active:bg-gradient-to-b active:from-accent-200 active:to-accent-400 transition-all">
              <span className="text-white font-bold">PREVIEW</span>
            </button>
          </section>
          <small className="text-primary-200 text-xs">Preview the impact of a monthly subscription.</small>
        </form>
      </section>
    </section>
  );
};

export default PublicCoralArtistTableOverlayed;