import React from 'react';
import aboutHeroImage from '../../assets/images/coral-wide-1.png';
import blueWaveImage from '../../assets/images/blue-wave.png';
import darkBlueWaveImage from '../../assets/images/dark-blue-wave.png';
import FAQItem from '../../components/FAQItem/faqItem';
import { faqData } from './faqForArtists';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';

const ForArtists: React.FC = () => {
    return (
        <div className="fade-in-top">
            <div className="max-w-screen-xl mx-auto">
                {/* Title text "Our dream" */}
                <div className="p-16 bg-white w-auto md:w-2/3 text-left">
                    <h1 className="font-nunito text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-coral-black font-extrabold">
                        Make music and live comfortably.
                    </h1>
                    <p className="text-sm sm:text-base md:text-lg text-custom-gray mt-8">
                    Welcome to wearecoral. We&apos;ve built a platform that lets communities cooperate at scale to encourage and support you to make more music, and live a comfortable life doing so.
                    </p>
                </div>
            </div>
            
            {/* Hero image section */}
            <div className="2xl:hidden"> {/* Hide on 2xl screens and above */}
                <img src={aboutHeroImage} alt="About Hero" className="aspect-content aspect-center w-full h-auto object-cover" />
            </div>

            <div className="2xl:h-[500px] relative"> {/* Show on 2xl screens and above */}
                <img src={aboutHeroImage} alt="About Hero" className="absolute inset-0 w-full h-full object-cover"/>
            </div>

            {/* How section */}
            <div className="max-w-screen-xl mx-auto flex">
                <div className="p-16">
                    <div className="w-full md:w-2/3">
                        <h2 className="text-sm md:text-base font-extrabold">
                            <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                HOW
                            </div>
                        </h2>
                        <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
                            Make music.
                        </h2>
                        <p className="text-sm sm:text-base md:text-lg text-coral-black mt-6">
                            Distinct from other creator platforms, wearecoral is crafted to let members support multiple artists simultaneously.<br /><br />
                            Although the wearecoral community would love to hear from you, but there&apos;s no requirement for you to engage in content creation or take specific actions.<br /><br />
                            wearecoral embodies the belief that music enriches culture collectively rather than serving individual interests alone. It champions the idea that artists should focus primarily on their passion: creating music.
                        </p>
                        <ol className="space-y-4 relative">
                            {/* Vertical line */}
                            <div className="absolute left-2 top-1 bottom-0 w-1 bg-gradient-to-b from-coral-pink to-transparent"></div>

                            {/* Each li represents a step with a dot and text */}
                            <li className="flex items-start">
                                {/* Dot */}
                                <div className="bg-coral-pink rounded-full w-5 h-5 mr-4 flex justify-center items-center flex-shrink-0">
                                <div className="rounded-full w-3 h-3"></div>
                                </div>
                                {/* Text */}
                                <span>Register for wearecoral.</span>
                            </li>

                            {/* Repeat for other steps */}
                            <li className="flex items-start">
                                <div className="bg-coral-pink rounded-full w-5  h-5 mr-4 flex justify-center items-center flex-shrink-0 mt-1">
                                <div className="rounded-full w-3 h-3"></div>
                                </div>
                                <span>Provide bank account details.</span>
                            </li>

                            <li className="flex items-start">
                                <div className="bg-coral-pink rounded-full w-5 h-5 mr-4 flex justify-center items-center flex-shrink-0 mt-1">
                                <div className="rounded-full w-3 h-3"></div>
                                </div>
                                <span>Craft a curated coral with other artists from your community and share it with your audience.</span>
                            </li>

                            <li className="flex items-start">
                                <div className="bg-coral-pink rounded-full w-5 h-5 mr-4 flex justify-center items-center flex-shrink-0 mt-1">
                                <div className="rounded-full w-3 h-3"></div>
                                </div>
                                <span>At your choice, collaborate with your community by sharing gratitude, updates, and attending events. Create your own coral and continue to shape the musical landscape.</span>
                            </li>
                        </ol>





                    </div>
                </div>
            </div>
            <div className="relative w-full h-full">
                <img src={blueWaveImage} alt="Blue Wave" className="w-full h-full object-cover" />
                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-deep-blue to-transparent"></div>
            </div>

            {/* FAQ section */}
            <div className="bg-coral-deep-blue text-white ">
                <div className="max-w-screen-xl mx-auto p-16">
                    <div className="container mx-auto">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            {/* Left column for the title and description */}
                            <div>
                                <h2 className="text-m md:text-l font-extrabold">
                                    <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                        SUPPORT
                                    </div>
                                </h2>
                                <h3 className="font-nunito text-2xl md:text-3xl font-extrabold mb-4">
                                    FAQs
                                </h3>
                                <p className="text-base sm:text-lg md:text-xl mb-4">
                                    Everything you need to know about Coral and how it operates.
                                </p>
                            </div>
                            
                            {/* Right column for the FAQ questions and answers */}
                            <div>
                                {faqData.map((faq, index) => (
                                    <FAQItem key={index} question={faq.question} answer={faq.answer} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative w-full h-full">
                    <img src={darkBlueWaveImage} alt="Dark Blue Wave" className="w-full h-full object-cover" />
                    <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-dark-blue to-transparent"></div>
                </div>                
            </div>

            {/* Vision section */}
            <div className="bg-coral-dark-blue text-white p-16">
                <div className="max-w-screen-xl mx-auto flex flex-col items-center">
                    <div className="w-full mb-4">
                        <h2 className="font-nunito text-2xl md:text-3xl font-extrabold pb-6 text-center">
                            A vibrant, healthy, and diverse ecosystem of music, artists and communities.
                        </h2>
                    </div>
                    <JoinCoralButton />
                </div>
            </div>

        </div>




    );
};

export default ForArtists;