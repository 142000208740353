// src/components/ImpactCard.tsx

import React from 'react';

export interface ImpactData {
  artistName: string;
  totalAmount: number;
  currentMonthAmount: number;
  priorMonthAmount: number;
  currentMonthCoralCount: number;
  priorMonthCoralCount: number;
}

interface ImpactCardProps {
  impact: ImpactData;
  id?: string;
}

const ImpactCard: React.FC<ImpactCardProps> = ({ id, impact }) => {
  return (
    <div id={ id }
      className="w-full max-w-sm shadow-lg hover:shadow-xl p-6 rounded-lg cursor-pointer bg-white border-4 border-dotted border-white hover:translate-y-[-4px] transition-all duration-200 ease"
    >
      <div className="flex justify-center items-center mb-4 gap-4">
        <div className="font-nunito text-base font-bold truncate text-center text-coral-purple"
             style={ { maxWidth: '70%' } }>
          { impact.artistName }
        </div>
      </div>
      <div className="flex items-baseline justify-center">
        <span className="text-base font-semibold text-gray-800">${ impact.totalAmount.toLocaleString() }</span>
        <span className="text-gray-500 text-sm ml-1 -mt-1">
          from { impact.currentMonthCoralCount.toLocaleString() } corals
        </span>
      </div>
    </div>
  );
};

export default ImpactCard;