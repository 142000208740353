import React from 'react';
import aboutHeroImage from '../../assets/images/coral-wide-1.png';
import lightBlueWaveImage from '../../assets/images/light-blue-wave.png';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';

const Terms: React.FC = () => {
    return (
        <div className="fade-in-top">
            <div className="max-w-screen-xl mx-auto">
                {/* Page title */}
                <div className="p-16 bg-white w-auto md:w-2/3 text-left">
                    <h1 className="font-nunito text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-coral-black font-extrabold">
                        The Fine Print.
                    </h1>
                </div>
            </div>
            
            {/* Hero image section */}
            <div className="2xl:hidden">
                <img src={aboutHeroImage} alt="Terms Hero" className="aspect-content aspect-center w-full h-auto object-cover" />
            </div>

            <div className="2xl:h-[500px] relative">
                <img src={aboutHeroImage} alt="Terms Hero" className="absolute inset-0 w-full h-full object-cover"/>
            </div>

            <div className="p-16">
                <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row">
                    {/* Left column for the header */}
                    <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                        <h2 className="text-sm md:text-base font-extrabold">
                            <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange uppercase">
                                Commitments
                            </div>
                        </h2>
                        <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
                            How We&apos;ll Work Together
                        </h2>
                    </div>
                    
                    {/* Right column for plain English summary */}
                    <div className="md:w-1/2">
                        <p className="text-xs sm:text-sm md:text-base text-coral-black">
                            At wearecoral, our mission is to support a thriving music ecosystem. We provide a platform that makes it easy for you to select and voluntarily contribute to the artists you love. In return for our service, we charge a small platform fee. By using wearecoral, you acknowledge that your creative support is voluntary and given to artists without any obligation or expectation of receiving anything in return.
                        </p>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black pt-4">
                            As an artist, by agreeing to receive contributions through wearecoral, you acknowledge that these contributions are intended to support your ongoing creative work and are not royalties or payments for specific goods or services.
                        </p>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black pt-4">
                            We are committed to being intentional, transparent, and fair in our operations. We believe in fostering a collaborative community and actively seeking feedback to continuously improve our platform. Our goal is to create a dynamic, evolving system that responds to the needs of the music community.
                        </p>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black pt-4">
                            By working together, we can build a strong support system for music that makes a lasting impact. We value open dialogue and encourage everyone to participate in shaping the future of wearecoral. Thank you for being a part of this journey with us.
                        </p>
                    </div>
                </div>
            </div>

            {/* Terms and Conditions section */}
            <div className="p-16">
                <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row">
                    {/* Left column for the header */}
                    <div className="md:w-1/2 mb-6 md:mb-0 md:pr-8">
                        <h2 className="text-sm md:text-base font-extrabold">
                            <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                TERMS & CONDITIONS
                            </div>
                        </h2>
                        <h2 className="font-nunito text-2xl md:text-3xl text-coral-black font-extrabold">
                            The Legalese
                        </h2>
                    </div>
                    
    {/* Right column for Terms & Conditions */}
    <div className="md:w-1/2">
    <p className="text-xs sm:text-sm md:text-base text-coral-black">
        <b>Effective Date: 13th March 2024</b>
      </p>

      <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
        1. Introduction
      </h3>
      <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4 mb-4">
        Welcome to wearecoral, a platform owned and operated by wearecoral Pty Ltd (&quot;wearecoral,&quot; &quot;we,&quot; &quot;us,&quot; or &quot;our&quot;), which may also be known as &quot;Coral.&quot; wearecoral&apos;s mission is to foster a vibrant, thriving music ecosystem.
      </p>

      <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
        These Terms of Use (&quot;Terms&quot;) govern your access to and use of the wearecoral website and services (collectively, the &quot;Platform&quot;) that enables users to connect and cooperate to ensure music thrives.
      </p>

      <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
        By accessing or using the Platform, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Platform.
      </p>

      <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6 uppercase">
        Members
      </h3>

      <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
        2. Eligibility
      </h3>
      <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
        You must be at least 16 years old to create an account and use the Platform. If you are under 18, you must have the permission of a parent or legal guardian to use the Platform. By using the Platform, you represent and warrant that you meet these eligibility requirements.
      </p>

      <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
        3. User Accounts
      </h3>
      <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4 mb-4">
        3.1 Registration: To access certain features of the Platform, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as needed.
      </p>
      <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
        3.2 Responsibility: You are responsible for maintaining the confidentiality of your account and password, and you agree to accept responsibility for all activities that occur under your account.
      </p>

      <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            4. Membership
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            4.1 Corals: The Platform allows you to create a &quot;coral,&quot; which is a selection of music artists you support, via a monthly contribution.
                        </p>                     
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            4.2 Payment Processing: wearecoral uses third-party payment processors to handle payments. By making a payment, you agree to the terms and privacy policy of the applicable payment processor. The contributions will be held and distributed monthly.
                        </p>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            4.3 Active Memberships: A user has an active membership with full access to the platform when the user has a coral with an active monthly contribution.
                        </p>   
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            4.4 Unclaimed Contributions: If an artist is not registered within 6-9 months, the money will be refunded as credit to reduce your next contribution or redistributed to other artists you support if you have left the service.
                        </p>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            4.5 Nature of Payments: Payments made on wearecoral support the maintenance and development of the wearecoral platform, and ongoing creative work of artists. These contributions are made in exchange for access to the platform&apos;s tools and services that facilitate community building and collaboration between communities and artists. While artists are not required to provide specific goods or services in return for individual contributions, they are encouraged to engage with their supporters and share updates about their creative process.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                            5. Intellectual Property  
                        </h3>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            5.1 User Content: Any original content users upload to the Platform remains theirs. By uploading content, users grant wearecoral a license to use it not only as necessary to operate and improve the Platform, but also for marketing and promotional activities. This license does not apply to any public domain data used in the content.
                        </p>
                        <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                            5.2 Third-Party Metadata: The Platform may use metadata from Spotify and other music data services. wearecoral does not claim any ownership rights in this metadata, and your use of the Platform is subject to the terms and conditions of these third-party services. You agree not to modify, create derivative works, decompile, reverse-engineer, disassemble, or otherwise reduce the Spotify Platform, Spotify Service, and Spotify Content to source code or other human-perceivable form, to the full extent allowed by law.
                        </p>

                        <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                                6. Prohibited Activities
                            </h3>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
                                You agree not to use the Platform for any unlawful or prohibited purpose, including but not limited to fraud, misrepresentation, abusive or harassing behavior, copyright infringement, or any activity that may interfere with the proper functioning of the Platform.
                            </p>

                            <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6 uppercase">
                                Artists
                            </h3>

                            <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                                7. Artist Payments  
                            </h3>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4 mb-4">
                                7.1 Eligibility: To collect payments from wearecoral, artists must sign up for the service, validate their identity and relationship to the named artist, and provide bank details to receive payment.
                            </p>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
                                7.2 Purpose: Payments to artists are for their ongoing creative endeavors and contribution to the community. These payments are not tied to the delivery of specific goods, and artists have creative freedom to create and share their work, without obligation.
                            </p>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
                                7.3 Not a Right: Eligibility for payments on wearecoral is not a right, and wearecoral reserves the right to change the parameters used to determine eligibility at any time.
                            </p>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
                                7.4 Taxes and Fees: Artists are solely responsible for any taxes or fees associated with payments received through wearecoral.
                            </p>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
                                7.5 No Employment or Contractor Relationship: Artists are not employees or contractors of wearecoral, and wearecoral is not responsible for any agreements between artists and their representatives.
                            </p>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
                                7.6 Holding and Return of Contributions: Funds collected for artists through member contributions will be held securely by wearecoral until the artist has completed the registration process to claim their funds. If an artist has not signed up to collect their funds within 9 months of the contribution being made, the funds will be returned to the member&apos;s wearecoral account as a credit that can be applied to future contributions or distributed to other artists the member supports. 
                            </p>
                            <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6 uppercase">
                                General
                            </h3>

                            <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                                8. wearecoral Mission Alignment
                            </h3>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4 mb-4">
                                8.1 Eligibility Changes: wearecoral&apos;s mission is to foster a vibrant ecosystem of communities and their artists. As such, wearecoral may take actions to ensure that contributions are distributed in a way that aligns with this mission and supports the sustainability and growth of the platform. This may include adjusting the eligibility criteria for artists to receive contributions or implementing policies to promote a diverse and thriving music community on the platform.
                            </p>

                            <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                                9. Platform Fees
                            </h3>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4 mb-4">
                                9.1 Fees: wearecoral generates revenue by providing tools and services that facilitate community building and collaboration between members and artists. To support the ongoing maintenance, development, and operation of the platform, wearecoral applies a 10% platform fee to contributions made by members. This fee is calculated after the deduction of any payment processing fees and is used to cover the costs associated with providing and improving the platform&apos;s services.
                            </p>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
                                9.2 Payment Process: Payments to artists will be made monthly, subject to any minimum payout thresholds. wearecoral reserves the right to withhold payments if there are suspected violations of the Terms of Use.
                            </p>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
                                9.3 Refunds and Chargebacks: wearecoral is not responsible for any refunds or chargebacks initiated by users. In the event of a chargeback, the corresponding amount may be deducted from the artist&apos;s future payments.
                            </p>

                            <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                                10. Termination  
                            </h3>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4 mb-4">
                                10.1 Termination by wearecoral: wearecoral may terminate a user&apos;s account for violation of these Terms, fraudulent activity, or any other reason at its sole discretion. If an artist account is terminated, pending contributions will be credited back to users or redistributed to other artists supported by the user.
                            </p>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
                                10.2 Termination by User: Users may terminate their accounts at any time. If a user account is terminated, pending contributions will be distributed to the applicable artists. Any outstanding payments will be processed in accordance with the normal payment schedule.
                            </p>

                            <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                                11. Disclaimers and Limitations of Liability
                            </h3>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4 mb-4">
                                11.1 Disclaimers: The Platform is provided &quot;as is&quot; and without warranties of any kind, express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement. wearecoral makes no warranties or representations on behalf of Spotify and expressly disclaims all implied warranties with respect to the Spotify Platform, Spotify Service, and Spotify Content, including the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
                            </p>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
                                11.2 Limitations of Liability: To the fullest extent permitted by law, wearecoral shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or relating to your use of the Platform. wearecoral is responsible for its products and disclaims any liability on the part of third parties, including Spotify. [Note: This clause should be carefully reviewed by counsel]
                            </p>

                            <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                                12. Privacy Policy
                            </h3>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                                Please refer to our <a href="/privacy" className="underline text-coral-black">Privacy Policy</a> for information on how we collect, use, and protect your personal data. By using the Platform, you consent to the collection and use of your data as described in the Privacy Policy. Spotify is a third-party beneficiary of wearecoral&apos;s Privacy Policy and is entitled to directly enforce it.
                            </p>

                            <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                                13. Dispute Resolution  
                            </h3>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4 mb-4">
                                Any dispute arising out of or in connection with these Terms shall be resolved through arbitration in accordance with the rules of the Australian Disputes Centre. Notwithstanding the foregoing, either party may bring a claim in small claims court if the claim qualifies. Any legal action or proceeding must be brought within one year after the cause of action accrues.
                            </p>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mb-4">
                                wearecoral encourages users to engage in dialogue to resolve any disputes before resorting to formal arbitration or legal action. We are committed to working with our community to find mutually beneficial solutions whenever possible.
                            </p>

                            <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                                14. Governing Law
                            </h3>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                                These Terms shall be governed by and construed in accordance with the laws of New South Wales, Australia.
                            </p>

                            <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                                15. Severability and Waiver
                            </h3>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                                If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions shall remain in full force and effect. wearecoral&apos;s failure to enforce any right or provision of these Terms shall not be deemed a waiver of such right or provision.
                            </p>

                            <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                                16. Modification of Terms
                            </h3>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black mt-4">
                                We reserve the right to modify these Terms at any time. We will notify you of any changes by posting the revised Terms on the Platform, updating the &quot;Effective Date&quot; at the top of this document, and sending an email to the address associated with your account. Your continued use of the Platform after the posting of the revised Terms constitutes your acceptance of the changes.<br /><br />
                                wearecoral encourages users to provide feedback on changes and engage in dialogue about the platform&apos;s evolution. We value the input of our community and strive to make changes that benefit the music ecosystem as a whole.
                            </p>

                            <h3 className="font-nunito text-xl md:text-2xl text-coral-black font-extrabold pt-6">
                                17. Communication
                            </h3>
                            <p className="text-xs sm:text-sm md:text-base text-coral-black pt-6 pb-8">
                            Communication By creating an account on the Platform, you agree to receive transactional emails related to your account and the Platform&apos;s services. You may also opt-in to receive updates about the platform&apos;s development, community discussions, and opportunities to provide feedback. We value open communication with our users and welcome your engagement in shaping the future of wearecoral.
                            </p>
                            
                        </div>
                    </div> 
                </div>        

            {/* Keep the blue wave image */}
            <div className="relative w-full h-full">
                <img src={lightBlueWaveImage} alt="Light Blue Wave" className="w-full h-full object-cover" />
                <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-light-blue to-transparent"></div>
            </div>

            <div className="p-16 pt-0 bg-coral-light-blue">
                <div className="max-w-screen-xl mx-auto flex flex-col items-center">
                        <div className="w-full mb-4">
                            <h2 className="font-nunito text-2xl md:text-3xl font-extrabold pb-6 text-center">
                                A vibrant, healthy, and diverse ecosystem of music, artists and communities.
                            </h2>
                        </div>
                        <JoinCoralButton />
                </div>
            </div>
        </div>
    );
};

export default Terms;
