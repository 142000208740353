// useCuratedCorals.ts
import { useState, useEffect } from 'react';
import { getAllCoralTags, getCoralsForTag, getRecentSharedCorals } from '../api/api';

interface UseCuratedCoralsResult {
  tags: string[];
  corals: { coralName: string; coralGuid: string; artists: string[] }[];
  selectedTags: string[];
  setSelectedTags: React.Dispatch<React.SetStateAction<string[]>>;
  fetchCorals: (tag: string) => Promise<void>;
}

export const useCuratedCorals = (initialState: 'recent' | 'tag' = 'tag'): UseCuratedCoralsResult => {
  const [tags, setTags] = useState<string[]>([]);
  const [corals, setCorals] = useState<{ coralName: string; coralGuid: string; artists: string[] }[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>(['all']);

  useEffect(() => {
    if (initialState === 'recent') {
      getRecentSharedCorals().then(setCorals);
    } else {
      getCoralsForTag('').then(setCorals);
      getAllCoralTags().then(setTags);
    }
  }, [initialState]);

  const fetchCorals = async (tag: string) => {
    const data = await getCoralsForTag(tag);
    setCorals(data);
  };

  return {
    tags,
    corals,
    selectedTags,
    setSelectedTags,
    fetchCorals
  };
};