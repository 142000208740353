import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import TabbedLayout from '../../components/TabbedLayout/TabbedLayout';
import Account from './Tabs/Account';
import Security from './Tabs/Security';
import Billing from './Tabs/Billing';
import Transactions from './Tabs/Transactions';
import Integrations from './Tabs/Integrations';
import { getUserFromAPI, updateUser } from '../../api/api';
import { User } from 'shared/types/platformTypes';
import { getCurrentUser } from '@aws-amplify/auth';

const Settings: React.FC = () => {
    const history = useHistory();
    const [user, setUser] = useState<User | null>(null);
    

    useEffect(() => {
        async function fetchUser() {
            const fetchedUser = await getUserFromAPI((await getCurrentUser()).userId); // Replace 'userId' with the actual user ID
            setUser(fetchedUser);
        }

        fetchUser();
    }, []);

    const handleBack = () => {
        history.push('/dashboard');
    };

    const handleUserChange = (updatedUser: Partial<User>) => {
        setUser(user => user ? { ...user, ...updatedUser } : updatedUser as User);
    };

    const handleSave = async () => {
        if (user) {
            const updatedUser = {
                firstName: user.firstName,
                lastName: user.lastName,
                country: user.country,
            };
            updateUser(updatedUser);
        }
        handleBack();
    }

    // Define breadcrumbs
    const breadcrumbs = [
        <span key="settings">Settings</span>
    ];

    // Define the tabs for the settings page
    const tabs = [
        { 
            name: 'Account', 
            component: Account,
            key: 'account',
            props: { 
                user: user ? {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    country: user.country,
                    email: user.email,
                } : null,
                onUserChange: handleUserChange,
            },
        },
        { 
            name: 'Security', 
            component: Security,
            key: 'security',
            props: {},
        },
        { 
            name: 'Payment', 
            component: Billing,
            key: 'billing',
            props: {},
        },
        {
            name: 'Payment History',
            component: Transactions,
            key: 'transactions',
            props: {},
        },
        {
            name: 'Integrations',
            component: Integrations,
            key: 'integrations',
            props: {},
        }        
    ];

    return (
        <TabbedLayout
            breadcrumbs={breadcrumbs}
            tabs={tabs}
            onSave={() => handleSave()}
            onCancel={handleBack}
        />
    );
};

export default Settings;
