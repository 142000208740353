import React, { useEffect, useCallback } from 'react';
import closeIcon from '../../assets/images/close.png';
import { Link } from 'react-router-dom';
import { exchangeCodeForAccessToken } from '../../api/api';


type SpotifyModalProps = {
    isOpen: boolean;
    onClose: () => void;
    isSpotifyModalClosing: boolean;
    modalState: string;
    isSpotifyLinked: boolean;
    setSpotifyLinkedStatus: (status: boolean) => void; 
};

const SpotifyModal: React.FC<SpotifyModalProps> = ({ isOpen, onClose, isSpotifyLinked, setSpotifyLinkedStatus, isSpotifyModalClosing, modalState }) => {

    const handleSpotifyCallback = useCallback(async () => {
        if (!isOpen || isSpotifyLinked) return;

        const query = new URLSearchParams(window.location.search);
        const code = query.get('code');
        const state = query.get('state');
        const storedState = localStorage.getItem('spotify_auth_state');

        if (state === storedState && code) {
            try {
                await exchangeCodeForAccessToken(code);
                localStorage.removeItem('spotify_auth_state'); // Clear the stored state
                window.history.replaceState({}, document.title, window.location.pathname); // clear spotify parameters
                setSpotifyLinkedStatus(true);
            } catch (error) {
                console.error('Error exchanging Spotify code:', error);
                // Handle the error appropriately
            }
        }
        else {
            console.log('Spotify callback not handled, state and stored state', state, storedState);
        }
    }, [isOpen]);

    useEffect(() => {
        if (modalState !== 'auth-error' && isOpen) {
            handleSpotifyCallback();
        }
    }, [modalState, handleSpotifyCallback, isOpen]);

    let header, message;
    switch (modalState) {
      case 'add-artists':
        header = 'Spotify is authorised';
        message = 'Add Available Connections from Spotify to streamline artist selection.';
        break;
      case 'auth-error':
        header = 'Spotify Authorization Error';
        message = (
          <span>
            There was an error authorizing Spotify.{' '}
            <Link to="/dashboard" onClick={onClose} className="underline">Please try again</Link> or add artists manually or using curated corals.
          </span>
        );
        break;
      default:
        header = 'Spotify Authorization';
        message = 'Spotify has been successfully authorized. You can now sync your Spotify data.';
    }

    return (
        <div className={`fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50 ${!isSpotifyModalClosing ? 'opacity-100' : 'opacity-0 pointer-events-none'} transition-opacity duration-300`}>
            {/* Overlay to close modal on click outside */}
            <div className="absolute inset-0 bg-transparent" onClick={onClose}></div>
            
            <div className="relative bg-white rounded-lg shadow-2xl mx-8 sm:mx-0 w-full sm:max-w-2xl">
                {/* Modal content */}
                <div className="relative p-4 sm:p-8">

                    {/* Close button */}
                    <button
                        onClick={onClose}
                        className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2"
                    >
                        <img src={closeIcon} alt="Close" className="w-12 h-12" />
                    </button>

                    {/* Modal content */}
                    <div className="relative max-w-2xl mx-auto p-8">
                        <h3 className="text-xl font-semibold text-gray-900 mb-6">{header}</h3>
                        <p className="text-sm text-gray-500 mb-6">{message}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default SpotifyModal;