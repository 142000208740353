import React, { useState, useEffect, createContext, ReactNode } from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Sidebar from './components/Sidebar/Sidebar';
import './styles/tailwind.css';
import { useLocation, Redirect } from 'react-router-dom';
import { getCurrentUser, AuthUser } from '@aws-amplify/auth';
import AuthModal from './components/AuthModal/AuthModal';
import Spinner from './components/Spinner/Spinner';
import { getUserFromAPI } from './api/api';
import { User } from 'shared/types/platformTypes';

import '@aws-amplify/ui-react/styles.css';
import './styles/authenticatorStyles.css';

interface AuthContextType {
  showAuth: { show: boolean, signIn: boolean };
  setShowAuth: (showAuth: { show: boolean, signIn: boolean }) => void;
}

export const AuthContext = createContext<AuthContextType>({
  showAuth: { show: false, signIn: false },
  setShowAuth: () => {},
});

interface AppProps {
  children: ReactNode;
}

const App: React.FC<AppProps> = ({ children }) => {
  const location = useLocation();
  const [currentUser, setCurrentUser] = useState<AuthUser | null>(null);
  const [showAuth, setShowAuth] = useState({ show: false, signIn: false });
  const [loading, setLoading] = useState(true);
  const publicRoutes = ['/', '/about', '/contact-us', '/privacy', '/terms', '/for-artists', '/welcome', '/curated-coral', '/sign-out', '/trust', '/principles'];

  const shouldRenderSidebar = currentUser !== null && isSidebarPage(location.pathname);
  const isPublicPage = isPublicRoute(location.pathname);
  const [redirectToSetup, setRedirectToSetup] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setLoading(true);
        const user = await getCurrentUser();
        setCurrentUser(user);
      } catch (error) {
        // Do nothing with the error
        setCurrentUser(null);
        if (!publicRoutes.includes(location.pathname)) {
          // Redirect unauthenticated users not on public routes to home
          // setShowAuth(true); // Trigger showing the authenticator
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [location.pathname]);

  useEffect(() => {
    async function fetchUserSettings() {
      if (currentUser && location.pathname !== '/setup') {
        try {
          const fetchedUserProfile = await getUserFromAPI(currentUser.userId) as User;
          if (!fetchedUserProfile.settings?.profileSetupComplete) {
            setRedirectToSetup(true);
          }
        } catch (error) {
          console.error('Could not fetch user settings', error);
        }
      }
    }

    fetchUserSettings();
  }, [currentUser, location]);

  function isPublicRoute(route: string): boolean {
    const exactPublicRoutes = ['/', '/about', '/contact-us', '/privacy', '/terms', '/for-artists', '/welcome', '/sign-out', '/trust', '/principles'];
    if (exactPublicRoutes.includes(route)) {
      return true;
    }
    if (route.startsWith('/c/')) {
      return true;
    }
    return false;
  }


  function isSidebarPage(page: string): boolean {
    const pagesWithSidebar = ['/dashboard', '/coral', '/settings', '/impact'];
    return pagesWithSidebar.some(p => page.startsWith(p));
  }

  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100dvh' }}>
        <Spinner />
      </div>
    );
  }

  function handleColorScheme(): string {
    if (location.pathname === '/dashboard') return 'bg-coral-blue';
    if (location.pathname.slice(0, 3) === '/c/') {
      return 'bg-gradient-to-b from-primary-400 to-primary-800 text-white';
    }
    return '';
  }

  function handleHeaderColor(): string {
    if (location.pathname.slice(0, 3) === '/c/') return 'bg-transparent text-white';
    return 'bg-white';
  }

  function handleNavigationTextColor(): string {
    if (location.pathname.slice(0, 3) === '/c/') return 'text-gray-300 hover:text-white transition duration-200 text-xs md:text-sm lg:text-base';
    return 'text-gray-600 hover:text-gray-900 transition duration-200 text-xs md:text-sm lg:text-base'
  }

  console.log(`PATH: ${location.pathname}`);

  return (
    <AuthContext.Provider value={{ showAuth, setShowAuth }}>
      {showAuth.show && !currentUser && (
        <AuthModal setCurrentUser={setCurrentUser} setShowAuth={setShowAuth} signIn={showAuth.signIn} />
      )}

      <div className={`min-h-screen ${handleColorScheme()}`}>
        <div className={`flex flex-col ${isPublicPage ? '' : 'h-safe'}`}>
          {isPublicPage && location.pathname !== '/welcome' && <Header className={handleHeaderColor()} navigationTextHandler={ handleNavigationTextColor() } />}
          <div className="flex-grow flex flex-col sm:flex-row overflow-hidden">
            {shouldRenderSidebar && <Sidebar className="w-full sm:w-20 p-4" />}
            <div className="flex-grow overflow-auto">
              {children}
            </div>
          </div>
          {isPublicPage && location.pathname !== '/welcome' && <Footer />}
        </div>
      </div>
      {redirectToSetup && <Redirect to="/setup" />}
      {!isPublicRoute(location.pathname) && !currentUser && <Redirect to="/" />}
      {currentUser && location.pathname === '/' && <Redirect to="/dashboard" />}
    </AuthContext.Provider>
  );
};

export default App;
