import { useState, useEffect } from 'react';
import { getCoralFromAPI, getCuratedCoralFromAPI, createCoralFromAPI, getArtistDetails, saveCoralToAPI, simulateCoral, simulateCoralWithoutAuth, SimulateCoralResponse, returnFavouriteArtists, updateCoralSharingSettings, updateCoralCuratedSettings } from '../api/api'; //, updateCoralShare, updateCoralFindable } from '../api/api';
import useCoralEditorArtistManagement from './useCoralEditorArtistManagement';
import { ClientCoral, CoralType } from 'shared/types/platformTypes';

const useCoralEditor = (initialCoralId: string, isShared = false, apiClient = { getCoralFromAPI, getCuratedCoralFromAPI, createCoralFromAPI, getArtistDetails, saveCoralToAPI, simulateCoral, simulateCoralWithoutAuth, returnFavouriteArtists, updateCoralSharingSettings, updateCoralCuratedSettings }) => {
    const [coralData, setCoralData] = useState<ClientCoral | null>(null);
    const [simulationResults, setSimulationResults] = useState<SimulateCoralResponse>({
        transactionFee: 0,
        platformFee: 0,
        artistAllocations: [],
    });
    // New state to store active filters
    const [activeFilters, setActiveFilters] = useState<Array<{ filterType: 'genre' | 'location'; value: string }>>([]);
    // const { addArtistDirectly, removeArtist, removeArtistsBySourceArtistPoolGuid, addArtistFromDynamicArtistPool, retrieveTopArtists, isDynamicArtistPools, uniqueGenres, uniqueLocations, dynamicArtistPools } = useCoralEditorArtistManagement(coralData, activeFilters, setCoralData);
    const safeSetCoralData: (updateFunction: (prevData: ClientCoral) => ClientCoral) => void = updateFunction => {
        setCoralData(prevData => {
            if (prevData === null) {
                // Handle the null case
                return null;
            }

            // Otherwise, update the ClientCoral object
            return updateFunction(prevData);
        });
    };

    const { addArtistDirectly, removeArtist, removeArtistsBySourceArtistPoolGuid, addArtistFromDynamicArtistPool, retrieveTopArtists, isDynamicArtistPools, uniqueGenres, uniqueLocations, dynamicArtistPools } = useCoralEditorArtistManagement(coralData, activeFilters, safeSetCoralData);
    // In the useCoralEditor hook
    console.log('useCoralEditor initialCoralId:', initialCoralId);

    // Log updated coral to the console
    useEffect(() => {
        console.log('Updated coral:', coralData);
    }, [coralData]);

    // Fetch initial coral data
  useEffect(() => {
    const fetchCoralData = async () => {
        // In the useEffect hook inside the useCoralEditor hook
        console.log('useEffect initialCoralId:', initialCoralId);
        let data: ClientCoral;
        if (initialCoralId !== '') {
            data = isShared ? await apiClient.getCuratedCoralFromAPI(initialCoralId) as ClientCoral : await apiClient.getCoralFromAPI(initialCoralId) as ClientCoral;
        } else {
            data = await apiClient.createCoralFromAPI(CoralType.USER) as ClientCoral;
        }
        setCoralData(data);
    };

    fetchCoralData();
  }, [initialCoralId]);


    
    // Effect to update activeFilters when coralData changes
    useEffect(() => {
        // Function to extract active filters from coralData
        const extractActiveFilters = (coral: ClientCoral | null): Array<{ filterType: 'genre' | 'location'; value: string }> => {
            const filters: Array<{ filterType: 'genre' | 'location'; value: string }> = [];
            coral?.coralRuleSet.layers.forEach(layer => {
                if (layer.layerType === 'userFiltering' && layer.filters) {
                    layer.filters.forEach(filter => {
                        Object.entries(filter.parameters).forEach(([key, value]) => {
                            if (key === 'genre' || key === 'location') {
                                filters.push({ filterType: key, value });
                            }
                        });
                    });
                }
            });
            return filters;
        };

        // Set active filters based on coralData
        if (coralData) {
            setActiveFilters(extractActiveFilters(coralData));
        }
    }, [coralData]);

    const updateFilter = (filterType: 'genre' | 'location', value: string, action: 'add' | 'remove') => {
        if (coralData) {
            // Find the userFiltering layer
            const userFilteringLayer = coralData.coralRuleSet.layers.find(layer => layer.layerType === 'userFiltering');
    
            if (userFilteringLayer && userFilteringLayer.filters) {
                // If action is 'add', add the filter
                if (action === 'add') {
                    userFilteringLayer.filters.push({ filterType, parameters: { [filterType]: value } });
                } 
                // If action is 'remove', remove the filter
                else if (action === 'remove') {
                    userFilteringLayer.filters = userFilteringLayer.filters.filter(filter => filter.parameters[filterType] !== value);
                }
    
                // Update the coralData state
                setCoralData({
                    ...coralData,
                    coralRuleSet: {
                        ...coralData.coralRuleSet,
                        layers: coralData.coralRuleSet.layers.map(layer => 
                            layer.layerType === 'userFiltering' ? userFilteringLayer : layer
                        ),
                    },
                });
            }
        }
    };    

  const updatePledgeAmount = (newPledgeAmount: number) => {
    if (coralData) {
      setCoralData({
        ...coralData,
        pledgeAmount: newPledgeAmount,
      });
    }
  };

const updateCoralName = (newCoralName: string) => {
  if (coralData) {
    setCoralData({
      ...coralData,
      coralName: newCoralName,
    });
  }
};  

const addPinnedArtist = (artistId: string) => {
    if (coralData) {
        setCoralData({
            ...coralData,
            pinnedArtists: [...coralData.pinnedArtists, artistId],
        });
    }
};

const removePinnedArtist = (artistId: string) => {
    if (coralData) {
        setCoralData({
            ...coralData,
            pinnedArtists: coralData.pinnedArtists.filter(id => id !== artistId),
        });
    }
};

const toggleShare = async () => {
    if (coralData) {
        const newVisibilityStatus = !coralData.coralShared;
        try {
            const response = await apiClient.updateCoralSharingSettings(coralData.guid, newVisibilityStatus);
            console.log(response);
            setCoralData({ ...coralData, sharedCoralGuid: response.sharedCoralGuid, coralShared: response.isShared, uniqueUrl: response.url});
        } catch (error) {
            console.error('Failed to update coral visibility:', error);
        }
    }
};

const toggleCurated = async () => {
    if (coralData) {
        const newCuratedStatus = !coralData.coralCurated;
        try {
            const response = await apiClient.updateCoralCuratedSettings(coralData.guid, newCuratedStatus);
            setCoralData({ ...coralData, coralCurated: response.isCurated });
        } catch (error) {
            console.error('Failed to update coral curated status:', error);
        }
    }
};

const toggleFindable = async () => {
    console.log("toggleFindable method called");
    // if (coralData) {
    //   const newFindableStatus = !coralData.findable;
    //   await apiClient.updateCoralFindable(coralData.guid, newFindableStatus);
    //   setCoralData({ ...coralData, findable: newFindableStatus });
    // }
};

// Function to save the coral
const saveCoral = async () => {
    let guid = null;
    if (coralData) {
        const response = await apiClient.saveCoralToAPI(coralData);
        guid = response.guid;
        // Update the coralData state with the new guid
        setCoralData({ ...coralData, guid: guid });
    }
    return guid;
};

// Function to simulate the coral
const simulateCoral = async () => {
    if (coralData) {
        let response;
        if (isShared) {
            response = await apiClient.simulateCoralWithoutAuth(coralData)
        } else {
            response = await apiClient.simulateCoral(coralData);
        }
        setSimulationResults(response);
    }
};   

//   const coralDataRef = useRef(coralData);
//   coralDataRef.current = coralData;

  return { coralData, addArtistDirectly, removeArtist, removeArtistsBySourceArtistPoolGuid, 
    addArtistFromDynamicArtistPool, addPinnedArtist, removePinnedArtist, retrieveTopArtists, 
    isDynamicArtistPools, uniqueGenres, uniqueLocations, dynamicArtistPools, activeFilters, 
    updatePledgeAmount, updateCoralName, saveCoral, simulateCoral, simulationResults, updateFilter, toggleShare, 
    toggleCurated, toggleFindable };
};

export default useCoralEditor;

