// src/pages/OurImpact/OurImpact.tsx

import React from 'react';
import { useOurImpact } from '../../hooks/useOurImpact';
import Spinner from '../../components/Spinner/Spinner';
import dashboardEmptyReef from '../../assets/images/dashboard/dashboard-empty-reef.png';
import ImpactCard from '../../components/ImpactCard/ImpactCard';
import dashboardPopulatedReef from '../../assets/images/dashboard/dashboard-populated-coral-reef.png';

const OurImpact: React.FC = () => {

    const { impactData, isLoading } = useOurImpact();

    // Determine if there is any impact data
    const hasImpactData = impactData.length > 0;
    const impactBackgroundImage = hasImpactData ? dashboardPopulatedReef : dashboardEmptyReef;

    if (isLoading) {
        return (
            <div className="relative h-full bg-white">
                <div 
                    className="absolute inset-0 bg-no-repeat bg-contain bg-bottom flex items-center justify-center"
                >
                    <Spinner />
                </div>
            </div>
        );
    }

    return (
        <div className="relative flex flex-col h-full bg-white">
            {/* Conditional background image */}
            <div 
                className={`flex-grow flex flex-col bg-no-repeat bg-contain bg-bottom animate-fadeIn`}
                style={{ backgroundImage: `url(${impactBackgroundImage})` }}
            >
                <div className="pt-4 px-10 flex flex-col sm:flex-row justify-between items-center mb-4 sm:mb-8">
                    <h1 className="font-nunito text-lg sm:text-xl md:text-2xl font-extrabold mb-4 md:mb-0">Our Impact</h1>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-10 pt-0">
                    {impactData.length > 0 ? (
                        impactData.map((impact, index) => (
                            <ImpactCard key={index} impact={impact} />
                        ))
                    ) : (
                        <div className="flex items-center w-full h-full">
                            <p className="text-lg text-gray-500">No results have been returned.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
    
};

export default OurImpact;