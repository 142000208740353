import React, { useState, useEffect } from 'react';
import { ClientArtistListItem } from 'shared/types/platformTypes';
import { SimulateCoralResponse } from '../../../api/api';
import MiniCoralSimulationTable from '../../../components/CoralEditor/CoralSimulationTable/MiniCoralSimulationTable';
import CoralButton from '../../../components/CoralButtons/CoralButton';

export interface CoralSettingsProps {
    coralName: string;
    artists: Array<ClientArtistListItem>;
    simulateCoral: () => void; // A function to simulate the coral
    simulationResults: SimulateCoralResponse;
    updatePledgeAmount: (amount: number) => void; // A function to update the pledge amount
    pledgeAmount: number;
    updateCoralName: (coralName: string) => void; // A function to update the coral name
    addPinnedArtist: (artistId: string) => void;
    removePinnedArtist: (artistId: string) => void;
    pinnedArtists: string[];
}

const CoralSettings: React.FC<CoralSettingsProps> = ({
    coralName,
    simulateCoral,
    updatePledgeAmount,
    simulationResults,
    pledgeAmount,
    addPinnedArtist,
    removePinnedArtist,
    pinnedArtists,
    updateCoralName,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingName, setIsEditingName] = useState(false);
    const [editNameValue, setEditNameValue] = useState(coralName);
    const [originalPledgeAmount, setOriginalPledgeAmount] = useState(pledgeAmount);

    const handleUpdateCoralName = (newName: string) => {
        updateCoralName(newName);
    };

    // Call simulateCoral when pledgeAmount changes
    useEffect(() => {
        if (pledgeAmount > 0) {
        simulateCoral();
        }
    }, [pledgeAmount]);

    useEffect(() => {
        console.log('simulationResults updated', simulationResults);
    }, [simulationResults]);

    const stopEditingContribution = (revert = true) => {
        setIsEditing(false);
        console.log(originalPledgeAmount, pledgeAmount)
        if (revert) {
            updatePledgeAmount(originalPledgeAmount);
            setEditValue(originalPledgeAmount.toString());
        }
    };

    const [editValue, setEditValue] = useState(pledgeAmount.toString());

    return (
        <div className="px-4 py-4">
            {/* <h2 className="font-nunito text-2xl md:text-3xl font-extrabold mb-2">Coral Settings</h2> */}
            <div className="mb-8">
                <div className="flex justify-between items-center mb-2">
                    <span className="block font-nunito text-lg font-extrabold text-gray-700">Coral Name</span>
                </div>
                {isEditingName ? (
                    <input
                        type="text"
                        value={editNameValue}
                        onChange={(e) => setEditNameValue(e.target.value)}
                        onBlur={() => {
                            handleUpdateCoralName(editNameValue);
                            setIsEditingName(false);
                        }}
                        className="border-b border-dotted border-gray-500 cursor-pointer p-2 text-gray-500 font-medium text-sm sm:text-base outline-none"
                    />
                ) : (
                    <span
                        className="border-b border-dotted border-gray-500 cursor-pointer pt-2 pb-2 text-gray-500 font-medium text-sm sm:text-base"
                        onClick={() => {
                            stopEditingContribution();
                            setIsEditingName(true);
                        }}
                    >
                        {coralName || 'Add a name for your coral'}
                    </span>
                )}
            </div>

            <div className="mb-8">
                <span className="block font-nunito text-lg font-extrabold text-gray-700 mb-2">
                    Monthly Contribution
                </span>
                {isEditing ? (
                    <input
                        type="string"
                        inputMode="numeric"
                        value={editValue}
                        onChange={(e) => {
                            setEditValue(e.target.value)
                        }}
                        className="border-b border-dotted border-gray-500 cursor-pointer p-2 text-gray-500 font-medium text-sm sm:text-base outline-none mr-8"
                    />
                ) : (
                    <span
                        className="border-b border-dotted border-gray-500 cursor-pointer pt-2 pb-2 text-gray-500 font-medium text-sm sm:text-base"
                        onClick={() => {
                            setOriginalPledgeAmount(Number(pledgeAmount));
                            setIsEditing(true)
                        }}
                    >
                        {`$${pledgeAmount} AUD`}
                    </span>
                )}
                {isEditing && (
                    <>
                        <CoralButton
                            className="font-semibold text-xs sm:text-sm mr-2"
                            onClick={() => {
                                updatePledgeAmount(Number(editValue));
                            }}
                        >
                            Preview
                        </CoralButton>
                        <CoralButton
                            className="font-semibold text-xs sm:text-sm"
                            onClick={() => {
                                stopEditingContribution(false);
                            }}
                        >
                            Apply
                        </CoralButton>
                    </>
                )}

                {isEditing && (
                    <MiniCoralSimulationTable
                        artistAllocations={simulationResults.artistAllocations}
                        addPinnedArtist={addPinnedArtist}
                        removePinnedArtist={removePinnedArtist}
                        pinnedArtists={pinnedArtists}
                        simulateCoral={simulateCoral}
                    />
                )}
            </div>
        </div>
    );
};

export default CoralSettings;
