// src/components/SectionWithSwiper/SectionWithSwiper.tsx

import React, { useRef, useState } from 'react';
import { Swiper } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../styles/swiper.css';
import { Swiper as SwiperType } from 'swiper/types';
import { BiLeftArrow, BiRightArrow, BiSolidLeftArrow, BiSolidRightArrow } from 'react-icons/bi';

const PaginatorButton: React.FC<{
  initialIcon: React.ReactNode;
  hoverIcon: React.ReactNode;
  onClick?: () => void;
}> = ({ initialIcon, hoverIcon, onClick }) => {
  const [arrowIcon, setArrowIcon] = useState<React.ReactNode>(initialIcon);

  return (
    <button
      className="p-1 hover:bg-primary-200 active:bg-primary-100 transition-all duration-200 ease-in-out rounded-sm"
      onClick={onClick ? () => onClick() : () => {}}
      onMouseOver={() => setArrowIcon(hoverIcon)}
      onMouseOut={() => setArrowIcon(initialIcon)}
    >
      {arrowIcon}
    </button>
  );
};

interface DashboardCarouselProps {
  title: string;
  children: React.ReactNode;
}

const DashboardCarousel: React.FC<DashboardCarouselProps> = ({ title, children }) => {
  const swiperRef = useRef<SwiperType | null>(null);

  return (
    <section className="w-full max-w-7xl bg-transparent-pale-aqua p-10 pb-0 rounded-xl border-[3px] border-solid border-transparent-yellow">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg lg:text-xl font-black font-nunito">{title}</h2>
        <div className="flex gap-2">
          <PaginatorButton
            initialIcon={<BiLeftArrow />}
            hoverIcon={<BiSolidLeftArrow />}
            onClick={() => swiperRef.current?.slidePrev()}
          />
          <PaginatorButton
            initialIcon={<BiRightArrow />}
            hoverIcon={<BiSolidRightArrow />}
            onClick={() => swiperRef.current?.slideNext()}
          />
        </div>
      </div>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={24}
        slidesPerView={1}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        navigation={true}
        pagination={{ clickable: true }}
        onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
        onSlideChange={() => console.log('slide change')}
        style={{ paddingBottom: '48px' }}
      >
        {children}
      </Swiper>
    </section>
  );
};

export default DashboardCarousel;