// src/pages/Dashboard/Dashboard.tsx

import React, { useState } from 'react';
import { useDashboard } from '../../hooks/useDashboard';
import CoralCard from '../../components/CoralCard/CoralCard';
import dashboardEmptyReef from '../../assets/images/coral-reef--empty.svg';
import dashboardPopulatedReef from '../../assets/images/coral-reef.svg';
import CreateCoralModal from '../../components/CreateCoralFlow/CreateCoralModal/CreateCoralModal';
import SecondaryCoralButton from '../../components/CoralButtons/SecondaryCoralButton';
import CoralButton from '../../components/CoralButtons/CoralButton';
import Spinner from '../../components/Spinner/Spinner';
import CoralCardWithThumbnail from '../../components/CoralCard/CoralCardWithThumbnail';
import ImpactCard from '../../components/ImpactCard/ImpactCard';
import { SwiperSlide } from 'swiper/react';
import DashboardCarousel from '../../components/DashboardCarousel/DashboardCarousel';


const Dashboard: React.FC = () => {

  const { activeCorals, isLoading, firstName, impactData, curatedCorals, connectedArtists } = useDashboard();
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!isModalOpen);

  // Determine if there are any active corals
  const hasCorals = activeCorals.length > 0;
  const hasConnectedArtists = connectedArtists.length > 0;
  const dashboardBackgroundImage = hasCorals || hasConnectedArtists ? dashboardPopulatedReef : dashboardEmptyReef;

  if (isLoading) {
    return (
      <div className="relative h-full bg-white">
        <div
          className="absolute inset-0 bg-no-repeat bg-contain bg-bottom flex items-center justify-center"
        >
          <Spinner/>
        </div>
      </div>
    );
  }

  return (
    <div className="relative flex flex-col bg-white">
            {/* Conditional background image */}
            <div 
                className={`flex-grow flex flex-col  bg-no-repeat bg-contain bg-bottom animate-fadeIn`}
                style={{ backgroundImage: `url(${dashboardBackgroundImage})` }}
            >
        <div className="overflow-y-auto">
          <section className="w-[calc(100%-50px)] max-w-5xl mx-auto">
            { hasCorals || hasConnectedArtists ? (
              <>
                <div className="pt-4 px-10 flex flex-col sm:flex-row justify-between items-center mb-4 sm:mb-8 ">
                  <h1
                    className="font-nunito text-lg sm:text-xl md:text-2xl font-extrabold mb-4 md:mb-0">Hi, { firstName } 👋</h1>
                  <SecondaryCoralButton
                    onClick={ toggleModal } // This will open the modal
                  >
                    + Create New Coral
                  </SecondaryCoralButton>
                </div>

                {hasConnectedArtists && (
                  <>
                    <h2 className="font-nunito text-base sm:text-lg md:text-xl font-extrabold mb-4 px-10">
                      Your Connected Artists
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-10 pt-0">
                      {connectedArtists.map((artist, index) => (
                        <ImpactCard key={index} id={`impact-${index}`} impact={artist} />
                      ))}
                    </div>
                  </>
                )}

                {hasCorals && (
                  <>
                    <h2 className="font-nunito text-base sm:text-lg md:text-xl font-extrabold mb-4 px-10">
                      Your Corals
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-10 pt-0">
                      {activeCorals.map((coral, index) => (
                        <CoralCard key={index} coral={coral} />
                      ))}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <div className="my-20 flex flex-col items-center justify-center pt-16">
                  <h1
                    className="font-nunito text-lg sm:text-xl md:text-2xl font-extrabold mb-4 text-center">Welcome, { firstName } 👋</h1>
                  <p className="font-nunito text-base sm:text-lg md:text-xl lg:text-2xl mb-6 text-center">
                    Let&apos;s
                    make music together.</p>
                  <CoralButton
                    onClick={ toggleModal }
                  >
                    Create a Coral
                  </CoralButton>
                </div>
              </>
            ) }
            <CreateCoralModal isOpen={ isModalOpen } onClose={ toggleModal }/>
            <div className="flex flex-col justify-center items-center gap-10 mb-24 mt-10">
              <p className="font-nunito text-sm sm:text-base md:text-lg lg:text-xl text-gray-500">See what&lsquo;s happening in the ecosystem.</p>
              <DashboardCarousel title="Featured Corals">
                {curatedCorals.slice(0, 4).map((coral, index) => (
                  <SwiperSlide key={index}>
                    <CoralCardWithThumbnail
                      link="#"
                      title={coral.coralName}
                      subtitle={coral.artists.join(', ')}
                      pageTurner={() => {}}
                      guid={coral.coralGuid}
                    />
                  </SwiperSlide>
                ))}
              </DashboardCarousel>
              <DashboardCarousel title="Trending Artists">
                {impactData.map((impact, index) => (
                  <SwiperSlide key={index}>
                    <ImpactCard id={`impact-${index}`} impact={impact} />
                  </SwiperSlide>
                ))}
              </DashboardCarousel>
            </div>
          </section>
        </div>
      </div>
    </div>
  );

};

export default Dashboard;