import React from 'react';
import { useParams } from 'react-router-dom';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import coralReefWithFishies from '../../assets/images/coral-with-fishies.svg';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';
import PublicCoralArtistTable from '../../components/PublicCoralArtistTable/PublicCoralArtistTable';
import useCoralEditor from '../../hooks/useCoralEditor';
import Spinner from '../../components/Spinner/Spinner';

const PublicSharedCoral: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const {
    coralData,
    addPinnedArtist,
    removePinnedArtist,
    updatePledgeAmount,
    simulateCoral,
    simulationResults,
  } = useCoralEditor(id || '', true);

  if (!coralData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <Spinner/>
      </div>
    );
  }

  return (
    <div className="fade-in-top overflow-clip">
<div className="grid place-content-center pt-8 md:pt-16 md:py-16 px-8 sm:px-12 lg:px-16 max-w-screen-xl mx-auto">
  <div className="mb-1">
    <h1 className="mb-2 text-3xl md:text-4xl lg:text-5xl font-black font-nunito text-center">
      <span className="block lg:inline mr-2">Support</span>
      <span className="bg-gradient-to-r from-accent-400 via-accent-200 to-accent-800 block lg:inline text-transparent bg-clip-text mr-2">{ coralData.coralName }</span>
      <span className="block lg:inline">on wearecoral</span>
    </h1>
  </div>
  <div>
    <p className="mb-0.5 text-xl md:text-2xl lg:text-3xl text-white font-nunito font-bold text-center pt-2">
      Join a living ecosystem that multiplies the music and culture you enjoy.
    </p>
    <p className="mb-0.5 text-xl md:text-2xl lg:text-3xl text-white font-nunito font-bold text-center pt-2">
      <span className="bg-gradient-to-r from-[#1B8ECA] via-[#1EE8FF] to-[#1B8ECA] inline-block text-transparent bg-clip-text pr-2"> Support multiple artists with a single monthly contribution.</span>
    </p>
    <p className="mb-0.5 text-xl md:text-2xl lg:text-3xl text-white font-nunito font-bold text-center pt-2">
      Create a &ldquo;coral&rdquo; by choosing the artists you support, and how much you give monthly.
    </p>
  </div>
</div>

      <div
        className="flex flex-col-reverse lg:flex-row px-3 sm:px-12 lg:px-16 max-w-screen-xl mx-auto gap-10 mb-8 items-center">
        <div className="w-full lg:w-1/2">
          <div className='justify-center flex'>
            <PublicCoralArtistTable
              coralName={ coralData ? coralData.coralName : '' }
              coralSimulationResults={ simulationResults.artistAllocations }
              simulateCoral={ simulateCoral }
              updatePledgeAmount={ updatePledgeAmount }
              pledgeAmount={ coralData ? coralData.pledgeAmount : 0 }
              addPinnedArtist={ addPinnedArtist }
              removePinnedArtist={ removePinnedArtist }
              pinnedArtists={ coralData ? coralData.pinnedArtists : [] }
            />
          </div>
          <div className="mt-8 mb-8 lg:hidden">
            <JoinCoralButton isAnimated={ false } className="w-fit mx-auto">
              Join & Customize Your Coral
            </JoinCoralButton>
          </div>
        </div>
        <section className="flex flex-col justify-center items-center gap-10 max-w-2xl w-full lg:w-1/2">
          <div>
            {/* eslint-disable-next-line react/no-unescaped-entities */ }
            <p className="text-[#B0C4DE] text-center font-nunito text-lg md:text-xl hidden lg:block">Discover a unique way to support music through "corals." A coral is a monetary pledge distributed monthly to artists you choose.  You have full control over the funds' distribution to the artists in your coral.</p>
            <p className="text-[#B0C4DE] font-nunito font-bold mt-4 text-center text-lg md:text-xl">
              Explore this shared coral, then sign up to customise and share your coral with others.
            </p>
          </div>
          <JoinCoralButton isAnimated={ false } className="w-fit mx-auto hidden lg:block">
            Join & Customize Your Coral
          </JoinCoralButton>
        </section>
      </div>

      <div className="relative w-full h-fit mt-8">
        <div className="w-full grid place-items-center">
          <div className="max-w-screen-xl mx-auto px-8 sm:px-12 lg:px-16 pb-12 sm:py-16 lg:py-20 mb-32 md:mb-72 lg:mb-96 flex flex-col items-center gap-7 relative z-10">
            <div className="max-w-4xl">
              <h2 className="font-nunito text-3xl md:text-4xl font-extrabold pb-6 text-center">
                A vibrant, healthy, and diverse ecosystem of music, artists and communities
              </h2>
              <p className="font-nunito text-lg md:text-xl text-center text-primary-200 hidden lg:block">
                wearecoral is a platform dedicated to enriching the music ecosystem, focusing on fostering diverse,
                non-mainstream genres. The purpose is to support artists in leading fulfilling lives, dedicated to
                creating music. Music enthusiasts can support their favourite artists and scenes with monthly pledges.
              </p>
            </div>
            <JoinCoralButton size="large" isAnimated={false} className='hidden lg:block' />
          </div>
        </div>
        <img
          src={coralReefWithFishies}
          alt="Fishes"
          className="w-full h-auto absolute bottom-0 left-0 right-0 z-0 opacity-75"
          aria-hidden
        />
      </div>
    </div>
  );
};

export default PublicSharedCoral;