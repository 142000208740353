/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SelectArtist from '../../components/CreateCoralFlow/SelectArtists/SelectArtists';
import ConfigureConnections from '../../components/CreateCoralFlow/ConfigureConnections/ConfigureConnections';
import PreviewImpact from '../../components/CreateCoralFlow/PreviewImpact/PreviewImpact';
import NavigationTrail from '../../components/NavigationTrail/NavigationTrail';
import useCoralEditor from '../../hooks/useCoralEditor';
import closeButton from '../../assets/images/close-light.png';
import spotifyIcon from '../../assets/images/spotify-icon-small.png';
import SecondaryCoralButton from '../../components/CoralButtons/SecondaryCoralButton'; 
import CoralButton from '../../components/CoralButtons/CoralButton'; 
import SpotifyModal from '../../components/SpotifyModal/SpotifyModal';
import useSpotifyConnect from '../../hooks/useSpotifyConnect';
import Spinner from '../../components/Spinner/Spinner';

const CreateCoralFunnel = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const artistAddMethod = queryParams.get('method'); // Get the 'method' query parameter
    const spotifyAuthError = queryParams.get('error'); // Get the 'error' query parameter from failed Spotify redirect
    const spotifyState = spotifyAuthError ? 'auth-error': 'add-artists';
    const { spotifyLinked, redirectToSpotify, setSpotifyLinkedStatus } = useSpotifyConnect();

    const [currentStep, setCurrentStep] = useState(0);
    const [maxStep, setMaxStep] = useState(0);
    const { coralData, addArtistDirectly, removeArtist, addArtistFromDynamicArtistPool, addPinnedArtist, removePinnedArtist, retrieveTopArtists, removeArtistsBySourceArtistPoolGuid, uniqueGenres, uniqueLocations, dynamicArtistPools, activeFilters, updatePledgeAmount, saveCoral, simulateCoral, simulationResults, updateFilter } = useCoralEditor('');
    const [showSpotifyAuthModal, setShowSpotifyAuthModal] = useState(false);
    const [isSpotifyModalClosing, setIsSpotifyModalClosing] = useState(false);
    const history = useHistory();

    const handleSaveCoral = async () => {
        const newGuid = await saveCoral();
        if (newGuid)
            history.push(`/coral/${newGuid}`);
    };
    

    useEffect(() => {
        console.log('artistAddMethod:', artistAddMethod);

        if (artistAddMethod === 'spotify' || artistAddMethod === 'spotify-auth') {
            setShowSpotifyAuthModal(true);

            // Clear the artistAddMethod parameter
            queryParams.delete('method');
            history.replace({ search: queryParams.toString() });
        }
    }, [location.search, history]);

    // Define the steps dynamically based on user choices
    // const steps = [
    //     { name: 'Add Artists', component: AddArtist },
    //     { name: 'Refine Selection', component: RefineSelection },
    //     // Include the RefineSelection step conditionally
    //     // ...(!isManualAdd ? [{ name: 'Refine Selection', component: RefineSelection }] : []),
    //     // { name: 'Preview Impact', component: PreviewImpact },
    // ];

    const handleSyncWithSpotify = () => {
        if (!spotifyLinked) {
            redirectToSpotify();
        }
        else {
            setShowSpotifyAuthModal(true);
        }
    };

    const goNext = () => {
        setCurrentStep((prevStep) => {
          const nextStep = Math.min(prevStep + 1, steps.length - 1);
          setMaxStep((prevMaxStep) => Math.max(nextStep, prevMaxStep)); // Update maxStep here
          return nextStep;
        });
      };
    
      const goBack = () => {
        setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
      };
    
      const onStepClick = (step: number) => {
        if (step <= maxStep) { // Check against maxStep here
          setCurrentStep(step);
        }
      };

    // If coralData is null, render a loading screen
    if (!coralData || !coralData.artistPool || !coralData.artistPool.artistList) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Spinner />
            </div>
        );
    }

    const defaultStepProps = {
        addArtistDirectly: () => {},
        removeArtist: () => {},
        removeArtistsBySourceArtistPoolGuid: () => {},
        updateFilter: () => {},
        simulateCoral: () => {},
        updatePledgeAmount: () => {},
        addArtistFromDynamicArtistPool: () => {},
        handleSyncWithSpotify: () => {},
        retrieveTopArtists: () => {},
        addPinnedArtist: () => {},
        removePinnedArtist: () => {},
    };

    const steps = artistAddMethod === 'manual' ? [
        { 
            name: 'Choose Artists', 
            component: SelectArtist,
            props: {
                ...defaultStepProps,
                addArtistDirectly,
                removeArtist,
                addArtistFromDynamicArtistPool,
                handleSyncWithSpotify,
                updateFilter,
            },
        },        
        {
            name: 'Preview Impact',
            component: PreviewImpact,
            props: {
                ...defaultStepProps,
                simulateCoral,
                simulationResults,
                updatePledgeAmount,
                addPinnedArtist,
                removePinnedArtist,
            },
        }
    ] : [
        { 
            name: 'Configure Connections', 
            component: ConfigureConnections,
            props: {
                ...defaultStepProps,
                addArtistDirectly,
                removeArtist,
                addArtistFromDynamicArtistPool,
                handleSyncWithSpotify,
                updateFilter,
                removeArtistsBySourceArtistPoolGuid,
                retrieveTopArtists,
            },
        },
        { 
            name: 'Choose Artists', 
            component: SelectArtist,
            props: {
                ...defaultStepProps,
                addArtistDirectly,
                removeArtist,
                addArtistFromDynamicArtistPool,
                handleSyncWithSpotify,
                updateFilter,
            },
        },        
        {
            name: 'Preview Impact',
            component: PreviewImpact,
            props: {
                ...defaultStepProps,
                simulateCoral,
                simulationResults,
                updatePledgeAmount,
                addPinnedArtist,
                removePinnedArtist,
            },
        }
    ];
    // Shared props
    const sharedProps = {
        artists: coralData.artistPool.artistList.map(({ artistId, artistName, artistListItemStatus, artistData, source, sourceType, sourceArtistPoolGuid, selected, artistListItemActive, payoutStatus }) => ({
            artistId: artistId || '',
            artistName,
            artistListItemStatus,
            artistData,
            source,
            sourceArtistPoolGuid,
            sourceType,
            selected,
            artistListItemActive,
            payoutStatus,
        })),
        simulationResults: simulationResults,
        pledgeAmount: coralData.pledgeAmount,
        activeFilters: activeFilters,
        uniqueGenres: uniqueGenres,
        uniqueLocations: uniqueLocations,
        dynamicArtistPools: dynamicArtistPools,
        spotifyLinked: spotifyLinked,
        pinnedArtists: coralData.pinnedArtists,
    };

    const CurrentStepComponent = steps[currentStep].component;

    const currentStepProps = {
        ...sharedProps,
        ...steps[currentStep].props,
    };

    // Check if we are on the first step
    const isFirstStep = currentStep === 0;

    // Check if the current step is the last step
    const isLastStep = currentStep === steps.length - 1;

    return (
        <div className="h-screen flex flex-col">
            {/* Add the Spotify authorization modal */}
            {showSpotifyAuthModal && (
                <SpotifyModal 
                isOpen={showSpotifyAuthModal} 
                onClose={() => {
                    setIsSpotifyModalClosing(true);
                    setTimeout(() => {
                        setShowSpotifyAuthModal(false);
                        setIsSpotifyModalClosing(false);
                    }, 300);
                }} 
                modalState={spotifyState} 
                isSpotifyLinked={spotifyLinked}
                isSpotifyModalClosing={isSpotifyModalClosing}
                setSpotifyLinkedStatus={setSpotifyLinkedStatus}
            />
            )}
          
            {/* Top bar with Create New Coral, Navigation Trail, and Close button */}
            <div className="flex items-center justify-between pl-8 pr-8">
                {/* <div className="max-w-screen-lg mx-auto flex justify-between w-full">
                    {/* Create New Coral text on the left */}
                    {/* <h1 className="text-lg font-extrabold font-nunito">Coral Creation</h1> */}
                {/* </div> */}
            </div>

            <div className="border-b">
                <div className="flex justify-between mx-auto max-w-screen-lg p-6 pl-8 pr-10">
                    {/* Centered Navigation Trail */}
                    <NavigationTrail currentStep={currentStep} steps={steps} isManualAdd={false} onStepClick={onStepClick} />
                    {/* Close button */}
                    <button className="p-2 flex-shrink-0" onClick={() => history.push('/dashboard')}>
                        <img src={closeButton} alt="Close" className="w-8 h-8" /> {/* Use the closeButton asset here */}
                    </button>
                </div>
            </div>

            {/* Scrollable Current Step Component container */}
            <div className="flex-grow overflow-auto">
                <div className="mx-auto max-w-screen-lg p-4 md:p-8"> {/* Add padding as needed */}
                    <CurrentStepComponent {...currentStepProps} />
                </div>
            </div>
             
            {/* Bottom bar with Navigation Buttons */}
            <div className="px-4 py-3 flex justify-between items-center sticky bottom-0 bg-white mb-8 mt-4 border-t">
                <div className="max-w-screen-lg mx-auto flex justify-between w-full">
                    {/* Show the BACK button if not on the first step */}
                    {currentStep > 0 && (
                        <SecondaryCoralButton onClick={goBack}>BACK</SecondaryCoralButton>
                    )}
                    {/* Otherwise, show an empty space to keep the alignment of the buttons */}
                    {currentStep === 0 && (
                        <div></div> // This empty div will act as a spacer
                    )}

                    {/* Align the NEXT button to the right for all steps except the last */}
                    {!isLastStep && (
                        <div>
                            <CoralButton onClick={goNext} disabled={Object.keys(coralData.artistPool.artistList).length < 2}>
                                NEXT
                            </CoralButton>
                        </div>
                    )}

                    {/* Align the MAYBE LATER and PROCEED TO PAYMENT buttons to the right on the last step */}
                    {isLastStep && (
                        <div className="flex">
                            <SecondaryCoralButton
                                onClick={handleSaveCoral}
                                disabled={coralData.pledgeAmount <= 0}
                            >
                                SAVE AND EXIT
                            </SecondaryCoralButton>
                            <CoralButton
                                onClick={handleSaveCoral}
                                disabled={coralData.pledgeAmount <= 0}
                                className="ml-4" // Add some margin to separate the two buttons
                            >
                                PLEDGE
                            </CoralButton>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CreateCoralFunnel;