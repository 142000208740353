import { useState, useEffect } from 'react';
import { getSpotifyStatusFromAPI } from '../api/api';
import { generateRandomString } from '../utils/authUtils';

const useSpotifyConnect = () => {
    const [spotifyLinked, setSpotifyLinked] = useState(false);

    useEffect(() => {
        getSpotifyStatusFromAPI()
            .then(setSpotifyLinked)
            .catch((error) => console.error('Error getting Spotify status:', error));
    }, []);

    const redirectToSpotify = () => {
        const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
        const redirectUrl = process.env.REACT_APP_SPOTIFY_REDIRECT_URL;
        if (!redirectUrl) {
            throw new Error('Spotify Callback Redirect URL env variable not set');
        }
        const redirectUri = encodeURIComponent(redirectUrl);
        const scopes = encodeURIComponent('user-top-read'); // Define the scopes you need
        const state = generateRandomString(16); // Generate a random state string

        localStorage.setItem('spotify_auth_state', state); // Store the state for later validation

        window.location.href = `https://accounts.spotify.com/authorize?` +
                               `response_type=code&client_id=${clientId}&` +
                               `scope=${scopes}&redirect_uri=${redirectUri}&` +
                               `state=${state}`;
    };

    const setSpotifyLinkedStatus = (status: boolean) => {
        setSpotifyLinked(status);
    };

    return { spotifyLinked, redirectToSpotify, setSpotifyLinkedStatus };
};

export default useSpotifyConnect;