// api.ts
import { BaseCoral, ClientCoral, CoralType, UserDashboard, CoralSimulationResult, ClientArtistPool, User, ArtistApplication, BlendedArtistListItem, UserIdentityVerification } from 'shared/types/platformTypes';
import { getCognitoTokens } from '../utils/authUtils';



export async function getArtistDetails(artistName: string): Promise<BlendedArtistListItem[]> {
    const endpoint = `/artist/get?artistName=${encodeURIComponent(artistName)}`;
    return fetchFromAPI<BlendedArtistListItem[]>(endpoint, (data) => data as BlendedArtistListItem[]);
}

export async function getCoralFromAPI(id: string): Promise<ClientCoral> {
    return fetchFromAPI<ClientCoral>(`/coral/get?id=${id}`, (data) => data as ClientCoral);
}

export async function getCuratedCoralFromAPI(id: string): Promise<ClientCoral> {
    return fetchFromAPIWithoutAuth<ClientCoral>(`/coral/curated/get?id=${id}`, (data) => data as ClientCoral);
}

export async function getwearecoralImpact(featured: boolean = false): Promise<{ artistGuid: string, artistName: string, totalAmount: number, currentMonthAmount: number, priorMonthAmount: number, currentMonthCoralCount: number, priorMonthCoralCount: number }[]> {
    const endpoint = `/analytics/impact${featured ? '?featured=true' : ''}`;
    return fetchFromAPI(endpoint, (data) => data as { artistGuid: string, artistName: string, totalAmount: number, currentMonthAmount: number, priorMonthAmount: number, currentMonthCoralCount: number, priorMonthCoralCount: number }[]);
}

export async function addTagToCoral(coralId: string, tagText: string) {
    const endpoint = `/coral/addTag`;
    const body = JSON.stringify({ coralId, tagText });
    return fetchFromAPI(endpoint, (data) => data, 'POST', body);
}

export async function removeTagFromCoral(coralId: string, tagText: string) {
    const endpoint = `/coral/removeTag`;
    const body = JSON.stringify({ coralId, tagText });
    return fetchFromAPI(endpoint, (data) => data, 'DELETE', body);
}

export async function retrieveTagsForCoral(coralId: string): Promise<string[]> {
  const endpoint = `/coral/retrieveTags?coralId=${encodeURIComponent(coralId)}`;
  const response = await fetchFromAPI(endpoint, (data) => data) as { tags: string[] };
  return Array.isArray(response.tags) ? response.tags : [];
}

export async function getAllCoralTags(limit: number = Infinity): Promise<string[]> {
  const endpoint = `/coral/getAllTags?limit=${limit}`;
  const response = await fetchFromAPI(endpoint, (data) => data) as { tags: string[] };
  return Array.isArray(response.tags) ? response.tags : [];
}

export async function getCoralsForTag(tagText: string): Promise<{ coralName: string; coralGuid: string, artists: string[] }[]> {
    const endpoint = `/coral/getCoralsForTag?tagText=${encodeURIComponent(tagText)}`;
    const response = await fetchFromAPI(endpoint, (data) => data) as { corals: { coralName: string; coralGuid: string, artists: string[] }[] };
    return Array.isArray(response.corals) ? response.corals : [];
}

export async function getRecentSharedCorals(limit: number = 10): Promise<{ coralName: string; coralGuid: string, artists: string[] }[]> {
    const endpoint = `/coral/getRecentSharedCorals?limit=${limit}`;
    const response = await fetchFromAPI(endpoint, (data) => data) as { corals: { coralName: string; coralGuid: string, artists: string[] }[] };
    return Array.isArray(response.corals) ? response.corals : [];
}

export async function createCoralFromAPI(coralType: CoralType): Promise<BaseCoral> {
    const endpoint = `/coral/create`;
    const body = JSON.stringify({ coralType });
    return fetchFromAPI<BaseCoral>(endpoint, (data) => data as BaseCoral, 'POST', body);
}

export interface UpdateCoralVisibilityResponse {
    message: string;
    sharedCoralGuid: string;
    isShared: boolean;
    url: string;
}

export async function updateCoralSharingSettings(coralGuid: string, isShared: boolean): Promise<UpdateCoralVisibilityResponse> {
    const endpoint = `/coral/updateSharingSettings`;
    const body = JSON.stringify({ coralGuid, isShared });
    return fetchFromAPI<UpdateCoralVisibilityResponse>(endpoint, (data) => data as UpdateCoralVisibilityResponse, 'POST', body);
}

export interface UpdateCoralCuratedResponse {
    message: string;
    isCurated: boolean;
}

export async function updateCoralCuratedSettings(coralGuid: string, isCurated: boolean): Promise<UpdateCoralCuratedResponse> {
    const endpoint = `/coral/updateCuratedSettings`;
    const body = JSON.stringify({ coralGuid, isCurated });
    return fetchFromAPI<UpdateCoralCuratedResponse>(endpoint, (data) => data as UpdateCoralCuratedResponse, 'POST', body);
}

export async function saveCoralToAPI(coral: ClientCoral): Promise<{ guid: string }> {
    const endpoint = `/user/saveCoral`;
    const body = JSON.stringify(coral);
    return fetchFromAPI<{ guid: string }>(endpoint, (data) => data as { guid: string }, 'POST', body);
}

export async function updateUserArtistConnections(artistConnections: Array<{ artistName: string, artistGuid: string }>): Promise<void> {
    const endpoint = `/user/artists`;
    const body = JSON.stringify(artistConnections);
    await fetchFromAPI<{ message: string }>(endpoint, (data) => data as { message: string }, 'POST', body);
}

export async function getUserArtistConnections(): Promise<Array<{ artistName: string, artistGuid: string }>> {
    const endpoint = `/user/artists`;
    return fetchFromAPI<Array<{ artistName: string, artistGuid: string }>>(endpoint, (data) => data as Array<{ artistName: string, artistGuid: string }>, 'GET');
}

export async function createArtistApplication(artistApplication: ArtistApplication): Promise<{applicationId: string; status: string; artistName: string;}> {
    const endpoint = `/user/application`;
    const body = JSON.stringify({ application: artistApplication });
    return fetchFromAPI<{applicationId: string; status: string; artistName: string;}>(endpoint, (data) => data as {applicationId: string; status: string; artistName: string;}, 'POST', body);
}

export async function getUserArtistApplications(): Promise<{applicationId: string; status: string; artistName: string;}[]> {
    const endpoint = `/user/applications`;
    return fetchFromAPI<{applicationId: string; status: string; artistName: string;}[]>(endpoint, (data) => data as {applicationId: string; status: string; artistName: string;}[], 'GET');
}

export async function createUserIdentityVerification(userIdentityVerification: UserIdentityVerification): Promise<{verificationId: string; status: string;}> {
    const endpoint = `/user/verification`;
    const body = JSON.stringify({ verification: userIdentityVerification });
    return fetchFromAPI<{verificationId: string; status: string;}>(endpoint, (data) => data as {verificationId: string; status: string;}, 'POST', body);
}

export interface SimulateCoralResponse {
    transactionFee: number;
    platformFee: number;
    artistAllocations: CoralSimulationResult[];
}

export async function simulateCoral(coral: ClientCoral): Promise<SimulateCoralResponse> {
    const endpoint = `/coral/simulate`;
    const body = JSON.stringify(coral);
    return fetchFromAPI<SimulateCoralResponse>(endpoint, (data) => data as SimulateCoralResponse, 'POST', body);
}

export async function simulateCoralWithoutAuth(coral: ClientCoral): Promise<SimulateCoralResponse> {
    const endpoint = `/coral/simulateSharedCoral`;
    const body = JSON.stringify(coral);
    return fetchFromAPIWithoutAuth<SimulateCoralResponse>(endpoint, (data) => data as SimulateCoralResponse, 'POST', body);
}

export async function updateUser(user: Partial<User>): Promise<User> {
    const endpoint = `/user/update`;
    const body = JSON.stringify(user);
    return fetchFromAPI<User>(endpoint, (data) => data as User, 'POST', body);
}

export async function getUserFromAPI(userId: string): Promise<User> {
        return fetchFromAPI<User>(`/user/get?id=${userId}`, (data) => data as User);
}

export async function getUserDashboard(): Promise<UserDashboard> {
    return fetchFromAPI<UserDashboard>('/user/dashboard', (data) => data as UserDashboard);
}

// --- Spotify Related API Calls ---
export async function getSpotifyStatusFromAPI(): Promise<boolean> {
    const endpoint = `/spotify/status`;
    const response = await fetchFromAPI(endpoint, (data) => data) as { spotifyStatus: boolean };
    console.log('Spotify status2:', response.spotifyStatus);
    return response.spotifyStatus;
}

export async function exchangeCodeForAccessToken(code: string) {
    try {
        const data = await fetchFromAPI(`/spotify/callback?code=${code}`, (data) => data);
        console.log('Spotify link successful:', data);
    } catch (error) {
        console.error('Error exchanging Spotify code:', error);
        throw error;
    }
}

export async function returnFavouriteArtists(): Promise<ClientArtistPool> {
    const endpoint = `/spotify/favouriteartists`;
    const response = await fetchFromAPI<{ favouriteArtistsPool: ClientArtistPool }>(endpoint, (data: { favouriteArtistsPool: ClientArtistPool }) => data, 'POST');
    return response.favouriteArtistsPool;
}

// -------------------------------

// Generic API Call
async function fetchFromAPI<T>(endpoint: string, processor: (data: T) => T, method: 'GET' | 'POST' | 'DELETE' = 'GET', body?: string): Promise<T> {
    const tokens = await getCognitoTokens();
    
    if (!tokens) {
        throw new Error('Could not retrieve Cognito idToken');
    }

    const idTokenString = tokens.idToken;
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_ENDPOINT;
    const response = await fetch(`${apiEndpoint}${endpoint}`, {
        method,
        headers: {
            'Authorization': `Bearer ${idTokenString}`,
            'Content-Type': 'application/json',
        },
        body,
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    if (isType<T>(data)) {
        return processor(data);
    } else {
        throw new Error('Invalid data type');
    }
}

async function fetchFromAPIWithoutAuth<T>(endpoint: string, processor: (data: T) => T, method: 'GET' | 'POST' = 'GET', body?: string): Promise<T> {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_ENDPOINT;
    const response = await fetch(`${apiEndpoint}${endpoint}`, {
        method,
        headers: {
            'Content-Type': 'application/json',
        },
        body,
    });

    if (!response.ok) {
        throw new Error('Network response was not ok');
    }

    const data = await response.json();
    if (isType<T>(data)) {
        return processor(data);
    } else {
        throw new Error('Invalid data type');
    }
}

function isType<T>(data: unknown): data is T {
    // Here you can add more specific type checking logic if needed
    return typeof data === 'object' && data !== null;
}