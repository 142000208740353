import React, { ReactNode, MouseEventHandler } from 'react';
import spotifyIcon from '../../assets/images/spotify/Spotify_Icon_RGB_Green.png';

interface ConnectionPillProps {
    children: ReactNode;
    onClick: MouseEventHandler<HTMLButtonElement>;
    variant: 'standard' | 'spotify';
    isActive: boolean;
    onRemove?: () => void;
}

const ConnectionPill: React.FC<ConnectionPillProps> = ({ children, onClick, variant, isActive, onRemove }) => {
    const baseClass = 'flex items-center justify-center px-4 py-1 border rounded-full shadow text-sm font-medium mr-2';
    const activeClass = isActive ? 'font-bold' : '';
    let variantClass, icon;

    if (variant === 'spotify') {
        variantClass = isActive ? 'text-green-600 border-green-600 bg-green-50' : 'text-green-600 border-green-600 bg-white';
        icon = <img src={spotifyIcon} alt="Spotify" className="w-5 h-5 mr-2" />;
    } else {
        variantClass = 'text-coral-deep-blue bg-white';
        icon = null;
    }

    return (
        <button
            onClick={onClick}
            className={`${baseClass} ${variantClass} ${activeClass}`}
        >
            <div className="inline-flex items-center">
                {icon}
                {children}
                {isActive && onRemove && (
                    <span className="bg-transparent hover:bg-gray-200 p-1 rounded-full ml-2 focus:outline-none font-extrabold">
                    ×
                    </span>
                )}
            </div>
        </button>
    );
};

export default ConnectionPill;