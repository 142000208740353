import { ClientArtistListItem } from 'shared/types/platformTypes';

export type ArtistStatus = 'active' | 'deceased' | 'top-artist' | 'retired' | 'pending' | 'disambiguation';

export const addArtist = (artistList: ClientArtistListItem[], newArtist: ClientArtistListItem): ClientArtistListItem[] => {
    return [...artistList, newArtist];
};

export const updateArtist = (artistList: ClientArtistListItem[], updatedArtist: ClientArtistListItem): ClientArtistListItem[] => {
  const index = artistList.findIndex(artist => artist.artistId === updatedArtist.artistId);
  if (index !== -1) {
    const newArtistList = [...artistList];
    newArtistList[index] = updatedArtist;
    return newArtistList;
  }
  return artistList;
};

export const removeArtist = (artistList: ClientArtistListItem[], artistId: string): ClientArtistListItem[] => {
  return artistList.filter(artist => artist.artistId !== artistId);
};

export const setArtistStatus = (artistList: ClientArtistListItem[], artistId: string, status: string): ClientArtistListItem[] => {
  if (!['active', 'deceased', 'top-artist', 'retired', 'pending', 'disambiguation'].includes(status)) {
    throw new Error(`Invalid status: ${status}`);
  }
  const index = artistList.findIndex(artist => artist.artistId === artistId);
  if (index !== -1) {
    const newArtistList = [...artistList];
    newArtistList[index] = {
      ...newArtistList[index],
      artistListItemStatus: status as ArtistStatus,
    };
    return newArtistList;
  }
  return artistList;
};