import React, { useEffect } from 'react';
import { Authenticator, useTheme, View, Image, Heading } from '@aws-amplify/ui-react';
import CoralLogo from '../../assets/images/coral-icon.png';

interface AuthModalProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setCurrentUser: (user: any) => void;
  setShowAuth: (showAuth: { show: boolean, signIn: boolean }) => void;
  signIn?: boolean;
}

const AuthModal: React.FC<AuthModalProps> = ({ setCurrentUser, setShowAuth, signIn = false }) => {

  const { tokens } = useTheme();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Dynamically select the modal content using the `data-amplify-container` attribute
      const modalContent = document.querySelector('[data-amplify-container]');

      if (modalContent && !modalContent.contains(event.target as Node)) {
        // If the click is outside the modal content, close the modal
        setShowAuth({ show: false, signIn: false });
      }
    };

    // Attach the event listener to the document
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setShowAuth]);

  const AuthComponents = {
    SignIn: {
      Header() {
        return (
          <View textAlign="center" padding={ tokens.space.large }>
            <Image
              alt="Coral logo"
              src={ CoralLogo }
              style={ { width: '60x', height: '60px' } }
            />
            <Heading
              level={ 5 }
              padding={ `${ tokens.space.xl } 0 0 0` }
              textAlign="center"
              style={ { fontFamily: 'Nunito, sans-serif', fontWeight: 800 } }
            >
              Welcome back
            </Heading>
          </View>
        );
      },
    },
    SignUp: {
      Header() {
        return (
          <View textAlign="center" padding={ tokens.space.large }>
            <Image
              alt="Coral logo"
              src={ CoralLogo }
              style={ { width: '60x', height: '60px' } }
            />
            <Heading
              level={ 5 }
              padding={ `${ tokens.space.xl } 0 0 0` }
              textAlign="center"
              style={ { fontFamily: 'Nunito, sans-serif', fontWeight: 800 } }
            >
              Welcome to Coral
            </Heading>
          </View>
        );
      },
    }
  };

  const AuthFormFields = {
    signIn: {
      username: {
        placeholder: '',
      },
      password: {
        placeholder: '',
      },
    },
    signUp: {
      email: {
        placeholder: '',
      },
      password: {
        placeholder: '',
      },
      confirm_password: {
        placeholder: '',
      },
    },
  };

  return (
    <div>
      <Authenticator
        initialState={ signIn ? 'signIn' : 'signUp' }
        variation="modal"
        loginMechanisms={ ['email'] }
        components={ AuthComponents }
        formFields={ AuthFormFields }
        // socialProviders={['apple', 'google']} // Requires additional configuration
      >
        {
          ({ user }) => { // Use the defined User type
            if (user) {
              setCurrentUser(user);
              setShowAuth({ show: false, signIn: false }); // Hide authenticator after successful login
            }
            return (<></>); // Return empty element to satisfy typescript
          }
        }
      </Authenticator>
    </div>
  );
};

export default AuthModal;
