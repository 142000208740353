// src/pages/Home/Home.tsx
import React from 'react';
import { useHistory } from 'react-router-dom';
import '@aws-amplify/ui-react/styles.css';
import mainBackgroundImage from '../../assets/images/homepage/main-background.png';
import blueWaveImage from '../../assets/images/blue-wave.png';
import darkBlueWaveImage from '../../assets/images/dark-blue-wave.png';
// import forArtists from '../../assets/images/homepage/give-love.png';
// import forMusicLovers from '../../assets/images/homepage/music-person-icon.png';
import playIcon from '../../assets/images/play-icon.png';
import FAQItem from '../../components/FAQItem/faqItem';
import JoinCoralButton from '../../components/CoralButtons/JoinCoralButton';
import SecondaryCoralButton from '../../components/CoralButtons/SecondaryCoralButton';
import { faqData } from './faqData';

const Home: React.FC = () => {
    const history = useHistory();

    return (
        <div className="flex flex-col min-h-screen mx-auto overflow-hidden">
            {/* Full-width background image container */}
            <div className="relative flex-grow p-14 pt-6 xl:pb-60 2xl:pb-96 bg-no-repeat bg-contain bg-bottom 3xl:bg-cover" style={{ backgroundImage: `url(${mainBackgroundImage})` }}>
                {/* Centered content within a max-width container */}
                <div className="max-w-screen-lg mx-auto text-center mb-8 md:mb-32 lg:mb-48 2xl:mb-0 pb-16 md:pb-40 lg:py-20 xl:pb-32 2xl:pb-52 2xl:pt-16 3xl:pb-72">
                    <h1 className="font-nunito text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-coral-black font-extrabold mb-4">
                        Support the artists and music scenes you love.
                    </h1>
                    <p className="text-base sm:text-lg md:text-xl text-custom-gray mb-4 sm:w-4/5 sm:mx-auto">
                    With a single monthly contribution, support multiple artists and be part of a living ecosystem that multiplies the music and culture you enjoy.
                    </p>
                    <button onClick={() => {
                        history.push('/welcome');
                    }}>
                        <img src={playIcon} alt="Rewind" className="cursor-pointer h-12 w-12 mb-4" />
                    </button>
                    <div className="flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
                        <JoinCoralButton />
                        <SecondaryCoralButton popOnHover={true} onClick={() => history.push('/for-artists')}>
                            For Artists
                        </SecondaryCoralButton>
                    </div>
                </div>
            </div>

            {/* Consistent structure applied to all sections */}
            <div className="bg-coral-blue">
                {/* Mission section */}
                <div className="p-16">
                    <div className="max-w-screen-xl mx-auto">
                        <div className="text-left md:w-1/2">
                            <h2 className="text-m md:text-l font-extrabold">
                                <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                    WHAT WE&apos;RE ABOUT
                                </div>
                            </h2>
                            <h2 className="font-nunito text-2xl md:text-3xl text-white font-extrabold mb-6">
                                A thriving ecosystem where music is valued and diverse styles and scenes can prosper.
                            </h2>
                            <p className="text-base md:text-lg text-white mb-6">
                            You love music – listening, dancing, and sharing it with others. Yet, in a society that prioritises economic growth over art and culture, we feel a growing disconnection as we witness the erosion of musical diversity. Venues are closing, scenes are struggling, and artists are abandoning music for more stable careers.
                            </p>
                            <p className="text-base md:text-lg text-white mb-6">
                                A <a href="https://www.abc.net.au/triplej/programs/program-unearthed/whats-up-in-australian-music-survey-results-feature/102988278" target="_blank" rel="noopener noreferrer">recent survey</a> found half our artists are considering quitting music, and 83% are working non-music jobs to make ends meet. That&apos;s a lot of time spent <em>not</em> making music. Our collective loss - music that never gets made or enjoyed.
                            </p>
                            <p className="text-base md:text-lg text-white mb-6">
                                But we don&apos;t need to let our music and culture wither. We are many, and with small actions taken together we can turn it around, multiplying the music and culture we value. It&apos;s more than support – it&apos;s about cooperating to create a future that is rich, vibrant, and enjoyable.
                            </p>
                            <p className="text-base md:text-lg text-white mb-6">
                                <em>We&apos;ll have more fun together.</em>
                            </p>
                            {/* Artist and Music Lover sections */}
                            {/* <div className="flex flex-col lg:flex-row justify-between items-start gap-4 text-white"> */}
                                {/* For Artists */}
                                {/* <div className="flex flex-col items-start"> */}
                                    {/* <img src={forArtists} alt="For Artists" className="mb-4" width="32" height="32" /> */}
                                    {/* <h3 className="font-bold text-base sm:text-lg md:text-xl">For artists</h3> */}
                                    {/* <p className="text-sm md:text-base"> */}
                                    {/* Focus on creating music, and connect with your fans, while the wearecoral community supports you with additional income, inspiration, and encouragement. */}
                                    {/* </p> */}
                                {/* </div> */}
                                {/* For Music Lovers */}
                                {/* <div className="flex flex-col items-start"> */}
                                    {/* <img src={forMusicLovers} alt="For Music Lovers" className="mb-4" width="32" height="32" /> */}
                                    {/* <h3 className="font-bold text-base sm:text-lg md:text-xl">For music communities</h3> */}
                                    {/* <p className="text-sm md:text-base"> */}
                                    {/* Easily cooperate with others to connect, support and amplify artists you value, leveraging the unique features of wearecoral. Discover and wield your collective agency to shape culture and music. */}
                                    {/* </p> */}
                                {/* </div> */}
                            {/* </div> */}
                        </div>
                    </div>
                </div>
                {/* Consistently structured wave image */}
                <img src={blueWaveImage} alt="Blue Wave" className="w-full" />
            </div>

            {/* How It Works section */}
            <div className="bg-coral-deep-blue text-white text-left">
                <div className="p-16">
                    <div className="max-w-screen-xl mx-auto flex justify-center">
                        <div className="md:w-1/2">
                            <h2 className="text-m md:text-l font-extrabold">
                                <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                    HOW WE WORK TOGETHER
                                </div>
                            </h2>
                            <h2 className="font-nunito text-2xl md:text-3xl text-white font-extrabold mb-6">
                                Funding the Music You Love
                            </h2>
                            <p className="text-base sm:text-lg md:text-xl">
                                wearecoral makes it simple to cooperate with others to fund the culture you care about. Here&apos;s how:
                            </p>
                            <div className="space-y-4 mt-4 text-base sm:text-lg md:text-xl">
                                <div className="font-semibold text-transparent bg-clip-text bg-gradient-to-r from-yellow-400 to-orange-500 animate-breathe-fast">
                                    Join wearecoral, then create your &ldquo;coral&rdquo;. You choose the artists to support and a single monthly contribution.
                                </div>
                                <div className="font-semibold text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-blue-500 animate-breathe-fast">
                                    Share your coral and encourage others to support music. The more of us, the more we can do.
                                </div>
                                <div className="font-semibold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-500 text-glow animate-breathe-fast">
                                    Watch as your support, combined with others&apos;, enables artists to create and share more music.
                                </div>
                                <div className="font-semibold text-transparent bg-clip-text bg-gradient-to-r from-coral-pink to-coral-orange text-glow animate-breathe-fast">
                                    Enjoy the fruits of a thriving, diverse music ecosystem that you helped create. More music. More fun. More connection. Our way.
                                </div>
                                {/* <div className="font-semibold text-transparent bg-clip-text bg-gradient-to-r from-teal-400 to-cyan-500 text-glow animate-breathe">
                                    
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="p-16">
                        <div className="max-w-screen-xl mx-auto flex flex-col items-center">
                            <JoinCoralButton />
                        </div>
                    </div>
                </div>
                <div className="relative w-full h-full">
                    <img src={darkBlueWaveImage} alt="Dark Blue Wave" className="w-full h-full object-cover" />
                    <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-dark-blue to-transparent"></div>
                </div>
            </div>

            {/* FAQ section */}
            <div className="bg-coral-dark-blue text-white p-16">
                <div className="container mx-auto">
                    <div className="max-w-screen-xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
                        {/* Left column for the title and description */}
                        <div>
                            <h2 className="text-m md:text-l font-extrabold">
                                <div className="inline bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                                    SUPPORT
                                </div>
                            </h2>
                            <h3 className="font-nunito text-2xl md:text-3xl font-extrabold mb-4">
                                FAQs
                            </h3>
                            <p className="text-base sm:text-lg md:text-xl mb-4">
                                Everything you need to know about wearecoral and how it operates.
                            </p>
                        </div>

                        {/* Right column for the FAQ questions and answers */}
                        <div>
                            {faqData.map((faq, index) => (
                                <FAQItem key={index} question={faq.question} answer={faq.answer} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
