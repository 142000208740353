import React, { useState } from 'react';
import MiniArtistTable from '../../CoralEditor/ArtistTable/MiniArtistTable';
import searchIcon from '../../../assets/images/search-icon.png';
import collapseBox from '../../../assets/images/collapseBox-coral.png';
import expandBox from '../../../assets/images/expandBox-coral.png';
import { ClientArtistListItem } from 'shared/types/platformTypes';
// import spotifyIcon from '../../../assets/images/spotify-icon-small.png';
import Dropdown from '../../../components/Dropdown/Dropdown';
import FilterPill from '../../../components/FilterPill/FilterPill';

export interface AddArtistProps {
  artists: Array<ClientArtistListItem>;
  addArtistDirectly: (artistName: string) => void;
  removeArtist: (artistId: string) => void;
  addArtistFromDynamicArtistPool: (artist: ClientArtistListItem) => void; 
  handleSyncWithSpotify: () => void;
  updateFilter: (filterType: 'genre' | 'location', value: string, action: 'add' | 'remove') => void; // A function to update the filters
  activeFilters: Array<{ filterType: 'genre' | 'location'; value: string }>; // An array of active filters
  uniqueGenres: { value: string; count: number; }[]; // An array of unique genres
  uniqueLocations: { value: string; count: number; }[]; // An array of unique locations
  pageTitle?: string;
}

const SelectArtist: React.FC<AddArtistProps> = ({ artists, addArtistDirectly, addArtistFromDynamicArtistPool, removeArtist, uniqueGenres, uniqueLocations, activeFilters, updateFilter, pageTitle = 'Choose Your Artists' }) => {
  const [newArtistName, setNewArtistName] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);

  const handleAddArtistClick = () => {
    addArtistDirectly(newArtistName);
    setNewArtistName(''); // Clear the input after adding an artist
  };

  const handleRemoveFilter = (filterToRemove: { type: 'genre' | 'location'; value: string }) => {
    updateFilter(filterToRemove.type, filterToRemove.value, 'remove');
  };

  return (
    <div className="flex flex-col items-center px-4 py-4">
      <div className="w-full max-w-4xl">
          <h1 className="font-nunito text-2xl md:text-3xl font-extrabold mb-2">{pageTitle}</h1>
          <p className="text-md mb-4">
            Curate the artists you wish to support. Add them from your suggestions, use <em>Living Filters</em>, or add them manually.
          </p>
        
        {/* <div className="font-nunito text-xl font-extrabold">Your supported artists</div> */}
        {/* Mini Artist Table
        <MiniArtistTable 
          artists={artists}
          onRemoveArtist={removeArtist}
          addArtistFromDynamicArtistPool={addArtistFromDynamicArtistPool}
          viewType='selected'
          emptyTableMessage='Add artists from your suggestions below or use Living Filters'
        /> */}

        {/* <div className="mt-8 mb-0 font-nunito text-xl font-extrabold">Add artists</div> */}

        <div className="w-full">
          {/* Quick Filters */}
          <div className="flex justify-between items-center">
            <div className="text-base font-nunito font-bold">Living Filters</div>
          </div>
          <div className="mb-2">
            <div className="flex justify-center space-x-2 mb-4 ml-2 mr-2 sm:ml-24 sm:mr-24 flex-wrap">
              {/* Active Filters */}
              {activeFilters.map((filter, index) => (
                <FilterPill
                  filterValue={filter.value}
                  key={index}
                  selected={true}
                  onClick={() => handleRemoveFilter({ type: filter.filterType, value: filter.value })}
                />
              ))}              
              {/* Top 3 genres */}
              {uniqueGenres
                .filter(genre => !activeFilters.some(filter => filter.filterType === 'genre' && filter.value === genre.value))
                .slice(0, 3)
                .map((genre, index) => (
                  <FilterPill filterValue={genre.value} key={index} selected={false} onClick={() => updateFilter('genre', genre.value, 'add')} />
                ))}
              {/* Top 3 locations */}
              {uniqueLocations
                .filter(location => !activeFilters.some(filter => filter.filterType === 'location' && filter.value === location.value))
                .slice(0, 3)
                .map((location, index) => (
                  <FilterPill filterValue={location.value} key={index} selected={false} onClick={() => updateFilter('location', location.value, 'add')} />
                ))}
            </div>
          </div>

          {/* Advanced Filters */}
          <div className="flex items-center py-1 w-full">
            <div className="text-base font-nunito text-gray-400 mr-2">Advanced Living Filters</div>
            <button onClick={() => setIsExpanded(!isExpanded)} className="p-0 border-0 flex justify-end">
              {isExpanded ? 
                <img src={collapseBox} alt="Collapse" style={{ width: '35%', height: 'auto' }} /> : 
                <img src={expandBox} alt="Expand" style={{ width: '35%', height: 'auto' }} />
              }
            </button>
          </div>
          {isExpanded && (
            <div className="mb-6">
              {/* Filters by Genre or Location */}
              <div className="grid grid-cols-2 gap-4 mb-4">
                {/* Genre Input */}
                <div>
                  <div className="text-sm my-2">Select by genre</div>
                  <Dropdown
                    options={uniqueGenres.map(genre => `${genre.value}` )}
                    placeholder="Ambient, Indie, Folk, etc."
                    onSelect={(selectedGenre: string) => {
                      console.log("Selected genre: ", selectedGenre);
                      updateFilter('genre', selectedGenre, 'add');
                    }}
                  />
                </div>
                {/* Location Input */}
                <div>
                  <div className="text-sm my-2">Select by location</div>
                  <Dropdown
                    options={uniqueLocations.map(location => `${location.value}` )}
                    placeholder="AU, US, GB, etc."
                    onSelect={(selectedLocation: string) => {
                      updateFilter('location', selectedLocation, 'add');
                    }}
                  />            
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="text-sm my-2 mt-8">Can&apos;t find an artist below? Add them directly</div>
        {/* Add Artist Input */}
        <div className="flex items-center border border-gray-300 rounded-md shadow-sm mb-4">
          <div className="pl-3 flex-shrink-0">
            <img src={searchIcon} alt="Search" className="h-5 w-5 text-gray-500" />
          </div>
          <input
            type="text"
            name="artist-search"
            id="artist-search"
            value={newArtistName}
            onChange={(e) => setNewArtistName(e.target.value)}
            className="focus:ring-coral-pink focus:border-coral-pink flex-grow block w-full sm:text-sm border-0 focus:outline-none"
            placeholder=""
          />
          <button
            onClick={handleAddArtistClick}
            className="bg-coral-pink text-white px-2 py-2 rounded-r-md hover:bg-coral-dark"
          >
            Add
          </button>
        </div>

        {/* Mini Artist Table */} 
        <MiniArtistTable 
            artists={artists}
            onRemoveArtist={removeArtist}
            addArtistFromDynamicArtistPool={addArtistFromDynamicArtistPool}
            viewType='suggested'
            emptyTableMessage='Add connections to generate artist suggestions'
          />
      </div>
    </div>
  );
};

export default SelectArtist;
