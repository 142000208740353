import React, { useRef } from 'react';
import closeIcon from '../../assets/images/close.png';
import { useOutsideAlerter } from '../../hooks/useOutsideAlerter';

interface CoralModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode|string;
}

const CoralModal: React.FC<CoralModalProps> = ({ isOpen, onClose, children }) => {
  const ref = useRef(null);
  useOutsideAlerter(ref, onClose);

  if (!isOpen) return null;

  return (
    <div
      className="w-full h-full fixed inset-0 bg-[rgba(0,0,0,0.5)] grid place-items-center z-[1000]">
      <div className="w-[calc(100%-70px)] max-w-6xl max-h-[100svh-30px] md:max-h-[736px] relative p-4 sm:p-8 bg-white rounded-lg shadow-2xl"
           ref={ ref }>
        <button
          onClick={ () => onClose() }
          className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2"
        >
          <img src={ closeIcon } alt="Close" className="w-10 h-10 object-contain"/>
        </button>
        <div className="relative mx-auto p-2 sm:p-8">
          { children }
        </div>
      </div>
    </div>
  );
};

export default CoralModal;