import React, { useEffect } from 'react';
import { BiSearch } from 'react-icons/bi';
import CoralCardWithThumbnail from '../../../../components/CoralCard/CoralCardWithThumbnail';
import { useCuratedCorals } from '../../../../hooks/useCuratedCorals';

interface CoralFilterTagProps {
  isSelected?: boolean;
  onClick?: () => void;
  children?: React.ReactNode|string;
  isLoading?: boolean;
}

const CoralFilterTag: React.FC<CoralFilterTagProps> = ({ isSelected, onClick, children, isLoading = false }) => {
  if (isLoading) {
    return (
      <span className={ `bg-primary-100 text-primary-600 px-4 py-2 text-xs rounded-[5000px] cursor-pointer transition-all duration-200 ease-in-out whitespace-nowrap animate-pulse text-transparent` } aria-hidden="true">Tag</span>
    );
  }

  return (
    <span
      onClick={ onClick }
      className={ `${ isSelected ? 'bg-primary-200 ' : '' }text-primary-600 px-4 py-2 text-xs rounded-[5000px] cursor-pointer transition-all duration-200 ease-in-out whitespace-nowrap` }
    >
      { children }
    </span>
  );
}

const CreateCoralFromCuratedArtists: React.FC<{
  pageTurner: (page: string, guid?: string) => void
}> = ({ pageTurner }) => {
  const { tags, corals, selectedTags, setSelectedTags, fetchCorals } = useCuratedCorals();


  useEffect(() => {
    if (selectedTags.length > 0) {
      fetchCorals(selectedTags[0]);
    }
  }, [selectedTags]);

  const isSelected = (tagName: string): boolean => selectedTags.includes(tagName);

  return (
    <section className="grid grid-cols-1 lg:grid-cols-[170px_1fr] gap-6">
      <section className="flex flex-col gap-4 justify-center">
        <div className="flex flex-row lg:flex-col gap-4 items-center lg:items-start overflow-scroll overscroll-x-contain no-scrollbar">
          {tags.length > 0 ? (
            tags.map((tag) => (
              <CoralFilterTag
                key={tag}
                isSelected={isSelected(tag)}
                onClick={() => {
                  setSelectedTags([tag]);
                  fetchCorals(tag);
                }}
              >
                {tag}
              </CoralFilterTag>
            ))
          ) : (
            <>
              <CoralFilterTag isLoading />
              <CoralFilterTag isLoading />
              <CoralFilterTag isLoading />
              <CoralFilterTag isLoading />
              <CoralFilterTag isLoading />
              <CoralFilterTag isLoading />
              <CoralFilterTag isLoading />
              <CoralFilterTag isLoading />
              <CoralFilterTag isLoading />
              <CoralFilterTag isLoading />
            </>
          )}
        </div>
      </section>
      <section className="flex flex-col gap-6">
        <label className="relative">
          <span className="absolute h-full grid items-center justify-start pl-6">
            <BiSearch className="w-[24px] h-[24px]" width={24} height={24} />
          </span>
          <input type="text" placeholder="Search"
                 className="w-full bg-transparent border-2 border-solid border-gray-300 rounded-md py-4 pr-6 pl-16 focus:ring-0 z-10"/>
        </label>
        <h2 className="text-lg font-nunito font-bold leading-[1] text-primary-600">Browse Curated Corals</h2>
        <p className="text-gray-500 text-sm">Explore curated corals for inspiration. Add artists from any coral, or link the coral to yours for updates.</p>
        <section className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 overflow-y-scroll max-h-[430px]">
          {corals.length > 0 ? (
            corals.map((coral, index) => (
              <CoralCardWithThumbnail
                key={index}
                pageTurner={pageTurner}
                title={coral.coralName}
                subtitle={coral.artists.join(', ')}
                guid={coral.coralGuid}
              />
            ))
          ) : (
            <div className="col-start-1 col-end-3 md:col-end-4 lg:col-end-5">
              <h2 className="text-xl font-bold font-nunito text-center">
                No corals to show.
              </h2>
            </div>
          )}
        </section>
      </section>
    </section>
  );
};

export default CreateCoralFromCuratedArtists;