import React, { useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import MiniArtistTable from '../../components/CoralEditor/ArtistTable/MiniArtistTable';
import SecondaryCoralButton from '../../components/CoralButtons/SecondaryCoralButton';
import ShareCoralModal from '../../components/ShareCoralModal/ShareCoralModal';
import useCoralEditor from '../../hooks/useCoralEditor';
import FilterPill from '../../components/FilterPill/FilterPill';

const Coral: React.FC = () => {
    const { id } = useParams<{ id?: string }>();
    const { coralData, dynamicArtistPools, activeFilters, toggleShare, toggleCurated } = useCoralEditor(id || '');
    const history = useHistory();
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  
    const handleEdit = () => {
      history.push(`/coral/${id}/edit`);
    };
  
    const handleShare = () => {
      setIsShareModalOpen(true);
    };
  
    const selectedArtistsCount = coralData && coralData.artistPool.artistList ? coralData.artistPool.artistList.filter(artist => artist.selected).length : 0;
  

    return (
        <div className={`bg-white p-6 rounded-lg shadow-md ${coralData ? '' : 'animate-pulse'}`}>
            {/* Breadcrumbs */}
            <div className="mb-6 font-nunito font-semibold">
                <Link to="/dashboard" className="text-gray-400 text-sm">Dashboard</Link>
                <span className="mx-2 text-gray-400">&gt;</span>
                <span className='text-sm'>View Coral</span>
            </div>
            <hr className="my-4 border-gray-300" />
            <header className="flex justify-between items-center mb-6">
                <div className="flex items-center">
                    <h1 className={`font-nunito text-base font-extrabold text-gray-900 mr-3 ${coralData ? '' : 'animate-pulse'}`}>
                    {coralData && coralData.coralName}
                    </h1>
                    <span className={`px-3 py-1 text-sm text-green-800 bg-green-200 rounded-full mr-2 ${coralData ? '' : 'animate-pulse'}`}>
                    Active
                    </span>
                </div>
                <div className="flex items-center space-x-2">
                    <SecondaryCoralButton onClick={handleShare}>
                        Share
                    </SecondaryCoralButton>
                    <SecondaryCoralButton onClick={handleEdit}>
                        Edit
                    </SecondaryCoralButton>
                </div>
            </header>
            {/* Tiles for SUPPORT, ARTISTS, COMMUNITIES */}
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
                {/* SUPPORT Tile */}
                <div className={`flex flex-col justify-between bg-white p-4 rounded-lg shadow-xl h-full ${coralData ? '' : 'animate-pulse'}`}>
                    <div className="text-coral-pink font-bold text-sm mb-3">CONTRIBUTION</div>
                    <div className="flex items-baseline">
                        <span className="text-xl font-semibold">${coralData?.pledgeAmount}</span>
                        <span className="text-xs text-gray-400 ml-1 -mt-1">monthly</span>
                    </div>
                </div>
                {/* ARTISTS Tile */}
                <div className={`flex flex-col justify-between bg-white p-4 rounded-lg shadow-xl h-full ${coralData ? '' : 'animate-pulse'}`}>
                    <div className="self-start text-coral-pink text-sm font-bold mb-3">ARTISTS</div>
                    <div className="flex items-baseline">
                        <span className="text-xl font-semibold">{selectedArtistsCount}</span>
                        <span className="text-xs text-gray-400 ml-1 -mt-1">supported</span>
                    </div>
                </div>
                {/* LIVING FILTERS Tile */}
                <div className={`flex flex-col justify-between bg-white p-4 rounded-lg shadow-xl h-full ${coralData ? '' : 'animate-pulse'}`}>
                    <div className="self-start text-coral-pink text-sm font-bold mb-3">CONNECTIONS</div>
                    <div className="flex items-baseline">
                        <span className="text-xl font-semibold">{activeFilters.length}</span>
                        <span className="text-xs text-gray-400 ml-1 -mt-1">living filter on {dynamicArtistPools.length} connections</span>
                    </div>
                </div>
            </div>
            {/* Coral Rules and Artist Table sections */}
            <section className="coral-rules">
                <div className="flex items-center mb-4">
                    <h2 className="text-sm font-semibold text-coral-blue mr-4">Living Filters</h2>
                    {/* Active Filters */}
                    {activeFilters.map((filter, index) => (
                        <FilterPill
                        filterValue={filter.value}
                        key={index}
                        selected={false}
                        onClick={() => {}}
                        />
                    ))}  
                </div>
            </section>
            <section className="coral-table">
                <MiniArtistTable 
                    artists={coralData && coralData.artistPool.artistList ? coralData.artistPool.artistList.map(({ artistId, artistName, artistListItemStatus, artistData, source, sourceType, sourceArtistPoolGuid, selected, artistListItemActive, payoutStatus, totalUserPayout, lastMonthPlatformPayout }) => ({
                        artistId: artistId || '',
                        artistName,
                        artistListItemStatus,
                        artistData,
                        source,
                        sourceArtistPoolGuid,
                        sourceType,
                        selected,
                        artistListItemActive,
                        payoutStatus,
                        totalUserPayout,
                        lastMonthPlatformPayout,
                    })) : []}
                    onRemoveArtist={() => {}}
                    addArtistFromDynamicArtistPool={() => {}}
                    viewType="view"
                    pinnedArtists={coralData ? coralData.pinnedArtists : []}
                />
            </section>
            {coralData && (
                <ShareCoralModal
                    isOpen={isShareModalOpen}
                    onClose={() => setIsShareModalOpen(false)}
                    coralData={coralData}
                    toggleShare={async () => {
                        if (coralData) {
                            await toggleShare();
                        }
                    }}
                    toggleCurated={async () => {
                        if (coralData) {
                            await toggleCurated();
                        }
                    }}
                />
            )}
    </div>
  );
};

export default Coral;