import React, { useState, useEffect } from 'react';
import { ClientArtistListItem } from 'shared/types/platformTypes';
import { SimulateCoralResponse } from '../../../api/api';
import MiniCoralSimulationTable from '../../../components/CoralEditor/CoralSimulationTable/MiniCoralSimulationTable';
import CoralButton from '../../../components/CoralButtons/CoralButton';
import selectedStar from '../../../assets/images/selected-star.png';


export interface PreviewImpactProps {
    artists: Array<ClientArtistListItem>;
    simulateCoral: () => void; // A function to simulate the coral
    simulationResults: SimulateCoralResponse;
    updatePledgeAmount: (amount: number) => void; // A function to update the pledge amount
    pledgeAmount: number;
    addPinnedArtist: (artistId: string) => void;
    removePinnedArtist: (artistId: string) => void;
    pinnedArtists: string[];
}

const PreviewImpact: React.FC<PreviewImpactProps> = ({ pledgeAmount, simulateCoral, updatePledgeAmount, simulationResults, pinnedArtists, addPinnedArtist, removePinnedArtist }) => {
    const [inputValue, setInputValue] = useState(''); // New state variable
    
    useEffect(() => {
      updatePledgeAmount(0);
      simulateCoral();
    }, []);

    useEffect(() => {
        // Update inputValue whenever pledgeAmount changes
        setInputValue(pledgeAmount === 0 ? '' : pledgeAmount.toString());
      }, [pledgeAmount]);

    // Call simulateCoral when pledgeAmount changes
    useEffect(() => {
        if (pledgeAmount > 0) {
        simulateCoral();
        }
    }, [pledgeAmount]);

      useEffect(() => {
        console.log('simulationResults updated', simulationResults);
      }, [simulationResults]);
  
      const handleUpdatePledgeAmount = () => {
        // Regular expression to match a decimal number with at most 2 decimal points or an empty string
        const decimalRegex = /^([1-9]\d*(\.\d{1,2})?)?$/;
      
        if (inputValue === '') {
          console.log('Textbox is empty');
        } else if (decimalRegex.test(inputValue)) {
          updatePledgeAmount(Number(inputValue));
          console.log('Pledge amount updated');
        } else {
          console.log('Invalid pledge amount');
        }
      };
  
  return (
    <div className="px-4 py-4">
      <h2 className="font-nunito text-xl md:text-2xl font-extrabold mb-2">Preview Your Impact</h2>
      <div className="mb-6">
        <label htmlFor="pledge-amount" className="block text-sm font-medium text-gray-700 mb-4">
          How much would you like to contribute per month?
        </label>
        <form onSubmit={(event) => {
          event.preventDefault();
          handleUpdatePledgeAmount();
        }} className="flex items-center">
          <input
            type="text"
            inputMode="numeric"
            name="pledge-amount"
            id="pledge-amount"
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
            className="border border-gray-300 rounded w-1/2 p-2 mr-2 text-base"
            placeholder="Enter amount (AUD)"
          />
          <CoralButton className="font-semibold text-xs sm:text-sm" onClick={handleUpdatePledgeAmount}>Preview Impact</CoralButton>
        </form>
      </div>

      <h3 className="font-nunito text-lg font-extrabold mb-2">
        <div className="flex items-center">
          <img src={selectedStar} alt="Star" className="inline-block mr-2" width="36" height="36" />
          <div className='mt-2'>Boost Artists</div>
        </div>
      </h3>
      <p className="text-sm mb-4">
        Give more to specific artists. You can choose up to two artists to receive this increased support.
      </p>

    {/* Impact simulation results */}
    <MiniCoralSimulationTable
    artistAllocations={simulationResults.artistAllocations}
    addPinnedArtist={addPinnedArtist}
    removePinnedArtist={removePinnedArtist}
    pinnedArtists={pinnedArtists}
    simulateCoral={simulateCoral}
    />

    </div>
  );
};

export default PreviewImpact;
