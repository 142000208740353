import React, { useState, FC, ChangeEvent } from 'react';

interface DropdownProps {
  options: string[];
  placeholder: string;
  onSelect: (value: string) => void; // Function that handles the selected value
}

const Dropdown: FC<DropdownProps> = ({ options, placeholder, onSelect }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

const handleSelect = (value: string) => {
    setIsOpen(false);
    onSelect(value); // Pass the selected value to the parent component or handle it as needed
    // add minor delay to ensure the dropdown closes before clearing the input
    setTimeout(() => setSearchTerm(''), 300);
    setSearchTerm(''); // Clear the input field
};

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleFocus = () => {
    setIsOpen(true);
  };

  const handleBlur = () => {
    // Delay hiding the dropdown to allow for option selection
    setTimeout(() => setIsOpen(false), 300);
  };

  return (
    <div className="relative">
      <input
        type="text"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className="border border-gray-300 rounded p-2 w-full"
      />
      {isOpen && (
        <div className="absolute z-10 w-full border border-gray-300 rounded bg-white">
          {options
            .filter((option) => option.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((option, index) => (
              <div
                key={index}
                onClick={() => handleSelect(option)}
                className="p-2 hover:bg-gray-100 cursor-pointer"
              >
                {option}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
