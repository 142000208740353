import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SelectArtist from '../../components/CreateCoralFlow/SelectArtists/SelectArtists';
import ConfigureConnections from '../../components/CreateCoralFlow/ConfigureConnections/ConfigureConnections';
// import PreviewImpact from '../../components/CreateCoralFlow/PreviewImpact/PreviewImpact';
import CoralSettings from '../../components/CoralEditor/CoralSettings/CoralSettings';
import useCoralEditor from '../../hooks/useCoralEditor';
import useSpotifyConnect from '../../hooks/useSpotifyConnect';
import SpotifyModal from '../../components/SpotifyModal/SpotifyModal';
import TabbedLayout from '../../components/TabbedLayout/TabbedLayout';
import Spinner from '../../components/Spinner/Spinner';

const EditCoral: React.FC = () => {
    const { id } = useParams<{ id?: string }>();
    const history = useHistory();
    const location = history.location;
    const queryParams = new URLSearchParams(location.search);
    const { spotifyLinked, redirectToSpotify, setSpotifyLinkedStatus } = useSpotifyConnect();
    const [showSpotifyAuthModal, setShowSpotifyAuthModal] = useState(false);
    const spotifyAuthError = queryParams.get('error'); // Get the 'error' query parameter from failed Spotify redirect    
    const spotifyState = spotifyAuthError ? 'auth-error': 'add-artists';
    const [isSpotifyModalClosing, setIsSpotifyModalClosing] = useState(false);

    const { coralData, addArtistDirectly, removeArtist, addArtistFromDynamicArtistPool, addPinnedArtist, removePinnedArtist, retrieveTopArtists, removeArtistsBySourceArtistPoolGuid, uniqueGenres, uniqueLocations, dynamicArtistPools, activeFilters, updatePledgeAmount, updateCoralName, saveCoral, simulateCoral, simulationResults, updateFilter } = useCoralEditor(id || '');

    const handleSyncWithSpotify = () => {
        if (!spotifyLinked) {
            redirectToSpotify();
        }
        else {
            setShowSpotifyAuthModal(true);
        }
    };

    const handleSaveCoral = async () => {
        const newGuid = await saveCoral();
        if (newGuid)
            history.push(`/coral/${newGuid}`);

        console.log('Coral saved');
    };

    const handleView = () => {
        history.push(`/coral/${id}`);
    };    

    // If coralData is null, render a loading screen
    if (!coralData || !coralData.artistPool || !coralData.artistPool.artistList) {
        return (
            <div className="flex items-center justify-center h-screen">
                <Spinner />
            </div>
        );
    }

    // Define breadcrumbs
    const breadcrumbs = [
        <button key="view" className="text-gray-400 text-sm" onClick={handleView}>View Coral</button>,
        <span className='text-sm' key="edit">Edit</span>
    ];

    const sharedProps = {
        artists: coralData.artistPool.artistList.map(({ artistId, artistName, artistListItemStatus, artistData, source, sourceType, sourceArtistPoolGuid, selected, payoutStatus, totalUserPayout, lastMonthPlatformPayout }) => ({
            artistId: artistId || '',
            artistName,
            artistListItemStatus,
            artistData,
            source,
            sourceArtistPoolGuid,
            sourceType,
            selected,
            payoutStatus,
            totalUserPayout,
            lastMonthPlatformPayout,
        })),
        simulationResults: simulationResults,
        pledgeAmount: coralData.pledgeAmount,
        activeFilters: activeFilters,
        uniqueGenres: uniqueGenres,
        uniqueLocations: uniqueLocations,
        dynamicArtistPools: dynamicArtistPools,
        spotifyLinked: spotifyLinked,
        pinnedArtists: coralData.pinnedArtists,
        coralName: coralData.coralName,
    };    

    // Define the default props that will be passed to each tab component
    const defaultStepProps = {
        ...sharedProps,
        addArtistDirectly,
        removeArtist,
        removeArtistsBySourceArtistPoolGuid: () => {},
        updateFilter,
        simulateCoral,
        updatePledgeAmount,
        addArtistFromDynamicArtistPool,
        handleSyncWithSpotify: () => {},
        retrieveTopArtists: () => {},
        addPinnedArtist: () => {},
        removePinnedArtist: () => {},
        updateCoralName: () => {},
        // add any other props needed
    };

    const tabs = [
        {
            name: 'Settings',
            component: CoralSettings,
            key: 'settings',
            props: {
                ...defaultStepProps,
                simulateCoral,
                simulationResults,
                updatePledgeAmount,
                addPinnedArtist,
                removePinnedArtist,
                updateCoralName,
            },
        },
        { 
            name: 'Connections', 
            component: ConfigureConnections,
            key: 'connections',
            props: {
                ...defaultStepProps,
                addArtistDirectly,
                removeArtist,
                addArtistFromDynamicArtistPool,
                handleSyncWithSpotify,
                updateFilter,
                removeArtistsBySourceArtistPoolGuid,
                retrieveTopArtists,
                pageTitle: '',
            },
        },
        { 
            name: 'Artists', 
            component: SelectArtist,
            key: 'artists',
            props: {
                ...defaultStepProps,
                addArtistDirectly,
                removeArtist,
                addArtistFromDynamicArtistPool,
                handleSyncWithSpotify,
                updateFilter,
                pageTitle: '',
            },
        }, 
    ];

    return (
        <>
            {/* Add the Spotify authorization modal */}
            {showSpotifyAuthModal && (
                <SpotifyModal 
                isOpen={showSpotifyAuthModal} 
                onClose={() => {
                    setIsSpotifyModalClosing(true);
                    setTimeout(() => {
                        setShowSpotifyAuthModal(false);
                        setIsSpotifyModalClosing(false);
                    }, 300);
                }} 
                modalState={spotifyState} 
                isSpotifyLinked={spotifyLinked}
                isSpotifyModalClosing={isSpotifyModalClosing}
                setSpotifyLinkedStatus={setSpotifyLinkedStatus}
                />
            )}
            <TabbedLayout
                breadcrumbs={breadcrumbs}
                tabs={tabs}
                onSave={handleSaveCoral}
                onCancel={handleView}
            />
        </>
    );
};

export default EditCoral;